import { Link } from "react-router-dom";
import BgImage from './assets/img/bg/bg-banner-3.jpg';
import img from './assets/img/bg/bg-banner-2.jpg'
function HeaderHeroProfile(props) {
    return (
        <section className="bg-primary effect-section section">
            <div className="mask bg-primary bg-opacity-80"></div>
            <div className="header-height-bar" ></div>
            <div className="container position-relative">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="text-white h1 mb-4">{props.Name}</h2>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default HeaderHeroProfile;