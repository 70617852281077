export default function Loader() {
    return (
        <>
            <div className="custom-loading-container">
                <div className="custom-spinner"></div>
                <p>Please wait...</p>
            </div>

        </>
    )
}