import { useEffect, useState } from "react";
import { FaPlus, FaEdit, FaSave, FaTimes } from "react-icons/fa";
import Select from 'react-select';
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from "sweetalert2";
import { Pagination, Stack } from "@mui/material";
import { useNavigate } from "react-router";

export default function AdminManageStateCitySection2() {
    const [data, setData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [city, setCity] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [editId, setEditId] = useState(null);
    const [editCity, setEditCity] = useState('');
    const [editIdState, setEditIdState] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedStateEdit, setSelectedStateEdit] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const token = `${window.localStorage.getItem("access")}`;
    const role = `${window.localStorage.getItem('role')}`;
    useEffect(() => {
        if ((token == '' || null) || role == 2) {
            navigate(`/Login`)
            return
        }
    }, []);
    useEffect(() => {
        setIsLoading(true)
        FetchCity();
        setIsLoading(false)
        FetchState();
    }, []);
    useEffect(() => {
        const handleSearch = () => {
            const value = searchTerm.toLowerCase();
            const rows = document.querySelectorAll("#myTable tbody tr");
            rows.forEach(row => {
                const text = row.textContent || row.innerText;
                const isVisible = text.toLowerCase().includes(value);
                row.style.display = isVisible ? "" : "none";
            });
        };

        const inputElement = document.getElementById("myInput");
        inputElement.addEventListener("keyup", handleSearch);

        return () => {
            inputElement.removeEventListener("keyup", handleSearch);
        };
    }, [searchTerm]);

    function FetchState() {
        fetch(`https://api.domehelp.com/Master/GetAllState`, {
            method: "POST",
        }).then((resp) => {
            if (!resp.ok) {
                console.log("response not ok");
                return
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                const options = result.data.map((state) => ({
                    label: state.stateName,
                    value: state.stateID,
                    stateName: state.stateName,
                }));
                setStateData(options);
            } else {
                console.log("Error");
            }
        });
    }
    function FetchCity() {
        fetch(`https://api.domehelp.com/Master/GetAllCities`, {
            method: "POST",
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error in network");
                throw new Error("Network response was not ok");
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                const Cityoptions = result.data.map((city) => ({
                    label: city.state.stateID,
                    value: city.cityID,
                    cityName: city.cityName,
                    stateName: city.state.stateName
                }));
                setData(Cityoptions);
            }
        }).catch((error) => {
            console.error("Error:", error);
        });
    }

    function handleAddCity() {
        if (!selectedState) {
            Swal.fire({
                title: "Warning!",
                text: "Please Select State!",
                icon: "warning",
            });
            return;
        }
        if (city.trim() === "") {
            Swal.fire({
                title: "Warning!",
                text: "Enter City Name!",
                icon: "warning",
            });
            return;
        }
        AddCity();
    }

    function AddCity() {
        let stateId = selectedState.value;
        let fd = new FormData();
        fd.append('stateId', stateId);
        fd.append('cityName', city);
        fetch(`https://api.domehelp.com/Master/AddCity`, {
            method: "POST",
            body: fd,
        }).then((resp) => {
            if (!resp.ok) {
                navigate(`/Login`);
                throw new Error("Network response was not ok");
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire({
                    title: "Success",
                    text: result.message,
                    icon: "success",
                });
                setCity('');
                setSelectedState(null);
                FetchCity();
            }
            else {
                Swal.fire("Faild !", result.message, "error");
                setCity('');
                setSelectedState(null);
            }
        }).catch((error) => {
            console.error("Error:", error);
        });
    }

    function handleEditCity(stateID, id, cityName, stateName) {
        setEditId(id);
        setEditCity(cityName);
        setEditIdState(stateName);

    }
    function handleSaveCity(id) {

        if (!selectedStateEdit) {
            Swal.fire({
                title: "Warning!",
                text: "Please Select State!",
                icon: "warning",
            });
            return;
        }
        if (editCity.trim() === "") {
            Swal.fire({
                title: "Warning!",
                text: "Enter City Name!",
                icon: "warning",
            });
            return;
        }
        let selectedStateId = selectedStateEdit.value;
        let fd = new FormData();
        fd.append("cityName", editCity);
        fd.append("stateId", selectedStateId);
        fetch(`https://api.domehelp.com/Master/UpdateCity?id=${id}`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Response Error")
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire("Success", result.message, "success");
                FetchCity();
                handleCancel();
            }
            else {
                Swal.fire("Faild !", result.message, "error")
            }
        })
    }

    function handleCancel() {
        setEditId(null);
        setEditCity('');
        setEditIdState(null);
        setSelectedStateEdit(null);
    }


    function handleDeleteCity(id) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`https://api.domehelp.com/Master/DeleteCity?id=${id}`, {
                    method: "POST",
                }).then((resp) => {
                    if (!resp.ok) {
                        throw Error
                    }
                    return resp.json()
                }).then((result) => {
                    if (result.status === 1) {
                        Swal.fire("Success", result.message, "success");
                        FetchCity();
                    }
                    else {
                        Swal.fire("Failed !", result.message, "error");
                    }
                })

            }
        })
    }

    function handlePageChange(event, value) {
        setCurrentPage(value);
    }

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Reset to first page when changing items per page
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsPerPage === 'all' ? data : data.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>

            <div className="col-xl-12 mt-3">
                <div className="profile-content-area card card-body mb-6">
                    <div>
                        <div className="row mb-1">
                            <div className="col-md-1"></div>

                            <div className="col-md-4 d-flex">
                                <p className="text-info my-2 mx-2">State *</p>
                                <div className="form-group mb-3 w-60">
                                    <Select
                                        options={stateData}
                                        value={selectedState}
                                        onChange={setSelectedState}
                                        placeholder="Select State"
                                    />
                                </div>
                            </div>

                            <div className="col-md-3 d-flex">
                                <p className="text-info my-2 mx-2">City *</p>
                                <div className="form-group mb-3 w-60">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter City Name"
                                        style={{ height: 'calc(1.5em + 0.75rem + 2px)' }}
                                        name="city"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-1 d-flex">
                                <div className="form-group mb-3">
                                    <button className="btn btn-sm btn-success" onClick={handleAddCity} ><FaPlus /> Add</button>
                                </div>
                            </div>

                        </div>
                        <div className="row mb-1">
                            <div className="col-md-1"></div>
                            <div className="col-md-8 d-flex">
                                <input
                                    type="text"
                                    id="myInput"
                                    className="form-control h-75"
                                    placeholder="Search for City or state names.."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div className="col-md-2 d-flex">
                                <p className="text-info mt-2 mx-2">Show</p>
                                <div className="form-group">
                                    <select
                                        style={{ padding: "0.575rem 3.5rem 0.575rem 0.5rem" }}
                                        className="form-select w-0 h-75"
                                        value={itemsPerPage}
                                        onChange={handleItemsPerPageChange}
                                    >
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <table id="myTable" className="table table-striped table-bordered" style={{ textAlign: 'center', fontSize: "12px" }}>
                                <thead style={{ background: "#0066ff", color: "white" }} className="thead-dark">
                                    <tr>
                                        <th>SR.No</th>
                                        <th>State Name</th>
                                        <th>City Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>{indexOfFirstItem + index + 1}</td>
                                            <td>{editId === item.value ? (
                                                <Select
                                                    options={stateData}
                                                    value={selectedStateEdit}
                                                    onChange={setSelectedStateEdit}
                                                    placeholder="Select State"

                                                />
                                            ) : item.stateName}</td>
                                            <td>{editId === item.value ? (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    style={{ padding: '0.275rem 0.5rem', }}
                                                    value={editCity}
                                                    onChange={(e) => setEditCity(e.target.value)}
                                                />
                                            ) : (
                                                item.cityName
                                            )}</td>
                                            <td>
                                                {editId === item.value ? (
                                                    <>
                                                        <button className="button-success-xs" onClick={() => handleSaveCity(item.value)} >
                                                            <FaSave /> Save
                                                        </button>
                                                        <button className="button-danger-xs" onClick={handleCancel}>
                                                            <FaTimes /> Cancel
                                                        </button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <button id="btnedit" className="button-primary-xs" onClick={() => handleEditCity(item.label, item.value, item.cityName, item.stateName)} >
                                                            <FaEdit /> Edit
                                                        </button>
                                                        <button className="button-danger-xs" onClick={() => handleDeleteCity(item.value)} > <RiDeleteBin6Line /> Delete </button>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Stack spacing={2} style={{ alignItems: 'center', marginTop: '20px', marginBottom: '10px' }}>
                                <Pagination
                                    count={Math.ceil(data.length / itemsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}