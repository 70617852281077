import AdminHeader from "../Component/AdminHeader"
import HeaderHeroProfile from "../HeaderHeroProfile"
import { useEffect } from "react";
import { useNavigate } from "react-router";
export default function AdminHomeSection2() {
    const navigate = useNavigate();
    const token = `${window.localStorage.getItem("access")}`;
    const role = `${window.localStorage.getItem('role')}`;
    useEffect(() => {
        if ((token === '' || null) || role === 2) {
            navigate(`/Login`)
            return
        }
    }, [token]);
    return (
        <>
            <HeaderHeroProfile Name='' />
            <section className="profile-container bg-gray-100">
                <div className="container-fluid px-7">
                    <div className="row align-items-start">
                        <div className="col-xl-2">
                            <AdminHeader />
                        </div>
                        <div className="col-xl-10">
                            <div className="profile-content-area my-6 card card-body">
                                <div className="border-bottom mb-6 pb-6">
                                    <h3 className="mb-2">Hello, This is Admin Page</h3>
                                    <h6 className="text-body fw-500 mb-3">London, UK - Joined in April 2019</h6>
                                    <p>I design and develop services for customers of all sizes, specializing in creating stylish, modern websites, web services, and online stores. My passion is to design digital user experiences through bold interfaces and meaningful interactions.</p>
                                    <div className="nav">
                                        <div className="d-flex align-items-center me-3 my-1">
                                            <div className="icon-sm text-center bg-primary text-white rounded-circle"></div>
                                            <div className="col ps-2">10 Skills</div>
                                        </div>
                                        <div className="d-flex align-items-center me-3 my-1">
                                            <div className="icon-sm text-center bg-success text-white rounded-circle"></div>
                                            <div className="col ps-2">9 Awards</div>
                                        </div>
                                        <div className="d-flex align-items-center me-3 my-1">
                                            <div className="icon-sm text-center bg-warning text-white rounded-circle"></div>
                                            <div className="col ps-2">6k Followers</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}