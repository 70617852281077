import B2BDatabaseSection4 from './B2BDatabaseSection4';
import { useEffect } from 'react';
import HeaderHero from '../HeaderHero';
import B2BDataCityWiseSection1 from './B2BDataCityWiseSection1';
function B2BDataStateWise() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <HeaderHero Name={'B2B Database'} />
            <B2BDataCityWiseSection1 />
            <B2BDatabaseSection4 />

        </>
    );
}
export default B2BDataStateWise;