import HeaderHeroProfile from "../HeaderHeroProfile";
import AdminHeader from "../Component/AdminHeader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Image } from "react-bootstrap";
import { FaFileInvoiceDollar } from "react-icons/fa6";
import { IoImagesOutline } from "react-icons/io5";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { FaPencilAlt } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import Swal from "sweetalert2";
import moment from "moment";
import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import '../App.css';
import { error } from "jquery";

function AdminNumerologyUsersList() {
    const editorRef = useRef(null);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const token = `${window.localStorage.getItem("access")}`;
    const role = `${window.localStorage.getItem('role')}`;    
    const [paymentDocName, setPaymentDocName] = useState("");
    const [showPaymentImgModal, setShowPaymentImgModal] = useState(false);
    const [PackageDetailModal, setPackageDetailModal] = useState(false);
    const [PackageDetail, setPackageDetail] = useState([]);
    const [PackageAmount, setPackageAmount] = useState("");
    const [TinyMcRemarksModal, setTinyMcRemarksModal] = useState(false);
    const [PackageRenarks, setPackageRenarks] = useState("");
    const [RecordeID, setRecordeID] = useState("");
    const [editingId, setEditingId] = useState(null);

    useEffect(() => {
        if ((token == '' || null) || role == 2) {
            navigate(`/Login`)
            return
        }
        fetchUser();
    }, []);



    function fetchUser() {
        fetch(`https://api.domehelp.com/User/GetAllNumerologyList`, {
            method: "POST",
            headers: { Authorization: token },
        })
            .then((response) => {
                if (!response.ok) {
                    Swal.fire("Warning!", "getting Error.", "warning");
                    return false;
                }
                return response.json();
            })
            .then((result) => {
                if (result.status === 1) {
                    setData(result.data);
                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                Swal.fire("Warning!", error.toString(), "warning");
            });
    }
    function ViewPackageDetail(ID) {
        fetch(`https://api.domehelp.com/User/GetServicePackageByID?ID=${ID}`, {
            method: "POSt",
        }).then((response) => {
            if (!response.ok) {
                Swal.fire("Warning!", "getting Error.", "warning");
                return false;
            }
            return response.json();
        })
            .then((result) => {
                if (result.status === 1) {
                    setPackageDetail(result.data);
                    setPackageDetailModal(true);
                } else {
                    console.log("Error");
                }
            })
            .catch((error) => {
                Swal.fire("Warning!", error, "warning");
            });

    }
    function fetchImageFile(ImgName) {
        setPaymentDocName('https://api.domehelp.com/NumerologyDoc/' + ImgName);
        setShowPaymentImgModal(true);
    }
    function VerifiedNumerologyUser(ID, status) {
        let fd = new FormData();
        fd.append("Id", ID);
        fd.append("LoggedUserId", window.localStorage.getItem('id'));
        fd.append("ResponseStatus", status);
        fetch('https://api.domehelp.com/User/VerifiedNumerology', {
            method: 'POST',
            body: fd,
        }).then((response) => {
            if (!response.ok) {
                Swal.fire("Warning!", "getting Error.", "warning");
                return false;
            }
            return response.json();
        }).then((result) => {
            if (result.status === 1) {
                fetchUser();
                Swal.fire("Success", result.message.toString(), "success");
            } else {
                Swal.fire("Warning!", result.message.toString(), "error");
            }
        }).catch((error) => {
            Swal.fire("Warning!", error, "warning");
        });
    }
    function FinishVerify(ID) {
        let fd = new FormData();
        fd.append("Id", ID);
        fd.append("LoggedUserId", window.localStorage.getItem('id'));
        fetch('https://api.domehelp.com/User/FinishVerifyNumerology', {
            method: 'POST',
            body: fd,
        }).then((response) => {
            if (!response.ok) {
                Swal.fire("Warning!", "getting Error.", "warning");
                return false;
            }
            return response.json();
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire("Success", result.message.toString(), "success");
                fetchUser();
            } else {
                Swal.fire("Warning!", result.message.toString(), "error");
            }
        }).catch((error) => {
            Swal.fire("Warning!", error, "warning");
        });
    }
    const AddRemark = () => {
        if (editorRef.current) {
            let fd = new FormData();
            fd.append("Id", RecordeID);
            fd.append("LoggedUserId", window.localStorage.getItem('id'));
            fd.append("ResponseNotes", editorRef.current.getContent())

            fetch(`https://api.domehelp.com/User/AddRemarksNumerology`, {
                method: "POST",
                body: fd,
            }).then((response) => {
                if (!response.ok) {
                    Swal.fire("Warning!", "getting Error.", "warning");
                    return false;
                }
                return response.json();
            }).then((result) => {
                if (result.status === 1) {
                    Swal.fire("Success", result.message.toString(), "success");
                    fetchUser();
                    setTinyMcRemarksModal(false);
                }
            }).catch(error)
        }
    };
    const handleSave = () => {
        let fd = new FormData();
        fd.append("Id", editingId);
        fd.append("LoggedUserId", window.localStorage.getItem('id'));
        fd.append("PackageAmount",PackageAmount);
        fetch('https://api.domehelp.com/User/SetVerifyAmountOfUser', {
            method: 'POST',
            body: fd,
        }).then((response) => {
            if (!response.ok) {
                Swal.fire("Warning!", "getting Error.", "warning");
                return false;
            }
            return response.json();
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire("Success", result.message.toString(), "success");
                setEditingId(null);
                fetchUser();
            } else {
                Swal.fire("Warning!", result.message.toString(), "error");
            }
        }).catch((error) => {
            Swal.fire("Warning!", error, "warning");
        });
    }

    return (
        <>
            <HeaderHeroProfile />
            <section className="profile-container bg-gray-100">
                <div className="container-fluid px-7">
                    <div className="row align-items-start">
                        <div className='col-xl-2'>
                            <AdminHeader />
                        </div>
                        <div className="col-xl-10">
                            <div className="profile-content-area card card-body mt-5">
                                <h1 style={{ textAlign: "center" }}>Numerology User Records</h1>
                                <div style={{ maxHeight: '450px', overflow: 'auto' }}>
                                    <table className="table table-striped table-bordered text-center" style={{ fontSize: 'small' }}>
                                        <thead style={{ background: "rgb(0, 102, 255)", color: "white" }} className="thead-dark">
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>D.O.B.</th>
                                                <th>Mobile</th>
                                                <th>Package</th>
                                                <th>Amount</th>
                                                <th>File</th>
                                                <th>Remarks</th>
                                                <th>Verified</th>
                                                <th>Finalized</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.user.userFullName}</td>
                                                    <td>{moment(item.user.userDOB).format("DD-MM-YYYY hh:mm A")}</td>
                                                    <td>{item.user.whatsappMobile}</td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <FaFileInvoiceDollar
                                                            style={{ color: '#1976d2' }}
                                                            size={20}
                                                            onClick={() => ViewPackageDetail(item.package.packageId)}
                                                        />
                                                    </td>
                                                    <td id={`numtd_${item.user.userId}`}>
                                                        {editingId === item.user.userId ? (
                                                            <>
                                                                <input
                                                                    type="number"
                                                                    value={PackageAmount}
                                                                    style={{ width: '80px' }}
                                                                    onChange={(e) => setPackageAmount(e.target.value)}
                                                                />
                                                                &nbsp;
                                                                <FaCheck style={{ color: 'green' }} onClick={handleSave} />
                                                                &nbsp;
                                                                <FaTimes
                                                                    style={{ color: 'red' }}
                                                                    onClick={() => {
                                                                        setEditingId(null);
                                                                        setPackageAmount("");
                                                                    }}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {(item.user.packageAmount > 0 ? `${item.user.packageAmount}`:`${item.package.pAmount}`)} &nbsp;                                                               
                                                                <FaPencilAlt
                                                                    style={{ color: '#0066ff', cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setEditingId(item.user.userId);
                                                                        setPackageAmount((item.user.packageAmount > 0 ? `${item.user.packageAmount}`:`${item.package.pAmount}`));
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <IoImagesOutline
                                                            style={{ color: '#1976d2' }}
                                                            size={20}
                                                            onClick={() => fetchImageFile(item.user.paymentDocName)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-primary"
                                                            onClick={() => {
                                                                setTinyMcRemarksModal(true);
                                                                setPackageRenarks(item.user.responseNotes);
                                                                setRecordeID(item.user.userId);
                                                            }}
                                                        >
                                                            Add/View
                                                        </button>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <div className="checkbox-wrapper-7">
                                                            <input
                                                                className="tgl tgl-ios"
                                                                id={`Verified${index + 1}`}
                                                                type="checkbox"
                                                                checked={item.user.responseStatus > 0}
                                                                onChange={() => VerifiedNumerologyUser(item.user.userId, item.user.responseStatus > 0 ? 0 : 1)}
                                                            />
                                                            <label className="tgl-btn" htmlFor={`Verified${index + 1}`}></label>
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <div className="checkbox-wrapper-7">
                                                            <input
                                                                className="tgl tgl-ios"
                                                                id={`Finished${index + 1}`}
                                                                type="checkbox"
                                                                checked={false} // Adjust this value based on your logic
                                                                onChange={() => FinishVerify(item.user.userId)}
                                                            />
                                                            <label className="tgl-btn" htmlFor={`Finished${index + 1}`}></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={PackageDetailModal} onHide={() => setPackageDetailModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: '#0066ff' }}>{PackageDetail.pName}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'justify' }}>
                    {PackageDetail.pDescription}
                    <div className="mt-3" style={{ textAlign: "end" }}>
                        <label className="payment-badge"><MdOutlineCurrencyRupee />{PackageDetail.pAmount}</label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal show={TinyMcRemarksModal} onHide={() => setTinyMcRemarksModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: '#0066ff' }}>Add/View Remarks</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'justify' }}>
                    <Editor
                        apiKey='cs78o1zulzlu2clanny4rdkxokbmpcn1yq7bsxalu1b7vztr'
                        onInit={(_evt, editor) => editorRef.current = editor}
                        initialValue={PackageRenarks}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-sm btn-success" onClick={AddRemark}>Submit</button>
                </Modal.Footer>
            </Modal>
            <Modal show={showPaymentImgModal} onHide={() => setShowPaymentImgModal(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Image src={paymentDocName} alt="Payment Image" width={'100%'} height={'100%'} />
                </Modal.Body>
            </Modal>
        </>);
}
export default AdminNumerologyUsersList;