import HeaderHeroProfile from "../HeaderHeroProfile";
import AdminHeader from "../Component/AdminHeader";
import { useState, useRef, useEffect } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { FaPlus, FaMinus } from "react-icons/fa";
import { Button } from 'react-bootstrap';
import { IoMdImages } from "react-icons/io";
import { FaPencilAlt } from "react-icons/fa";
import { ImBin } from "react-icons/im";
import Swal from "sweetalert2";

function AdminAddNewProduct() {
  const editorRef = useRef(null);
  const [ProductData, setProductData] = useState([]);
  const [XrefImages, setXrefImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [state, setState] = useState(0);
  const [ProductId, setProductId] = useState("");
  const [Productname, setProductname] = useState('');
  const [Previousamount, setPreviousamount] = useState('');
  const [Currentamount, setCurrentamount] = useState('');
  const [Discount, setDiscount] = useState('');
  const [Description, setDescription] = useState('');
  const [showFisrtModel, setshowFisrtModel] = useState(0);
  const [imageFields, setImageFields] = useState([{ id: Date.now(), file: null, isLastField: true }]);
  const [BannerImage, setBannerImage] = useState('');


  useEffect(() => {
    const prevAmount = parseFloat(Previousamount) || 0;
    const currAmount = parseFloat(Currentamount) || 0;
    if (prevAmount > 0) {
      const discountPercentage = ((prevAmount - currAmount) / prevAmount) * 100;
      setDiscount(Math.round(discountPercentage));
    } else {
      setDiscount(0);
    }
    GetAllProducts();
  }, [Previousamount, Currentamount]);

  const GetAllProducts = (() => {
    fetch('https://api.domehelp.com/ProductInformation/GetAllProducts', {
      method: "POST",
    })
      .then(response => response.json())
      .then(result => {
        if (result.status === 1) {
          setProductData(result.data);
        } else {
          Swal.fire("warning!", "data is Not Found", "warning")
        }
      })
  });
  const GetAllAditionalImages = (Id) => {
    fetch(`https://api.domehelp.com/ProductInformation/GetProductXrefImagesByID?ID=${Id}`, {
      method: "POST",
    }).then((response) => {
      if (!response.ok) {
        Swal.fire("warning!", "Key is missing.", "warning");
      } else {
        return response.json();
      }
    }).then((result) => {
      if (result.status === 1) {
        setXrefImages(result.data);
        setProductId(Id);
        setshowFisrtModel(1);
      }
      else {
        setXrefImages([]);
      }
    })
  }
  function AddNewProduct() {
    if (Productname.trim() === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Enter Product Name!'
      });
      return false;
    }
    if (Previousamount.trim() === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Enter Previous Amount!'
      });
      return false;
    }
    if (parseFloat(Previousamount) < 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Previous amount cannot be negative!'
      });
      return false;
    }
    if (Currentamount.trim() === "") {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Enter Current Amount!'
      });
      return false;
    }
    if (parseFloat(Currentamount) < 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Current Amount cannot be negative!'
      });
      return false;
    }
    if (parseFloat(Currentamount) >= parseFloat(Previousamount)) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Current Amount cannot be greater than Previous Amount!'
      });
      return false;
    }
    if (imageFields[0].file === null) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Please select Product Banner Image.'
      });
      return false;
    }
    const fd = new FormData();
    fd.append('ProductName', Productname);
    fd.append('PPreviousAmount', parseFloat(Previousamount));
    fd.append('PCurrentAmount', parseFloat(Currentamount));
    fd.append('Discount', parseFloat(Discount));
    fd.append('PDescription', Description);
    imageFields.forEach((field, index) => {
      if (field.file !== null) {
        fd.append(`Files`, field.file);
      }
    });

    fetch('https://api.domehelp.com/ProductInformation/AddNewProduct', {
      method: 'POST',
      body: fd,
    }).then((response) => {
      if (!response.ok) {
        Swal.fire("Failed", 'Network response was not ok', "error");
        return false;
      }
      else {
        return response.json();
      }
    }).then((result) => {
      if (result.status === 1) {
        Swal.fire("Success", result.message, "success");
        setProductname('');
        setPreviousamount('');
        setCurrentamount('');
        setDiscount('');
        setDescription('');
        setImageFields([{ image: "", isLastField: true }]);
      } else {
        Swal.fire("Failed", result.message, "error");
      }
    })
  }
  const AddNewProductXrefImages = () => {
    console.log(selectedFiles);
    const fd = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      fd.append('Files', selectedFiles[i]);
    }
    fetch(`https://api.domehelp.com/ProductInformation/AddNewProductXrefImages?ProductId=${ProductId}`, {
      method: "POST",
      body: fd,
    }).then((response) => {
      if (!response.ok) {
        Swal.fire("Error!", "Key is missing.", "error");
        return false;
      }
      else {
        return response.json();
      }
    }).then((result) => {
      if (result.status === 1) {
        Swal.fire("Success", result.message, "success");
        GetAllAditionalImages(ProductId);
      }
    })
  }
  const DeleteProduct = (productId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to remove this product?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://api.domehelp.com/ProductInformation/DeleteProductbyId?ProductId=${productId}`, {
          method: "POST"
        }).then(() => {
          GetAllProducts();
          Swal.fire('Deleted!', 'The product has been deleted.', 'success');
        }).catch(err => {
          Swal.fire('Error!', `Error fetching data: ${err}.`, 'error');
        });
      }
    });
  };
  const DeleteXrefImage = (Id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to remove this aditional Image?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://api.domehelp.com/ProductInformation/DeleteProductXrefImageByID?ID=${Id}`, {
          method: "POST",
        }).then((response) => {
          if (!response.ok) {
            Swal.fire('Wraning!', 'Key is Missing.', "warning");
            return false;
          }
          else {
            return response.json();
          }
        }).then((result) => {
          if (result.status === 1) {
            Swal.fire('Deleted!', 'The image has been deleted.', 'success');
            GetAllAditionalImages(ProductId);
          }
          else {
            Swal.fire('Wraning!', 'The image is not deleted.', "warning");
          }
        })
      }
    })
  }
  const handleImageChange = (index, event) => {
    const newFields = [...imageFields];
    newFields[index].file = event.target.files[0];
    setImageFields(newFields);
  };
  const handleAddClick = (index) => {
    const newFields = [...imageFields];
    newFields[index].isLastField = false;
    setImageFields([...newFields, { id: Date.now(), file: null, isLastField: true }]);
  };
  const handleRemoveClick = (index) => {
    const newFields = imageFields.filter((_, i) => i !== index);
    if (newFields.length > 0) {
      newFields[newFields.length - 1].isLastField = true;
    }
    setImageFields(newFields);
  };
  const handleEditClick = (product) => {
    setState(1);
    setProductId(product.productId);
    setProductname(product.productName);
    setPreviousamount(product.pPreviousAmount);
    setCurrentamount(product.pCurrentAmount);
    setDiscount(product.discount);
    setDescription(product.pDescription);
    setBannerImage(product.pBannerImage);
    document.getElementById('home-tab').click();

  };
  const UpdateProduct = (id) => {
    if (Productname.trim() === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Enter Product Name!'
      });
      return false;
    }
    if (Previousamount === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Enter Previous Amount!'
      });
      return false;
    }
    if (parseFloat(Previousamount) < 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Previous amount cannot be negative!'
      });
      return false;
    }
    if (Currentamount === "") {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Enter Current Amount!'
      });
      return false;
    }
    if (parseFloat(Currentamount) < 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Current Amount cannot be negative!'
      });
      return false;
    }
    if (parseFloat(Currentamount) >= parseFloat(Previousamount)) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning!',
        text: 'Current Amount cannot be greater than Previous Amount!'
      });
      return false;
    }
    const fd = new FormData();
    fd.append('ProductName', Productname);
    fd.append('PPreviousAmount', parseFloat(Previousamount));
    fd.append('PCurrentAmount', parseFloat(Currentamount));
    fd.append('Discount', parseFloat(Discount));
    fd.append('PDescription', Description);
    fd.append('bannerImage', imageFields[0].file);
    fetch(`https://api.domehelp.com/ProductInformation/UpdateProduct?ProductId=${id}`, {
      method: 'POST',
      body: fd,
    })
      .then((response) => {
        if (!response.ok) {
          Swal.fire("Failed", 'Network response was not ok', "error");
          return false;
        }
        return response.json();
      })
      .then((result) => {
        if (result.status === 1) {
          Swal.fire("Success", result.message, "success");
          setProductname('');
          setPreviousamount('');
          setCurrentamount('');
          setDiscount('');
          setDescription('');
          setImageFields([{ id: Date.now(), file: null, isLastField: true }]);
          setBannerImage("");
          setState(0);
        } else {
          Swal.fire("Failed", result.message, "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire("Failed", 'An error occurred while updating the product.', "error");
      });

  };
  return (
    <>
      <HeaderHeroProfile />
      <section className="profile-container bg-gray-100">
        <div className="container-fluid px-7">
          <div className="row align-items-start">
            <div className='col-xl-2'>
              <AdminHeader />
            </div>
            <div className="col-xl-10">
              <div className="profile-content-area card card-body mt-5">
                <h1>Add/Manage Products</h1>
                <ul className="nav nav-tabs" id="myTab" role="tablist" style={{ borderBottom: "none", margin: "auto" }}>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" style={{ border: "none" }}>{state === 0 ? 'Add New Product' : 'Update Product'}</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" style={{ border: "none" }}>Manage Product</button>
                  </li>
                </ul>
                <div className="tab-content mt-4" id="myTabContent">
                  <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                    <div className="row">
                      <div className="col-12">
                        <section className="profile-container bg-gray-100">
                          <div className="container">
                            <div className="row align-items-start">
                              <div className="col-xl-12 p-0">
                                <div className="profile-content-area card card-body">
                                  <div className="border-bottom mb-6 pb-6">
                                    <h5>{state === 0 ? 'Add New Product' : 'Update Product'}</h5>
                                    <div className="row mb-4">
                                      <div className="col-md-6">
                                        <div className="form-group mb-3">
                                          <label className="form-label">Product Name</label>
                                          <input
                                            type="text"
                                            name="Productname"
                                            placeholder="Product Name"
                                            className="form-control"
                                            value={Productname}
                                            onChange={(e) => { setProductname(e.target.value) }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group mb-3">
                                          <label className="form-label">Previous Amount</label>
                                          <input
                                            type="number"
                                            name="Previousamount"
                                            placeholder="Previous Amount"
                                            className="form-control"
                                            value={Previousamount}
                                            onChange={(e) => { setPreviousamount(e.target.value) }}
                                            min={0}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group mb-3">
                                          <label className="form-label">Current Amount</label>
                                          <input
                                            type="text"
                                            name="Currentamount"
                                            placeholder="Current Amount"
                                            className="form-control"
                                            value={Currentamount}
                                            onChange={(e) => { setCurrentamount(e.target.value) }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group mb-3">
                                          <label className="form-label">Discount</label>
                                          <input
                                            type="text"
                                            name="Discount"
                                            placeholder="Discount"
                                            className="form-control"
                                            value={`${Discount}%`}
                                            readOnly
                                          />
                                        </div>
                                      </div>
                                      <div>
                                        {imageFields.map((field, index) => (
                                          <div className="col-md-12" key={field.id}>
                                            <div className="form-group mb-3">
                                              <label className="form-label">
                                                {index === 0 ? (BannerImage ? <span>Banner Image: <a href={`https://api.domehelp.com/ProductDoc/${BannerImage}`} target="_blank" rel="noopener noreferrer">Banner Image View</a></span> : "Banner Image") : `Additional Banner Image ${index}`}
                                              </label>
                                              <div style={{ display: "flex" }}>
                                                <input type="file" onChange={(event) => handleImageChange(index, event)} name={`Image_${index}`} className="form-control" style={{ marginRight: "10px" }} accept=".jpg, .jpeg, .png,.webp" />
                                                {state === 0 && (
                                                  <div>
                                                    {field.isLastField ? (
                                                      <button
                                                        type="button"
                                                        className="btn btn-success btn-sm"
                                                        onClick={() => handleAddClick(index)}
                                                        style={{ height: "100%" }}
                                                      >
                                                        <FaPlus style={{ fontSize: "14px" }} />
                                                      </button>
                                                    ) : (
                                                      <button type="button" className="btn btn-danger btn-sm" onClick={() => handleRemoveClick(index)} style={{ height: "100%" }}>
                                                        <FaMinus style={{ fontSize: "14px" }} />
                                                      </button>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-group mb-3">
                                          <label className="form-label">Description</label>
                                          <Editor
                                            apiKey='cs78o1zulzlu2clanny4rdkxokbmpcn1yq7bsxalu1b7vztr'
                                            onInit={(_evt, editor) => editorRef.current = editor}
                                            value={Description}
                                            initialValue=""
                                            init={{
                                              height: 500,
                                              menubar: false,
                                              plugins: [
                                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                              ],
                                              toolbar: 'undo redo | blocks | ' +
                                                'bold italic forecolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                            }}
                                            onEditorChange={(content) => setDescription(content)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Button onClick={() => { { state === 0 ? AddNewProduct() : UpdateProduct(ProductId) } }}> {state === 0 ? `Save Product` : `Update Product`} </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                    <div style={{ maxHeight: '450px', overflow: 'auto' }}>
                      <table className="table table-striped table-bordered text-center" style={{ fontSize: 'small' }}>
                        <thead style={{ background: "rgb(0, 102, 255)", color: "white" }} className="thead-dark">
                          <tr>
                            <th>#</th>
                            <th>ProductName</th>
                            <th>Amount</th>
                            <th>Discount(%)</th>
                            <th>Addition Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ProductData.length > 0 ? (
                            ProductData.map((product, index) => (
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{product.productName}</td>
                                <td>{product.pCurrentAmount}</td>
                                <td>{product.discount}</td>
                                <td>
                                  <button type="button" className="btn btn-primary btn-sm" style={{ padding: '0px 3px' }} onClick={() => GetAllAditionalImages(product.productId)}> <IoMdImages style={{ color: 'white', fontSize: 16 }} /></button>
                                </td>
                                <td>
                                  <button type="button" className="btn btn-success" style={{ padding: '0px 5px', marginRight: '5px' }} onClick={() => { handleEditClick(product) }}>
                                    <FaPencilAlt />
                                  </button>
                                  <button type="button" className="btn btn-danger" style={{ padding: '0px 5px' }} onClick={() => { DeleteProduct(product.productId) }}>
                                    <ImBin />
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7">No products available.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={showFisrtModel === 0 ? `modal fade d-none` : `modal fade show d-block`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden={showFisrtModel === 0 ? `true` : `false`} style={{ backgroundColor: "#000000b0" }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-primary" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", position: "relative", padding: "10px 15px 5px 15px" }}>
              <div style={{ width: "100%" }}>
                <h5 style={{ fontSize: "16px", color: "white" }}>Additional Images Manage</h5>
              </div>
              <button type="button" className="custom-close-btn" data-dismiss="modal" aria-label="Close" onClick={() => { setshowFisrtModel(0); setProductId("");setSelectedFiles([]);}}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-2">
              <div className="row justify-content-center">
                <div className="col-12 mb-2 mt-2">
                  <div className="profile-content-area card card-body mt-2 p-3">
                    <div style={{ display: "flex" }}>
                      <input type="file" className="form-control" multiple onChange={(e) => { setSelectedFiles(e.target.files); }} accept=".jpg, .jpeg, .png,.webp" />
                      <button type="button" className="btn btn-success btn-sm" style={{ marginLeft: "10px" }} onClick={() => { AddNewProductXrefImages() }}>Add Images</button>
                    </div>
                    <p className="mt-2 mb-0 p-0" style={{ fontSize: "12px", color: "red" }}>*Hold Ctrl and click to select multiple images.</p>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                {
                  XrefImages.map((image, index) => (
                    <div className="col-lg-3 col-6 text-center mb-2" key={index + 1}>
                      <div className="card card-body p-2 round">
                        <img src={`https://api.domehelp.com/ProductDoc/${image.pxImageUrl}`} alt="" />
                        <button type="button" className="btn btn-danger mt-2" style={{ padding: '0px 5px' }} onClick={() => { DeleteXrefImage(image.pxImagesID) }}>
                          <ImBin style={{ marginBottom: "5px" }} /> Delete
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  )
}
export default AdminAddNewProduct;