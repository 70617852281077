import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import BgImg from '../assets/img/bg/bg-banner-10.jpg';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useUserId } from '../Component/ContextApi';
function ForgetPasswordSection1() {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [ButtonName, setButtonName] = useState('Send OTP');
    const [showOtpInput, setShowOtpInput] = useState(false);
    const Navigate = useNavigate();
    const { setUserId } = useUserId();

    function ChangePassword() {
        let isValid = true;
        if (email.trim() === "") {
            setEmailError("Enter Email");
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError('Enter a valid email address!');
            isValid = false;
        } else {
            setEmailError("");
        }
        if (ButtonName === "Verify OTP") {
            if (otp.trim() === "") {
                setOtpError("Enter OTP !")
                isValid = false;
            }
            else {
                setOtpError("")
            }
        }

        if (isValid) {
            const fd = new FormData();
            fd.append("Emailto", email);
            fd.append("OTP", otp);
            Swal.fire({
                title: "Loading...",
                html: `
                    <div class="custom-loading-container">
                        <div class="custom-spinner"></div>
                        <p>Please wait...</p>
                    </div>
                `,
                showConfirmButton: false,
                background: "rgba(0, 0, 0, 0.8)",
                customClass: {
                    popup: 'custom-popup',
                    title: 'custom-title',
                    htmlContainer: 'custom-html-container'
                }
            });

            fetch(`https://api.domehelp.com/User/SendEmailOtp`, {
                method: 'POST',
                body: fd,
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {

                    if (data.status === 1) {
                        setShowOtpInput(true);
                        setButtonName('Verify OTP');
                        Swal.fire(data.message, "Success");
                    } else if (data.status === 2) {
                        setEmail('');
                        setOtp('');
                        const encId = data.data;
                        setUserId(encId)
                        setShowOtpInput(false);
                        setButtonName('Send OTP');
                        Swal.fire(data.message, "Success");
                        Navigate(`/ChangePassword`);
                    } else {
                        Swal.fire("Failed !", data.message, "error");
                    }
                })
                .catch((error) => {
                    console.log(error)
                    Swal.fire("Error !", error, "error");
                });
        }
    }
    return (
        <main className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BgImg})` }}>
            <div className="container">
                <div className="row justify-content-md-end py-12">
                    <div className="col-md-5 col-xl-4">
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="pb-4 text-center">
                                    <h3 className="mb-2">Forgot Password</h3>
                                    <p>Enter your email address to reset your password.</p>
                                </div>
                                <form>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Email address</label>
                                        <input
                                            type="email"
                                            className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                            value={email}
                                            onChange={(e) => { setEmail(e.target.value); setEmailError(""); }}
                                            placeholder="name@example.com"
                                        />
                                        {emailError && <div className="invalid-feedback">{emailError}</div>}
                                    </div>
                                    {showOtpInput &&
                                        <div className="form-group mb-3">
                                            <label className="form-label">OTP</label>
                                            <input
                                                type="text"
                                                className={`form-control ${otpError ? 'is-invalid' : ''}`}
                                                value={otp}
                                                onChange={(e) => { setOtp(e.target.value); setOtpError(""); }}
                                                placeholder="Enter OTP Here..."
                                            />
                                            {otpError && <div className="invalid-feedback">{otpError}</div>}
                                        </div>
                                    }
                                    <div className="pt-2">
                                        <button type="button" className="btn btn-primary w-100" onClick={ChangePassword}>
                                            {ButtonName}
                                        </button>
                                    </div>
                                    <div className="mt-3 text-center"><NavLink to={'/login'} className="small fw-700">Back to Login</NavLink></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ForgetPasswordSection1;
