import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import BgImg from '../assets/img/bg/bg-banner-6.jpg';
import Swal from 'sweetalert2';
import { useUserId } from '../Component/ContextApi';
function ChangePasswordSection1() {
    const { userId } = useUserId();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (setter, setError) => (e) => {
        setter(e.target.value);
        setError('');
    };
    const updateUserData = (e) => {
        e.preventDefault();
        let isValid = true;
        if (password.length < 8) {
            setPasswordError('Password must be greater than 8 characters');
            isValid = false;
        }
        if (password.trim() === '') {
            setPasswordError('Enter Password!');
            isValid = false;
        } else if (password !== confirmPassword) {
            setConfirmPasswordError('Password and Confirm Password Not Matched!');
            isValid = false;
        }
        if (confirmPassword.trim() === '') {
            setConfirmPasswordError('Enter Confirm password');
            isValid = false;
        }

        if (isValid) {
            const fd = new FormData();
            fd.append("id", userId);
            fd.append("newPassword", password);
            fetch(`https://api.domehelp.com/User/ChangePassword`, {
                method: 'POST',
                body: fd,
            })
                .then((response) => {
                    if (!response.ok) {
                        navigate(`/Login`);
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.status === 1) {
                        setPassword('');
                        setConfirmPassword('');
                        Swal.fire(data.message, "Success");
                        navigate(`/Login`);
                    }
                    else {
                        Swal.fire(data.message, "error", "error");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user data:", error);
                });


        }
    };
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <main className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BgImg})` }}>
            <div className="container">
                <div className="row justify-content-md-end py-12">
                    <div className="col-md-5 col-xl-4">
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="pb-4 text-center">
                                    <h3 className="mb-2">Generate New Password</h3>
                                </div>
                                <form>
                                    <div className="form-group mb-3">
                                        <label className="form-label">New Password</label>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                            value={password}
                                            onChange={handleInputChange(setPassword, setPasswordError)}
                                        />
                                        {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label">Confirm New Password</label>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className={`form-control ${confirmPasswordError ? 'is-invalid' : ''}`}
                                            value={confirmPassword}
                                            onChange={handleInputChange(setConfirmPassword, setConfirmPasswordError)}
                                        />
                                        {confirmPasswordError && <div className="invalid-feedback">{confirmPasswordError}</div>}
                                    </div>
                                    <div className="form-group mb-3">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="showPassword"
                                                checked={showPassword}
                                                onChange={toggleShowPassword}
                                            />
                                            <label className="form-check-label" htmlFor="showPassword">
                                                Show Password
                                            </label>
                                        </div>
                                    </div>
                                    <div className="pt-2">
                                        <button type="button" className="btn btn-primary w-100" onClick={updateUserData}>
                                            Update Password
                                        </button>
                                    </div>
                                    <div className="mt-3 text-center"><NavLink to={'/login'} className="small fw-700">Back to Login</NavLink></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
export default ChangePasswordSection1;
