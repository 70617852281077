import img2 from '../assets/img/Services/ServiceSection.png'
import img3 from '../assets/img/Services/Servicesection3img.svg'
import { NavLink } from 'react-router-dom'
export default function ServicesSection3() {
    return (
        <>

            <section >
                <div className="section effect-section">
                    <div className="effect effect-middle bg-gray-100"></div>
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-5 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                <div className="col-lg-6 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                    <img src={img3} title="" alt="" />
                                </div>
                                <div className="h3 mb-4">Sales Lead Data</div>
                                <p className="m-0">Achieve a high level of targeting by gathering relevant leads from the Internet. Reach out to the right audience to market your product or services or execute your sales campaign. Enrich your CRM data with E-mails, Contact numbers, Addresses, Revenue numbers and Social Media Profiles &amp; more.</p>
                                <div className="pt-4"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                            </div>
                            <div className="col-lg-5 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                <div className="col-lg-6 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                    <img src={img2} title="" alt="" />
                                </div><br />
                                <div className="h3 mb-4">Financial Data Scraping</div>
                                <p className="m-0">Monitor finance market data using data scraping. Scrap financial data such as news and articles, company’s data, equity research, turnover, retained earnings, business news data, political news, current stock rates data, and limitless data of the finance industry.</p>
                                <div className="pt-4"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}