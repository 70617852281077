import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationCity } from "react-icons/md";
import { FaGlobe } from "react-icons/fa";
export default function UserProfileSection1() {
    const [Firstname, setFirstName] = useState('');
    const [Lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [data, setData] = useState(null);

    const id = window.localStorage.getItem("id")
    const token = window.localStorage.getItem("access")

    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            fetchUser();
        } else {
            navigate(`/Login`);
        }
    }, [id, navigate]);

    const fetchUser = async () => {
        try {
            const response = await fetch(`https://api.domehelp.com/User/GetUserById?id=${id}`, {
                method: "POST",
                headers: { Authorization: `${token}` },
            });
            const result = await response.json();
            setData(result.data);
            if (result.data) {
                setFirstName(result.data.firstName);
                setLastname(result.data.lastName);
                setEmail(result.data.email);
                setMobile(result.data.mobile);
                setCity(result.data.city);
                setState(result.data.state);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };
    return (
        <>

            <div className="container-fluid">
                <h5>User information</h5>
                <div className="row">
                    <div className="col-md-6 my-2">
                        <div className="d-flex">
                            <div className="icon-md bg-primary bg-opacity-10 rounded-circle text-primary mt-1">
                                <MdEmail />
                            </div>
                            <div className="col ps-3 lh-normal">
                                <div className="text-mode m-0 fw-600">Email</div>
                                <span>{data ? data.email : 'Loading...'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 my-2">
                        <div className="d-flex">
                            <div className="icon-md bg-primary bg-opacity-10 rounded-circle text-primary mt-1">
                                <FaPhoneAlt />
                            </div>
                            <div className="col ps-3 lh-normal">
                                <div className="text-mode m-0 fw-600">Mobile</div>
                                <span>{data ? data.mobile : 'Loading...'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 my-2">
                        <div className="d-flex">
                            <div className="icon-md bg-primary bg-opacity-10 rounded-circle text-primary mt-1">
                                <MdLocationCity />
                            </div>
                            <div className="col ps-3 lh-normal">
                                <div className="text-mode m-0 fw-600">City</div>
                                <span>{data ? data.city : 'Loading...'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 my-2">
                        <div className="d-flex">
                            <div className="icon-md bg-primary bg-opacity-10 rounded-circle text-primary mt-1">
                                <FaGlobe />
                            </div>
                            <div className="col ps-3 lh-normal">
                                <div className="text-mode m-0 fw-600">State</div>
                                <span>{data ? data.state : 'Loading...'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
