import { useEffect } from "react"
import HeaderHero from "../HeaderHero";
import ProductsList from "./ProductsList";
export default function Product(){
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
    return(
        <>
        <HeaderHero Name={"Products"}/>     
        <ProductsList/>
        </>
    )
}