import ForgetPasswordSection1 from "./ForgetPasswordSection1";
import { useEffect } from "react";
export default function ForgetPassword() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <ForgetPasswordSection1 />
        </>
    )
}