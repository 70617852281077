import { useState } from 'react';
import BgImg from '../assets/img/bg/bg-banner-9.jpg';
import { NavLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function SignUpSection1() {
    const [Firstname, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [Lastname, setLastname] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [FirstnameError, setFirstNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [LastnameError, setLastnameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [cpasswordError, setCpasswordError] = useState('');
    const [cityError, setCityError] = useState('');
    const [stateError, setStateError] = useState('');
    const navigate = useNavigate();
    const handleInputChange = (setter, setError) => (e) => {
        setter(e.target.value);
        setError('');
    };
    const handleSignup = () => {
        let isValid = true;
        if (Firstname.trim() === '') {
            setFirstNameError('Enter First Name!');
            isValid = false;
        }
        if (Lastname.trim() === '') {
            setLastnameError('Enter Last Name!');
            isValid = false;
        }

        if (email.trim() === '') {
            setEmailError('Enter Email!');
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError('Enter a valid email address!');
            isValid = false;
        }

        if (mobile.trim() === '' || mobile.length !== 10) {
            setMobileError('Enter 10 Digit Mobile Number!');
            isValid = false;
        }

        if (password.trim() === '') {
            setPasswordError('Enter Password!');
            isValid = false;
        }

        if (cpassword.trim() === '') {
            setCpasswordError('Enter Confirm Password!');
            isValid = false;
        } else if (password !== cpassword) {
            setCpasswordError('Password and Confirm Password Not Matched!');
            isValid = false;
        } else if (password.length < 8 || cpassword.length < 8) {
            setPasswordError('Enter minimum 8 Digit !');
            setCpasswordError('Enter minimum 8 Digit !');
            isValid = false;
        }

        if (city.trim() === '') {
            setCityError('Enter City!');
            isValid = false;
        }
        if (state.trim() === '') {
            setStateError('Enter State!');
            isValid = false;
        }

        if (isValid) {
            SignUpUser();
        }
    };
    function SignUpUser() {
        const fd = new FormData();
        fd.append('userFirstName', Firstname)
        fd.append('userLastName', Lastname)
        fd.append('userMobile', mobile)
        fd.append('userEmail', email)
        fd.append('userPassword', password)
        fd.append('userCity', city)
        fd.append('userState', state)
        fetch('https://api.domehelp.com/User/AddUser', {
            method: 'POST',
            body: fd,
        }).then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
            .then((result) => {
                if (result.status === 1) {
                    Swal.fire("Success",result.message, "success");
                    setFirstName('');
                    setLastname('');
                    setEmail('');
                    setMobile('');
                    setPassword('');
                    setCpassword('');
                    setCity('');
                    setState('');
                    navigate(`/Login`);
                    
                }
                else{
                    Swal.fire("Faild", result.message, "error");
                    navigate(`/Login`);
                }
            })
    }
    return (
        <>
            <main className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BgImg})` }}>
                <div className="container">
                    <div className="row justify-content-md-end py-12">
                        <div className="col-xl-8">
                            <div className="card">
                                <div className="card-body p-5">
                                    <div className="pb-1 text-center">
                                        <h3 className="mb-2">Sign Up</h3>
                                    </div>
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">First Name</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${FirstnameError ? 'is-invalid' : ''}`}
                                                        placeholder="Rachel"
                                                        value={Firstname}
                                                        onChange={handleInputChange(setFirstName, setFirstNameError)}
                                                    />
                                                    {FirstnameError && <div className="invalid-feedback">{FirstnameError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Last Name</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${LastnameError ? 'is-invalid' : ''}`}
                                                        placeholder="Roth"
                                                        value={Lastname}
                                                        onChange={handleInputChange(setLastname, setLastnameError)}
                                                    />
                                                    {LastnameError && <div className="invalid-feedback">{LastnameError}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Mobile No.</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${mobileError ? 'is-invalid' : ''}`}
                                                        placeholder="+91 1234567890"
                                                        value={mobile}
                                                        onChange={handleInputChange(setMobile, setMobileError)}
                                                    />
                                                    {mobileError && <div className="invalid-feedback">{mobileError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Email address</label>
                                                    <input
                                                        type="email"
                                                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                                        placeholder="name@example.com"
                                                        value={email}
                                                        onChange={handleInputChange(setEmail, setEmailError)}
                                                    />
                                                    {emailError && <div className="invalid-feedback">{emailError}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">Password</label>
                                                    <input
                                                        type="password"
                                                        className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                                        placeholder="***********"
                                                        value={password}
                                                        onChange={handleInputChange(setPassword, setPasswordError)}
                                                    />
                                                    {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label" style={{ width: "max-content" }}>Confirm Password</label>
                                                    <input
                                                        type="password"
                                                        className={`form-control ${cpasswordError ? 'is-invalid' : ''}`}
                                                        placeholder="***********"
                                                        value={cpassword}
                                                        onChange={handleInputChange(setCpassword, setCpasswordError)}
                                                    />
                                                    {cpasswordError && <div className="invalid-feedback">{cpasswordError}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">City</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${cityError ? 'is-invalid' : ''}`}
                                                        placeholder="Patiala.."
                                                        value={city}
                                                        onChange={handleInputChange(setCity, setCityError)}
                                                    />
                                                    {cityError && <div className="invalid-feedback">{cityError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="form-group">
                                                    <label className="form-label">State</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${stateError ? 'is-invalid' : ''}`}
                                                        placeholder="Punjab.."
                                                        value={state}
                                                        onChange={handleInputChange(setState, setStateError)}
                                                    />
                                                    {stateError && <div className="invalid-feedback">{stateError}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-2 text-center">
                                            <button type='button' className="btn btn-md btn-primary" onClick={handleSignup}>Create account</button>
                                        </div>
                                        <div className="mt-3 text-center"><small>Already have an account?</small> <NavLink
                                            to={'/Login'} className="small fw-700">Login</NavLink></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

