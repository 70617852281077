import ParticlesComponent from '../Component/particles'
import HomeBanner1 from '../assets/img/home-banner/home-banner-1.png'
import { NavLink } from 'react-router-dom'
import '../assets/css/theme.css'

export default function HomeSection1() {
    return (
        <>
            <section id="home" className="effect-section bg-gray-100">
                <div className="effect bg-primary effect-skew"><ParticlesComponent id="particles-box" /></div>
                <div className="particles-box" id="particles-box">

                </div>
                <div className="container position-relative">
                    <div className="row  align-items-center py-10">
                        <div className="col-12 col-lg-5 col-xl-4 py-8 wow fadeInUp" data-aos="fade-right" data-aos-delay="0.4s">
                            <h1 className="text-white mb-3">Most Reliable Web Scraping Service</h1>
                            <p className="lead text-white-50">Tap into one of the most reliable web scraping services trusted by 2900+ enterprises globally</p>

                            {/* <NavLink className="btn btn-secondary rounded-0 flex-shrink-0" to={'/B2BDatabase'}>Explore B2B Database</NavLink> */}
                            <NavLink className="btn btn-secondary rounded-0 flex-shrink-0" to={'/Products'}>Products</NavLink>

                        </div>
                        <div className="col-lg-7 col-xl-8 text-center wow fadeInUp" data-aos="fade-left" data-aos-delay=".4s">
                            <img className="lg-img-140" src={HomeBanner1} title="" alt="" />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}