import { useState } from "react";
import Swal from "sweetalert2";

function ContactSection1() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        if (name.trim() === '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Full  Name!",
                icon: "warning",
            });
            return;
        }
        if (email.trim() === '') {
            Swal.fire({
                title: "Warning!",
                text: "Enter Email!",
                icon: "warning",
            });
            return;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            Swal.fire({
                title: "Warning!",
                text: "Email are not in Valid Formate!",
                icon: "warning",
            });
            return;
        }
        if (comment.trim() === '') {
            Swal.fire({
                title: "Warning!",
                text: "Comment Field is mandatory!",
                icon: "warning",
            });
            return;
        }
        const formData = new FormData();
        formData.append('SenderUser', name);
        formData.append('SenderEmail', email);
        formData.append('SenderPhone','');
        formData.append('SenderRequestText', comment);        
        Swal.fire({
            title: "Loading...",
            html: `
                <div class="custom-loading-container">
                    <div class="custom-spinner"></div>
                    <p>Please wait...</p>
                </div>
            `,
            showConfirmButton: false,
            background: "rgba(0, 0, 0, 0.8)",
            customClass: {
                popup: 'custom-popup',
                title: 'custom-title',
                htmlContainer: 'custom-html-container'
            }
        });
        fetch('https://api.domehelp.com/User/SendEmailToDomeHelpAdmin', {
            method: 'POST',
            body: formData,
        }).then(response => {
            if (response.ok) {
                Swal.fire("THANK YOU FOR GETTING IN TOUCH!", "OUR TEAM WILL CONTACT YOU SHORTLY.", "success");
                setEmail('');
                setName('');
                setComment('');
            } else {
                Swal.fire("Failed to send email", "An error occurred while sending the email.", "error");
            }
        }).catch(error => {
            Swal.fire("Failed to send email", `An error occurred while sending the email: ${error.message}`, "error");
        });
        
    };

    return (
        <section className="section bg-gray-100">
            <div className="container">
                <div className="row justify-content-center gy-4">
                    <div className="col-lg-8 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDuration: '0.4s', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
                        <div className="bg-body shadow p-4 p-lg-6"><div className="pb-4">
                            <h5 className="m-0">Contact Us</h5>
                        </div>
                            <form className="rd-mailform" data-form-output="form-output-global" data-form-type="contact" method="post" action="https://www.pxdraft.com/wrap/mombo/assets/vendor/mail/bat/rd-mailform.php" noValidate="noValidate">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label className="form-label rd-input-label focus not-empty">Name</label>
                                            <input id="contact-name" type="text" value={name} onChange={(e) => { setName(e.target.value) }} name="name" placeholder="Enter FullName" data-constraints="@Required" className="form-control form-control-has-validation form-control-last-child" />
                                            <span className="invalid-feedback"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label className="form-label rd-input-label focus not-empty">Email address</label>
                                            <input id="contact-email" type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} name="email" placeholder="name@example.com" data-constraints="@Required" className="form-control form-control-has-validation form-control-last-child" />
                                            <span className="invalid-feedback"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label className="form-label rd-input-label focus not-empty">Comment</label>
                                            <textarea className="form-control form-control-has-validation form-control-last-child" id="contact-message" value={comment} onChange={(e) => { setComment(e.target.value) }} name="message" rows="3" placeholder="Hi there, I would like to ..." data-constraints="@Required"></textarea>
                                            <span className="invalid-feedback"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-12 pt-2">
                                        <button className="btn btn-primary" type="button" name="send" onClick={handleSubmit}>Contact US</button>
                                        <div className="snackbars" id="form-output-global">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDuration: '0.4s', animationDelay: '0.1s', animationName: 'fadeInUp' }}>
                        <div className="bg-body p-2 shadow h-100 ms-xl-6">
                            <div className="py-3 px-4 bg-primary h-100">
                                <h5 className="h6 text-white mb-2">Location:</h5>
                                <p className="text-white text-opacity-65 mb-2" style={{ fontSize: 14 }}><strong style={{ fontSize: "12px" }}>A Product of VIABLE INFORMATICS CLUES</strong><br /> SCO 234, First Floor, Urban Estate Phase 1, PIN : 147002</p>
                                <h5 className="h6 text-white mb-2">Email:</h5>
                                <p className="text-white text-opacity-65 mb-2">contact@domehelp.com</p>
                                <h5 className="h6 text-white mb-2">Call:</h5>
                                <p className="text-white text-opacity-65 mb-2">+91-91151-52566</p>
                                <h5 className="h6 text-white mb-2">Working Time:</h5>
                                <p className="text-white text-opacity-65 mb-2">10.00AM to 07.00PM</p>
                                <h5 className="h6 text-white mb-2">Working Days:</h5>
                                <p className="text-white text-opacity-65 mb-2">Monday to Friday</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 p-3 bg-body shadow">
                    <div className="ratio ratio-21x9">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3443.752395142563!2d76.3906083!3d30.32956!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391028ecc0000001%3A0xa6073e88b7307a85!2sVIABLE+INFORMATICS+CLUES+PVT.+LTD!5e0!3m2!1sen!2sin!4v1543294550056"
                            allowFullScreen="" title="Vic location" >
                        </iframe>
                    </div>
                </div>
            </div>
        </section>
    );

}
export default ContactSection1;