import img4 from '../assets/img/home/bg-2.jpg'
import { NavLink } from 'react-router-dom'
export default function ServicesSection4() {
    return (
        <>
            <section >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pe-xl-12 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                            <h2 className="h1 mb-4">E-commerce Data Scraping</h2>
                            <p className="mb-2 fs-lg">Get E-commerce data from any website across the web.
                                Scrap E-commerce data such as product details,
                                pricing, deals and sale data, customer reviews, ratings, sellers details, and much more.
                                Make informed decisions about the product you sell or buy by analyzing and understanding the target group.
                            </p>
                            <div className="pt-3"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                        </div>
                        <div className="col-lg-6 my-3 wow fadeInRight" data-aos="fade-right" data-aos-delay="0.1s">
                            <div className="position-relative">
                                <img className="card-img" src={img4} title="" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}