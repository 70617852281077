import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderHero from "../HeaderHero";
import { useParams } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { FaArrowLeft } from "react-icons/fa6";
import Swal from "sweetalert2";

function ProductDeatils() {
    const { productId } = useParams();
    //const [quantity, setQuantity] = useState(1);
    const [data, setData] = useState([]);
    const [ProductName, setProductName] = useState("");
    const [CurrentAmount, setCurrentAmount] = useState("");
    const [PreviousAmount, setPreviousAmount] = useState("");
    const [Description, setDescription] = useState("");
    const [BannerImage, setBannerImage] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        GetProductList();
    }, [productId]);

    function GetProductList() {
        fetch(` https://api.domehelp.com/ProductInformation/GetProductDetailAllByID?ID=${productId}`, {
            method: "POST",
        })
            .then((response) => {
                if (!response.ok) {
                    Swal.fire("Warning!", "getting Error.", "warning");
                    return false;
                }
                return response.json();
            })
            .then((result) => {
                if (result.status === 1) {
                    setData(result.data);
                    setProductName(result.data[0].productName);
                    setCurrentAmount(result.data[0].pCurrentAmount);
                    setPreviousAmount(result.data[0].pPreviousAmount);
                    setDescription(result.data[0].pDescription);
                    setBannerImage(result.data[0].pBannerImage);
                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                Swal.fire("Warning!", error.toString(), "warning");
            });
    }
    return (
        <>
            <HeaderHero Name={ProductName} />
            <div className="container">
                <div className="row justify-content-center mt-4 mb-4">
                    <div className="col-lg-5 p-5 ">
                        <div className="width-400">
                            <Carousel data-bs-theme="dark">
                                {BannerImage && (
                                    <Carousel.Item key="banner-image">
                                        <img
                                            className="d-block w-100"
                                            src={`https://api.domehelp.com/ProductDoc/${BannerImage}`}
                                            alt="slide item"
                                        />
                                    </Carousel.Item>
                                )}
                                {
                                    data.map((item, index) => (
                                        item.pxImageUrl ? (
                                            <Carousel.Item key={index + 1}>
                                                <img className="d-block w-100" src={`https://api.domehelp.com/ProductDoc/${item.pxImageUrl}`} alt="slide item" />
                                            </Carousel.Item>
                                        ) : null
                                    ))
                                }
                            </Carousel>
                        </div>
                    </div>
                    <div className="col-lg-5 col-8 py-4 px-5">
                        <div>
                            <h3 className="mt-4" style={{ fontSize: 26, textAlign: "center" }}>{ProductName}</h3>
                            <h6 className="mt-3" style={{ fontSize: 14 }}><span><del aria-hidden="true">₹{PreviousAmount}</del> <span>₹{CurrentAmount}</span></span></h6>
                        </div>
                        <div className="mt-3">
                            Qty : 1 {/*<input type="number" id="quantity_66f640279cb94" className="input-text qty text" name="quantity" value={quantity} onChange={(e) => { setQuantity(e.target.value) }} aria-label="Product quantity" size="4" min="1" max="" step="1" placeholder="" pattern="[0-9]*" style={{ width: "100px" }} /> */}
                        </div>
                        <div className="mt-3 text-center d-none">
                            <button className="btn btn-outline-primary btn-sm" type="button" style={{ width: "100%", borderRadius: "30px" }} >Add to cart</button>
                        </div>
                        <div className="mt-3 text-center">
                            <Link to={`/ProductBuy/${productId}`} className="btn btn-dark btn-sm" type="button" style={{ width: "100%", borderRadius: "30px" }} >Buy Now</Link>
                        </div>
                        <div className="mt-3 text-center">
                            <Link to={`/Products`} className="btn btn-sm text-primary" type="button" style={{ width: "100%", borderRadius: "30px" }} ><FaArrowLeft /> Back</Link>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-4 mb-4">
                    <div className="col-12" style={{fontSize:"25px"}}>
                        <div dangerouslySetInnerHTML={{ __html: Description }} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProductDeatils;