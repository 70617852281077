import SignUpSection1 from "./SignUpSection1";
import { useEffect } from "react";
export default function SignUp(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
        <>
        <SignUpSection1/>
        </>
    )
}