import img6 from '../assets/img/home/Hs5.1img.png'
import { NavLink } from 'react-router-dom'
export default function ServicesSection6() {
    return (
        <>
            <section >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pe-xl-12 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                            <h2 className="h1 mb-4">Research and Journalism data</h2>
                            <p className="mb-2 fs-lg">Researchers and Journalists require large amounts of data for accurate analysis
                                & reports.
                                We can assist you to get research and journalism data such as weather,
                                third world development, crime, local and global trends data to power your next research project or news story.
                            </p>
                            <div className="pt-3"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                        </div>
                        <div className="col-lg-6 my-3 wow fadeInRight" data-aos="fade-right" data-aos-delay="0.1s">
                            <div className="position-relative">
                                <img className="card-img" src={img6} title="" alt="About" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}