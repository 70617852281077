import HeaderHero from "../HeaderHero";
import { useEffect, useState } from "react";
//import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { Form } from "react-bootstrap";
import GPayLogo from '../assets/img/icon/Google-Pay-logo.png';
//import allpayApp from '../assets/img/icon/payment-logo.png';
//import Scanner1000 from '../assets/Scanner/GPay_1000.jpeg';
import Swal from "sweetalert2";
function ProductBuy() {
    const { productId } = useParams();
    const [FullName, setFullName] = useState('');
    const [Phone, setPhone] = useState('');
    const [Email, setEmail] = useState('');
    const [Note, setNote] = useState('');
    const [ProductName, setProductName] = useState('');
    const [CurrentAmount, setCurrentAmount] = useState('');
    const [BannerImage, setBannerImage] = useState('');
    //const [showFisrtModel, setshowFisrtModel] = useState(0);
    //const [showSecondModel, setshowSecondModel] = useState(0);
    const [quantity, setQuantity] = useState(1);
    //const [OrderId, setOrderId] = useState('');
    // const [TransactionId, setTransactionId] = useState('');
    //const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        GetOrderDetails();
    }, [])

    function GetOrderDetails() {
        fetch(`https://api.domehelp.com/ProductInformation/GetProductDetailByID?ID=${productId}`, {
            method: "POST",
        }).then((response) => {
            if (!response.ok) {
                Swal.fire("Warning!", "getting Error.", "warning");
                return false;
            }
            return response.json();
        }).then((result) => {
            if (result.status === 1) {
                setProductName(result.data.productName);
                setCurrentAmount(result.data.pCurrentAmount);
                setBannerImage(result.data.pBannerImage);
            }
        })
            .catch((error) => {
                Swal.fire("Warning!", error.toString(), "warning");
            });
    }

    function CreatenewOrder() {
        if (FullName.trim() === '') {
            Swal.fire("Warning!", "Please Enter Your Full Name.", "warning");
            return false;
        }

        if (Phone.trim() === '') {
            Swal.fire("Warning!", "Enter your correct Phone Number.", "warning");
            return;
        }
        if (Phone.length !== 10 || Phone.trim() <= 0) {
            Swal.fire("Warning!", "Enter your correct Phone Number.", "warning");
            return;
        }
        if (Email.trim() === '') {
            Swal.fire("Warning!", "Please Enter Your Email.", "warning");
            return false;
        }
        let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!regex.test(Email)) {
            Swal.fire("Warning!", "Please Enter Your Correct Email.", "warning");
            return false;
        }

        let fd = new FormData();
        fd.append('UserFullName', FullName);
        fd.append('UserMobile', Phone);
        fd.append('UserEmail', Email);
        fd.append('ResponseNotes', Note);
        fd.append('ProductId', productId);
        fd.append('OQuantity', quantity);
        fd.append('Amount', (CurrentAmount * quantity));

        fetch(`https://api.domehelp.com/ProductInformation/CreateNewOrder`, {
            method: "POST",
            body: fd,
        }).then((response) => {
            if (!response.ok) {
                Swal.fire("Warning!", "getting Error.", "warning");
                return false;
            }
            else {
                return response.json();
            }
        }).then((result) => {
            if (result.status === 1) {
                ProceedPayment(result.orderId, result.amount, result.userId);
            }
            else {
                Swal.fire("Warning!", "getting Error.", "warning");
            }
        })
    }

    function ProceedPayment(OrderId, Amount, CustomerId) {
        let fd = new FormData();
        fd.append("orderId", OrderId);
        fd.append("amount", Amount);
        fd.append("customerId", CustomerId);
        fetch(`https://api.domehelp.com/InitiateJuspayPayment/Post`, {
            method: "POST",
            body: fd,
        }).then((response) => {
            if (!response.ok) {
                Swal.fire("Warning!", "getting Error.", "warning");
                return false;
            }
            else {
                return response.json();
            }
        }).then((result) => {
            const orderDetails = {
                orderId: OrderId,
                productId: productId
            };
            
            localStorage.setItem('orderDetails', JSON.stringify(orderDetails));
            setFullName("");
            setPhone("");
            setEmail("");
            setNote("");           
            window.open(result.paymentLinks.web, '_blank');
        })
    }

    /* function PaymentTransactionIDConfirm() {
        if (TransactionId.trim === '') {
            Swal.fire("Warning!", "Please Enter Your Transaction ID.", "warning");
            return false;
        }
        if (TransactionId.length !== 12) {
            Swal.fire("Warning!", "Please Enter Correct Transaction ID.", "warning");
            return false;
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "Please ensure that your transaction ID is correct before confirming.",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`https://api.domehelp.com/ProductInformation/UpdateTransaction?OrderId=${OrderId}&TransactionId=${TransactionId}`, {
                    method: "POST",
                }).then((response) => {
                    if (!response.ok) {
                        Swal.fire("Warning!", "getting Error.", "warning");
                        return false;
                    }
                    else {
                        return response.json()
                    }
                }).then((results) => {
                    if (results.status === 1) {
                        OrderReceivedPending(OrderId);
                    }
                    else {
                        Swal.fire("Warning!", "getting Error.", "warning");
                        return false;
                    }
                })
            }
       })
     }*/

    /*function CancelPayment() {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to Cancel this Order Payment?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setshowFisrtModel(0);
                setFullName("");
                setPhone("");
                setEmail("");
                setNote("");
            }
        })
    }*/
   

    return (
        <>
            <HeaderHero Name={"Product Deatils"} />
            <div className="container">
                <div className="row justify-content-center mt-5 mb-5">
                    <div className="col-lg-8 col-10" style={{ paddingRight: "5%" }}>
                        <div className="mb-5">
                            <h3 style={{ textAlign: "left" }}>Billing details</h3>
                            <Form>
                                <div className="row mb-3">
                                    <div className="col-12 mt-2">
                                        <Form.Group controlId="formFirstName">
                                            <Form.Label style={{ color: '#000' }}>Full Name *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="FullName"
                                                value={FullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <Form.Group controlId="formLastName">
                                            <Form.Label style={{ color: '#000' }}>Phone *</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="Phone"
                                                value={Phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <Form.Group controlId="formMobile">
                                            <Form.Label style={{ color: '#000' }}>Email address *</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="Email"
                                                value={Email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                        <p className="mb-0" style={{ fontSize: "12px", color: "red" }}>* Kindly provide us your valid email address as we will send the product links on this email address.        </p>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="mt-5">
                            <h3 style={{ textAlign: "left" }}>Additional information</h3>
                            <Form>
                                <div className="row mb-3">
                                    <div className="col-12 mt-2">
                                        <Form.Group controlId="formFullName">
                                            <Form.Label style={{ color: '#000' }}>Order notes (optional)</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={2}
                                                name="FullName"
                                                value={Note}
                                                onChange={(e) => setNote(e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="col-lg-4 col-10">
                        <h3 style={{ textAlign: "left" }}>Your order</h3>
                        <div className="row mt-5">
                            <div className="col-2" style={{ padding: "0px" }}>
                                {BannerImage && (<img className="width-55" src={`https://api.domehelp.com/ProductDoc/${BannerImage}`} alt="" />)}
                            </div>
                            <div className="col-8" style={{ padding: "0px" }}>
                                <h6 style={{ fontSize: "16px" }}>{ProductName}&nbsp;<span className="product-quantity">×&nbsp;{quantity}</span></h6>
                            </div>
                            <div className="col-2" style={{ padding: "0px" }}>
                                ₹{CurrentAmount}
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-12" style={{ padding: "0px" }}>
                                <table id="tblbuyorderAmount">
                                    <tbody>
                                        <tr style={{ border: "1px solid #e1e1e1", fontSize: "14px" }}>
                                            <td>Subtotal</td>
                                            <td style={{ textAlign: "right" }}>₹{CurrentAmount}</td>
                                        </tr>
                                        <tr style={{ color: "black" }}>
                                            <td>Subtotal</td>
                                            <td style={{ textAlign: "right" }}>₹{CurrentAmount}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                            <div className="col-12" style={{ padding: "10px", border: "1px solid #e1e1e1" }}>
                                <h6 style={{ fontSize: "14px" }}><input type="radio" checked readOnly /> Google Pay Payment Solutions</h6>
                                <img src={GPayLogo} alt="gpay" style={{ width: "120px", height: "60px" }} />
                                <p style={{ fontSize: "13px", marginBottom: "0px", padding: "0px 30px" }}>All UPI apps, Debit and Credit Cards, and NetBanking accepted | Powered by G pay</p>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-4">
                            <div className="col-12 p-0">
                                <button type="button" className="btn btn-dark" style={{ width: "100%", borderRadius: "0px" }} onClick={() => { CreatenewOrder() }}>Proceed to Payment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={showFisrtModel === 0 ? `modal fade d-none` : `modal fade show d-block`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden={showFisrtModel === 0 ? `true` : `false`} style={{ backgroundColor: "#000000b0" }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content custom-content">
                        <div className="modal-header bg-primary" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", position: "relative", padding: "10px 15px 5px 15px" }}>
                            <div style={{ width: "100%" }}>
                                <h5 style={{ fontSize: "16px", color: "white" }}>Domehelp</h5>
                                <h5 style={{ fontSize: "12px", color: "white", backgroundColor: "#6b9de9cc", width: "120px", padding: "5px", borderRadius: "2px" }}>Order ID : #{OrderId}</h5>
                            </div>
                            <button type="button" className="custom-close-btn" data-dismiss="modal" aria-label="Close" onClick={() => { CancelPayment() }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-2">
                            <div style={{ border: "1px solid #80808047", padding: "10px", textAlign: "center" }}>
                                <div style={{ width: "50%", margin: "auto", border: "1px solid #80808047" }}><img src={Scanner1000} alt="" /></div>
                                <h3 style={{ backgroundColor: "#80808021", color: "#00000066", width: "200px", fontSize: "20px", margin: "auto", marginTop: "10px", marginBottom: "10px" }}>igjoinyhitu</h3>
                                <p style={{ fontSize: "13px", marginBottom: "0px" }}>Please scan the QR code with any  UPI app to pay for your order.Kindly check your email after payment,and be sure to check your spam folder as well.If you do not receive any mail,please contact us immediately at contact@domehelp.com</p>
                                <img src={allpayApp} alt="gpay" style={{ width: "200px", height: "60px" }} />
                            </div>
                        </div>
                        <div className="modal-footer p-3" style={{ justifyContent: "space-between" }}>
                            <h6>₹{CurrentAmount * quantity}</h6>
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => { setshowFisrtModel(0); setshowSecondModel(1); }}>Proceed to Next</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={showSecondModel === 0 ? `modal fade d-none` : `modal fade show d-block`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden={showSecondModel === 0 ? `true` : `false`} style={{ backgroundColor: "#000000b0" }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content custom-content">
                        <div className="modal-header bg-primary" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", position: "relative", padding: "10px 15px 5px 15px" }}>
                            <div style={{ width: "100%" }}>
                                <h5 style={{ fontSize: "16px", color: "white" }}>Domehelp</h5>
                                <h5 style={{ fontSize: "12px", color: "white", backgroundColor: "#6b9de9cc", width: "120px", padding: "5px", borderRadius: "2px" }}>Order ID : #{OrderId}</h5>
                            </div>
                            <button type="button" className="custom-close-btn" data-dismiss="modal" aria-label="Close" onClick={() => { setshowFisrtModel(1); setshowSecondModel(0); }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-2">
                            <div style={{ border: "1px solid #80808047", padding: "10px", textAlign: "center" }}>
                                <h3 style={{ textAlign: "center", fontSize: "14px", margin: "10px" }}>Enter 12-digit Transaction/UTR/Reference ID:</h3>
                                <input className="my-2" type="text" style={{ border: "1px solid black" }} value={TransactionId} onChange={(e) => { setTransactionId(e.target.value) }} />
                                <p style={{ fontSize: "13px", marginBottom: "0px", textAlign: "center" }}>Please ensure that the amount has been deducted from your account before clicking "Confirm". We will verfiy your transaction once submitted.</p>
                                <img src={allpayApp} alt="gpay" style={{ width: "200px", height: "60px" }} />
                            </div>
                        </div>
                        <div className="modal-footer p-3" style={{ justifyContent: "space-between" }}>
                            <button type="button" style={{ background: "none", border: "none" }} onClick={() => { setshowFisrtModel(1); setshowSecondModel(0); }}>Back</button>
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => { PaymentTransactionIDConfirm() }}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default ProductBuy;