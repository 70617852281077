import '../assets/css/theme.css'
import { LuDatabaseBackup } from "react-icons/lu";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import HS5img from '../assets/img/home/Hs5.1img.png'
import img52 from '../assets/img/home/HomeSection5img.svg'
import { NavLink } from "react-router-dom";
export default function HomeSection5() {
    return (
        <>
            <section >
                <div className="section effect-section">
                    <div className="effect effect-middle bg-gray-100"></div>
                    <div className="container">
                        <div className="col-12">
                            <div className="cmn-title-div text-center bdr-row">
                                <h2>Data Delivery and Formats</h2>
                                <p>We make every effort to transmit data in a format that is effortless and easily accessible for our clients. Our experts can transform data into formats like – XLS, CSV, JSON, PDF, TXT, IMAGE & more. Keeping your convenience in mind we provide you with simple & clean data that is insightful and easily sortable to get any information faster, smoother, and reliable.</p>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-5 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                <div className="icon-lg bg-gray-100 border rounded text-primary border-primary dots-icon mb-4">
                                    <LuDatabaseBackup />
                                    <span className="dots">
                                        <i className="dot dot1"></i>
                                        <i className="dot dot2"></i>
                                        <i className="dot dot3"></i>
                                    </span>
                                </div>
                                <div className="h3 mb-4">Transform data into any structure or format</div>
                                <p className="m-0">Domhelp helps you to scrape data and transform it into any structure or format you like – TXT, PDF, Image, JSON, FTP, API, and CSV ensuring ease of convenience to use it for day to day business activities.</p>
                                <div className="pt-4"><NavLink className="popup-youtube btn btn-primary" to={'/Contact'}>Contact Sales</NavLink></div>
                            </div>
                            <div className="col-lg-6 my-3 wow fadeInRight" data-aos="fade-right" data-aos-delay="0.1s">
                                <img src={HS5img} title="" alt="" />
                            </div>
                        </div>
                        <div className="py-5 py-md-6 py-lg-8">
                            <hr />
                        </div>
                        <div className="row align-items-center justify-content-between flex-row-reverse">
                            <div className="col-lg-5 my-3 text-lg-end wow fadeInRight" data-aos="fade-left" data-aos-delay="0.1s">
                                <div className="icon-lg bg-gray-100 border rounded text-primary border-primary dots-icon mb-4">
                                    <AiOutlineDeliveredProcedure />
                                    <span className="dots">
                                        <i className="dot dot1"></i>
                                        <i className="dot dot2"></i>
                                        <i className="dot dot3"></i>
                                    </span>
                                </div>
                                <div className="h3 mb-4">Automated data delivery to any location</div>
                                <p className="m-0">Without compromising with the security and confidentiality of the information, we smoothly facilitate data delivery at any location.</p>
                                <div className="pt-4"><NavLink className="popup-youtube btn btn-primary" to={'/Contact'}>Contact Sales</NavLink></div>
                            </div>
                            <div className="col-lg-6 my-3 wow fadeInLeft" data-aos="fade-right" data-aos-delay="0.1s">
                                <img src={img52} title="" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>

    )
}