import Logoimage from '../assets/img/CompanyLogo-removebg-preview.png';
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';
export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="bg-dark footer">
        <div className="py-7 py-md-8 py-lg-7">
          <div className="container">
            <div className="row gy-6">
              <div className="col-lg-3 me-auto">
                <div style={{ textAlign: 'center' }}><img className='image-fluid' src={Logoimage} title="" alt="Logo" height={'100%'} width={'65%'} /></div>
              </div>
              <div className="col-lg-3 col-6">
                <h5 className="text-white footer-title-01">Navigation</h5>
                <ul className="list-unstyled footer-link-01 m-0">
                  <li><NavLink className="link-white" to={'/'}>Home</NavLink></li>
                  {/* <li><NavLink className="link-white" to={'/B2BDatabase'}>B2B Database</NavLink></li> */}
                  <li><NavLink className="link-white" to={'/Products'}>Products</NavLink></li>
                  <li><NavLink className="link-white" to={'/Pricing'}>Pricing</NavLink></li>
                  <li><NavLink className="link-white" to={'/Contact'}>Contact Us</NavLink></li>
                </ul>
              </div>
              <div className="col-lg-3 col-6">
                <h5 className="text-white footer-title-01">Services</h5>
                <ul className="list-unstyled footer-link-01 m-0">
                  <li><NavLink className="link-white" to={'/Services'}>Real Estate Data Scraping</NavLink></li>
                  <li><NavLink className="link-white" to={'/Services'}>Sales Lead Data</NavLink></li>
                  <li><NavLink className="link-white" to={'/Services'}>E-commerce Data Scraping</NavLink></li>
                  <li><NavLink className="link-white" to={'/Services'}>Recruitment Data Scraping</NavLink></li>
                  <li><NavLink className="link-white" to={'/Services'}>News monitoring data Scraping</NavLink></li>

                </ul>
              </div>
              <div className="col-lg-3 col-12">
                <h5 className="text-white footer-title-01">Contact Us</h5>
                <address>
                  <p className="text-white text-opacity-85 mb-2">A Product of VIABLE INFORMATICS CLUES<br />
                    Urban Estate Phase 1,<br />SCO 234, First Floor<br />Patiala, Punjab <br />Pin : 147002</p>
                  <p className="mb-2"><a className="text-secondary border-bottom border-secondary" href="mailto:contact@domehelp.com">contact@domehelp.com</a></p>
                  <p className="mb-2"><a className="text-secondary border-bottom border-secondary" href="tel:+91-91151-52566">  +91-91151-52566</a></p>
                </address>
                <div className="nav pt-3">
                  <a className="icon-md bg-secondary text-white me-2" href="https://www.facebook.com/vicpl.in"><FaFacebookF /></a>
                  <a className="icon-md bg-secondary text-white me-2" href="https://twitter.com/i/flow/login?redirect_after_login=%2Fviableclues"><FaXTwitter /></a>
                  <a className="icon-md bg-secondary text-white me-2" href="https://www.instagram.com/vicpl.in/?igsh=MWRjdngxcmpia2lqZg%3D%3D"><FaInstagram /> </a>
                  <a className="icon-md bg-secondary text-white me-2" href="https://www.linkedin.com/company/viable-informatics-clues-pvt.-ltd?trk=fc_badge">< FaLinkedinIn /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
        <div className="container">
          <div className="row py-3 gy-3 align-items-center">
            <div className="col-md-6">
              <ul className="nav justify-content-center justify-content-md-start my-2 links-white small list-unstyled footer-link-01 m-0">
                <li className="p-0 mx-3 ms-md-0 me-md-3"><NavLink to={'/PrivacyPolicy'} className="link-white">Privacy &amp; Policy</NavLink></li>
                <li className="p-0 mx-3 ms-md-0 me-md-3"><NavLink to={'/RefundPolicy'} className="link-white">Refund Policy</NavLink></li>
                <li className="p-0 mx-3 ms-md-0 me-md-3"><NavLink to={'/TermsAndCondition'} className="link-white">Terms &amp; Condition</NavLink></li>
              </ul>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <p className="text-white text-opacity-85 small m-0">© {currentYear} Domehelp is Proudly Powered by <a href='https://vicpl.in/' className="link-white">VIC</a></p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
