import img8 from '../assets/img/Services/ServiceSection8.png'
import { NavLink } from 'react-router-dom'
export default function ServicesSection8() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pe-xl-12 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                            <h2 className="h1 mb-4">Manufacturing Industry Data Scraping</h2>
                            <p className="mb-2 fs-lg">Add credibility to your manufacturing business with data analytics. Identify consumer preferences and implement dynamic strategies such as waste reduction, improving machine productivity, increase efficiency, cost-cutting, resource usage, etc.</p>
                            <div className="pt-3"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                        </div>
                        <div className="col-lg-6 my-3 wow fadeInRight" data-aos="fade-right" data-aos-delay="0.1s">
                            <div className="position-relative">
                                <img className="card-img" src={img8} title="" alt="About" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}