import '../assets/css/theme.css'
import React from 'react';
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { GiSellCard } from "react-icons/gi";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { MdOutlineTravelExplore } from "react-icons/md";
import { IoMdCart } from "react-icons/io";
import { DiRequirejs } from "react-icons/di";
import { TbBrandAuth0 } from "react-icons/tb";
import { FcWorkflow } from "react-icons/fc";
import { LiaCloudMeatballSolid } from "react-icons/lia";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { MdOutlineAddShoppingCart } from "react-icons/md";

export default function HomeSection3() {
    return (
        <section id="about" className="section bg-gray-100">
            <div className="container">
                <div className="row section-heading justify-content-center text-center">
                    <div className="col-lg-8 col-xl-7">
                        <h3 className="h1 bg-primary-after after-50px pb-3 mb-3 wow fadeInUp" data-wow-duration=".4s">How businesses use data through web scraping</h3>
                        <div className="lead wow fadeInUp" data-wow-duration=".4s">Web scraping enables businesses to take unstructured data on the world wide web and turn it into structured data so that it can be consumed by their applications, providing significant business value</div>
                    </div>
                </div>
                <div className="row gy-4" >

                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.05s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary"><MdOutlineRealEstateAgent /> <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i
                                className="dot dot3"></i></span></div>
                            <h6>Real Estate</h6>
                            <p className="m-0">Get access to real estate listings, price, price changes, historical price trends, agent’s information, property details, sales history and much more. Data is gathered from thousands of real estate listing sites across India.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.10s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary">
                                <GiSellCard />
                                <span className="dots"><i className="dot dot1"></i><i
                                    className="dot dot2"></i><i className="dot dot3"></i></span></div>
                            <h6>Sales Leads</h6>
                            <p className="m-0">Extract list of companies, people, email addresses and phone numbers for a specific industry you work in. Outsource lead generation and focus on nurturing leads.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.15s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary">
                                <FaHandHoldingDollar />
                                <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i
                                    className="dot dot3"></i></span></div>
                            <h6>Finance</h6>
                            <p className="m-0">Tap into a pool of hundreds of datasets such as pricing, volume, sentiments, twitter, stocktwits, news, sentiment, social media and much more. Leverage most up to date and historical data for publicly traded companies.</p>
                        </div>
                    </div>
                </div>
                <div className="row gy-4" >

                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.2s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary">
                                <MdOutlineTravelExplore />
                                <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i
                                    className="dot dot3"></i></span></div>
                            <h6>Travel and Ieisure</h6>
                            <p className="m-0">Extract flight information, pricing, availability, price change, hotel availability, price analysis, reviews and much more. Build a better customer experience with rich data.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.2s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary">
                                <IoMdCart />
                                <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i
                                    className="dot dot3"></i></span></div>
                            <h6>Ecommerce</h6>
                            <p className="m-0">Data feed for products, pricing, reviews, product performance, size variations, color variations and availability across the internet. Make better decision based on consumer preferences and market trends.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.2s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary">
                                <DiRequirejs />
                                <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i
                                    className="dot dot3"></i></span></div>
                            <h6>Recruitment</h6>
                            <p className="m-0">Monitor jobs, companies, company reviews, candidates, profiles and resumes. Make a move before your competitor can.</p>
                        </div>
                    </div>
                </div>
                <div className="row gy-4" >
                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.2s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary">
                                <TbBrandAuth0 />
                                <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i
                                    className="dot dot3"></i></span></div>
                            <h6>Brand Monitoring</h6>
                            <p className="m-0">Monitor over a million ecommerce website across the internet and discover counterfeit items and obtain contact details of the owners. Data scraped at the highest frequency to protect your brand value.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.2s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary">
                                <FcWorkflow />
                                <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i
                                    className="dot dot3"></i></span></div>
                            <h6>Workflow Automation</h6>
                            <p className="m-0">Automate data entry workflows with OCR, data pipelines and custom data management solutions. Save thousands of hours on repetitive tasks with smart automations.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.2s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary">
                                <LiaCloudMeatballSolid />
                                <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i
                                    className="dot dot3"></i></span></div>
                            <h6>Research and Journalism</h6>
                            <p className="m-0">Get a dataset on virtually anything for your research papers and articles.</p>
                        </div>
                    </div>
                </div>
                <div className="row gy-4" >
                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.2s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary">
                                <MdOutlinePrecisionManufacturing />
                                <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i
                                    className="dot dot3"></i></span></div>
                            <h6>Manufacturing</h6>
                            <p className="m-0">Add credibility to your business production with big business intelligence data. Get production process, equipment & spare parts, vendors, competitors, and quality control data to make accurate decisions at every production stage.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.2s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary">
                                <IoNewspaperOutline />
                                <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i
                                    className="dot dot3"></i></span></div>
                            <h6>News Monitoring</h6>
                            <p className="m-0">Get reliable news data from social media, newspapers, blogs, review sites, and much more. Keep an eye on recent events, local events, and every bit of news across any region of the world from multiple sources. Get structured data to do live news monitoring so you don't miss a single news.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3" style={{ display: 'flex', flex: '1' }}>
                        <div className="card card-body text-center hover-top theme-hover-bg wow fadeInUp" data-wow-duration="0.5s"
                            data-wow-delay="0.2s" style={{ visibility: 'visible' }}>
                            <div className="icon-xl rounded-circle dots-icon mb-4 mx-auto bg-gray-100 text-primary">
                                <MdOutlineAddShoppingCart />
                                <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i
                                    className="dot dot3"></i></span></div>
                            <h6>Retail</h6>
                            <p className="m-0">Get limitless location data with precise details such as - product details, address, contact numbers, pick up options, facilities, etc. Gain visibility over the retail market with location intelligence and geographical information like longitudes, latitudes, city, number of stores, and other data.</p>
                        </div>
                    </div>

                </div>
            </div>

        </section>

    )
}
