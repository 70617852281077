import { FaSearchDollar } from "react-icons/fa";
import { FaUnlockAlt } from "react-icons/fa";
import { FaAlignLeft } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";
import Swal from "sweetalert2";
import { Modal, Button, Form, Image, Row, Col, Accordion } from "react-bootstrap";
import Logoimage from '../assets/img/CompanyLogo-removebg-preview.png';
import { useEffect, useState } from "react";
import { CgAsterisk } from "react-icons/cg";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import Scanner1000 from '../assets/Scanner/GPay_1000.jpeg';
import Scanner2100 from '../assets/Scanner/GPay_2100.jpeg';
import Scanner3000 from '../assets/Scanner/GPay_3000.jpeg';
import profile_Image from '../assets/img/Numerology-profile-image.jpeg';

function NumerlogySection1() {
    const [FullName, setFullName] = useState("");
    const [DOB, setDOB] = useState("");
    const [WhatsappMobile, setWhatsappMobile] = useState("");
    const [AnotherMobile, setAnotherMobile] = useState("");
    const [Email, setEmail] = useState("");
    const [File, setFile] = useState(null);
    const [showNumerologyRequestModal, setShowNumerologyRequestModal] = useState(false);
    const [showServicePackageModal, setShowServicePackageModal] = useState(false);
    const [packageData, setpackageData] = useState([]);
    const [servicePackageID, setservicePackageID] = useState("");
    const [ServiceName, setServiceName] = useState("");
    const [GpScanner, setGpScanner] = useState("");

    useEffect(() => {
        GettAllServicePackages();
    }, []);

    function RequestForNumerology() {
        if (FullName.trim() === '') {
            Swal.fire("Warning!", "Enter Your Full Name", "warning");
            return;
        }
        if (DOB === '') {
            Swal.fire("Warning!", "Enter Your D.O.B.", "warning");
            return;
        }
        const userDOB = new Date(DOB);
        const currentDate = new Date();
    
        // Remove time portion for an accurate comparison
        userDOB.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
    
        // Ensure DOB is not today or in the future
        if (userDOB >= currentDate) {
          Swal.fire("Warning!", "Please enter Your  Valid D.O.B.", "warning");
          return;
        }
        if (WhatsappMobile.trim() === '' || WhatsappMobile.length !== 10) {
            Swal.fire("Warning!", "Enter your Whatsapp Mobile", "warning");
            return;
        }
        if (!File) {
            Swal.fire("Warning!", "Make payment & Take Screenshots then upload here.", "warning");
            return;
        }
        if (servicePackageID === '') {
            Swal.fire("Warning!", "Please Select Your Service Package.", "warning");
            return;
        }
        // FormData Setup

        let fd = new FormData();
        fd.append("FullName", FullName);
        fd.append("UserDOBStr", DOB);
        fd.append("WhatsappMobile", WhatsappMobile);
        fd.append("AnotherMobile", AnotherMobile);
        fd.append("Email", Email);
        fd.append("PaymentDocName", File.name);
        fd.append("File", File);
        fd.append("PackageId", servicePackageID);
        // Fetch API Call
        fetch('https://api.domehelp.com/User/NumerologyLogin', {
            method: "POST",
            body: fd,
        })
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Network response was not ok');
                }
                return resp.json();
            })
            .then((result) => {
                if (result.status === 1) {
                    Swal.fire("Success", result.message, "success");
                    ClearInput();
                    setShowNumerologyRequestModal(false);
                } else {
                    Swal.fire("Failed!", result.message, "error");
                }
            })
            .catch((error) => {
                console.error("Error in handlePurchaseLead:", error);
            });
    }

    function ClearInput() {
        setFullName('');
        setDOB('');
        setWhatsappMobile('');
        setAnotherMobile('');
        setEmail('');
        setFile(null);
        setServiceName('');
        setservicePackageID('');
    }
    function MakeYourPayment(Id, Name) {
        setShowServicePackageModal(false);
        setShowNumerologyRequestModal(true);
        setservicePackageID(Id);
        setServiceName(Name);
        switch (Id) {
            case 1:
                setGpScanner(Scanner1000);
                break;
            case 2:
                setGpScanner(Scanner2100);
                break;
            case 3:
                setGpScanner(Scanner3000);
                break;
            case 4:
                setGpScanner(Scanner3000);
                break;
            case 5:
                setGpScanner(Scanner1000);
                break;
            default:
                setGpScanner(Scanner1000);
                break;
        }
    }

    function GettAllServicePackages() {
        fetch('https://api.domehelp.com/User/GetAllServicePackage', {
            method: "POST",
        }).then((response) => {
            if (!response.ok) {
                return
            }
            else {
                return response.json();
            }
        }).then((result) => {
            if (result.status === 1) {
                setpackageData(result.data);
            } else {
                console.log("Error");
            }
        });

    }
    const handleCancel = () => {
        setShowServicePackageModal(true);
        setShowNumerologyRequestModal(false);
        ClearInput();
    };

    return (
        <>
            <div className="container main-content w-md-98">
                <h2 style={{ color: '#2172e9' }}>Book Your Consultation for Just 1000/- INR</h2>
                <p style={{fontSize:'18px'}}>Discover the profound impact your phone number can have on your life. Unlock your financial potential and gain valuable insights. Payments can be made via Gpay, Paytm, or PhonePe at the number below:</p>
                <div className="row">
                    <div className="col-6" style={{padding:'20px'}}>
                    <p><strong>Contact Number:</strong> 91151-52566</p>
                    <button type="button" className="btn btn-lg btn-primary mb-0 ms-2 text-nowrap" style={{paddingLeft: '50px', paddingRight: '50px', fontSize: '20px'}} onClick={() => setShowServicePackageModal(true)} >Book Your Service</button>
                    </div>
                    <div className="col-6" style={{textAlign:'end'}}>                        
                        <img src={profile_Image} alt="prfile-image" style={{width:'200px',height:'200px',borderRadius:25}}></img>
                    </div>
                </div>
              

                <div className="details" style={{marginTop:0}}>
                    <h3>What You Will Gain</h3>
                    <ul>
                        <li><FaSearchDollar className="detailsIcon" /> Identify the root causes of your money blocks</li>
                        <li><FaUnlockAlt className="detailsIcon" /> Release negative patterns and beliefs</li>
                        <li><FaAlignLeft className="detailsIcon" /> Align your energy with abundance and prosperity</li>
                        <li><FaCoins className="detailsIcon" /> Unlock your earning potential and attract wealth</li>
                    </ul>
                </div>
                <div className="testimonials">
                    <h3>What Our Clients Say</h3>
                    <div className="testimonial">
                        <p>"This consultation was eye-opening! I never realized how much my phone number affected my finances. Highly recommend!"</p>
                        <cite>- Priya S.</cite>
                    </div>
                    <div className="testimonial">
                        <p>"A fantastic experience. The insights I gained have already started to make a difference in my financial situation."</p>
                        <cite>- Rajesh K.</cite>
                    </div>
                    <div className="testimonial">
                        <p>"I was skeptical at first, but this consultation gave me actionable steps to improve my financial situation. Worth every penny!"</p>
                        <cite>- Ananya T.</cite>
                    </div>
                    <div className="testimonial">
                        <p>"The session was very enlightening. I feel more confident about my financial future now. Thank you for the valuable guidance."</p>
                        <cite>- Vikram P.</cite>
                    </div>
                    <div className="testimonial">
                        <p>"Amazing results! I was surprised by how accurate the analysis was. The strategies provided are truly transformative."</p>
                        <cite>- Neha M.</cite>
                    </div>
                </div>
            </div>
            <Modal show={showServicePackageModal} onHide={() => setShowServicePackageModal(false)}>
                <Modal.Header closeButton>
                    <Image src={Logoimage} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
                    <Modal.Title >Service Package</Modal.Title><br />
                </Modal.Header>
                <Modal.Body>
                    <Accordion defaultActiveKey="0">
                        {packageData.map((item, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header>{index + 1}. {item.pName} </Accordion.Header>
                                <Accordion.Body style={{ textAlign: 'justify' }}>
                                    {item.pDescription}
                                    <div className="accordion-footer mt-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ flex: 1 }}>
                                            <label className="payment-badge"><MdOutlineCurrencyRupee />{item.pAmount} only.</label>
                                        </div>
                                        <div style={{ flex: 1, textAlign: 'end' }}>
                                            <button type="button" className="btn btn-sm btn-primary mb-0 ms-2 text-nowrap" style={{padding:'5px 30px',fontSize:'16px'}} onClick={() => MakeYourPayment(item.packageId, item.pName)} >Make Your Payment</button>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>))}
                    </Accordion>
                </Modal.Body>
            </Modal>
            <Modal show={showNumerologyRequestModal} onHide={() => setShowNumerologyRequestModal(false)}>
                <Modal.Header closeButton>
                    <Image src={Logoimage} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
                    <Modal.Title >Transform Your Life with the Power of Your Phone Number</Modal.Title><br />
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Col md={7}>
                                <Row>
                                    <Col>
                                        <Form.Label style={{ color: '#1c6eab', fontSize: 18, fontWeight: '600' }}>{ServiceName}</Form.Label>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Group controlId="formFirstName">
                                            <Form.Label style={{ color: '#000' }}><CgAsterisk style={{ color: 'red' }} />Full Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="FullName"
                                                value={FullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="formLastName">
                                            <Form.Label style={{ color: '#000' }}><CgAsterisk style={{ color: 'red' }} />D.O.B.</Form.Label>
                                            <Form.Control
                                                type="datetime-local"
                                                name="DOB"
                                                value={DOB}
                                                onChange={(e) => setDOB(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Group controlId="formMobile">
                                            <Form.Label style={{ color: '#000' }}><CgAsterisk style={{ color: 'red' }} />Whatsapp Mobile</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="WhatsappMobile"
                                                value={WhatsappMobile}
                                                onChange={(e) => setWhatsappMobile(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="formMobile">
                                            <Form.Label style={{ color: '#000' }}>Another Mobile</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="AnotherMobile"
                                                value={AnotherMobile}
                                                onChange={(e) => setAnotherMobile(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Group controlId="formEmail">
                                            <Form.Label style={{ color: '#000' }}>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="Email"
                                                value={Email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Form.Group controlId="formFile">
                                            <Form.Label style={{ color: '#000' }}><CgAsterisk style={{ color: 'red' }} />File (Kindly upload a payment screenshot or receipt of the transaction.)</Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="File"
                                                accept="image/png, image/jpeg, image/jpg, image/gif, image/bmp"
                                                onChange={(e) => setFile(e.target.files[0])}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={5}>
                                <div className="mt-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img
                                        src={GpScanner}
                                        alt="Scanner"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <hr />
                    <div>
                        <p style={{ color: '#000', fontSize: 14 }}><CgAsterisk style={{ color: 'red' }} />Kindly fill in the above information and proceed with the payment. Once we receive your details, our team will contact you soon to provide personalized assistance and services. Thank you for choosing Astro Soul Numerology.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="my-2 me-2 btn btn-danger" onClick={handleCancel}>Cancel</Button><Button className="my-2 me-2 btn btn-secondary" onClick={RequestForNumerology}>Register Now</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default NumerlogySection1;