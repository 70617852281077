import img from '../assets/img/Page404img.svg'
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
export default function Page404() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <main>
                <section className="bg-dark">
                    <div className="container">
                        <div className="row justify-content-center min-vh-100 align-items-center py-10">
                            <div className="col-lg-8 text-center">
                                <div className="pb-5"><img src={img} title="" alt="" /></div>
                                <h3 className="text-white display-4">404 - Page Not Found..</h3>
                                <div className="pt-6"><NavLink className="btn btn-light" to={'/'}>Back to Home</NavLink></div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}