import UserImg from '../assets/img/user.png';
import { NavLink } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { MdLabelImportantOutline } from "react-icons/md";
import { FaUserGear } from "react-icons/fa6";
import { IoSettings } from "react-icons/io5";
import { FaMoneyBillWave } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { FaBell } from "react-icons/fa6";
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import { BsBuildingsFill } from "react-icons/bs";
import { IoBagSharp } from "react-icons/io5";
export default function AdminHeader() {

    const navigate = useNavigate();
    const token = ` ${window.localStorage.getItem("access")}`;
    const userId = `${window.localStorage.getItem("id")}`;

    const [data, setData] = useState("");
    useEffect(() => {
        fetchUser()
    }, [userId]);

    function logout() {
        window.localStorage.clear()
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Log Out Successfully",
            showConfirmButton: false,
            timer: 1500
        });
        navigate(`/Login`);
    }
    function fetchUser() {
        fetch(`https://api.domehelp.com/User/GetUserById?id=${userId}`, {
            method: "POST",
            headers: { Authorization: token },
        }).then((resp) => {
            if (!resp.ok) {
                navigate(`/Login`)
                return
            }
            return resp.json()
        }).then((result) => {
            setData(result.data)
        }).catch((error) => {
            console.log("Error", error)
            navigate(`/Login`)
        })
    }
    const messageCount = 5;
    return (
        <>

            <div className="profile-aside mt-n12">
                <div className="card mb-4">
                    <div className="p-5 text-center">
                        <div className="avatar-xl rounded-circle d-inline-block overflow-hidden">
                            <img src={UserImg} title="" alt="" />
                        </div>
                        <h6 className="fw-500 mt-3 m-0"><span className="fw-700">{data.firstName}</span> {data.lastName}</h6>
                        <span className="small">Admin</span>
                        <div className="pt-2">
                            <button className="btn btn-primary btn-sm" onClick={logout} >
                                <IoLogOutOutline /> Log Out
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card mb-4">
                    <div className="card-header">
                        <h6 className="my-2">My Account</h6><NavLink to={'/AdminHomeSection2'}><FaBell className="bell-icon" />{messageCount > 0 && <span className="badge">{messageCount}</span>}</NavLink>
                    </div>
                    <div className="list-group list-group-flush" style={{ fontSize: "14px" }}>
                        <NavLink to={'/AdminHome'} className="list-group-item list-group-item-action d-flex justify-content-between py-3">
                            <div><MdLabelImportantOutline className=" me-2" /> <span>Manage User</span></div>
                            <div><FaUserGear className="bi-chevron-right" /></div>
                        </NavLink>
                        <NavLink to={'/AdminProfile'} className="list-group-item list-group-item-action d-flex justify-content-between py-3">
                            <div><MdLabelImportantOutline className=" me-2" />  <span>My Profile</span></div>
                            <div><CgProfile className="bi-chevron-right" /></div>
                        </NavLink>
                        <NavLink to={'/AdminSetting'} className="list-group-item list-group-item-action d-flex justify-content-between py-3">
                            <div><MdLabelImportantOutline className=" me-2" /> <span>Settings</span></div>
                            <div><IoSettings className="bi-chevron-right" /></div>
                        </NavLink>
                        <NavLink to={'/AdminManageStateCity'} className="list-group-item list-group-item-action d-flex justify-content-between py-3">
                            <div><MdLabelImportantOutline className=" me-2" />  <span>Manage Place</span></div>
                            <div><BsBuildingsFill className="bi-chevron-right" /></div>
                        </NavLink>
                        <NavLink to={'/AdminManageJob'} className="list-group-item list-group-item-action d-flex justify-content-between py-3">
                            <div><MdLabelImportantOutline className=" me-2" />  <span>Manage Job</span></div>
                            <div><IoBagSharp className="bi-chevron-right" /></div>
                        </NavLink>
                        <NavLink to={'/CommingSoon'} className="list-group-item list-group-item-action d-flex justify-content-between py-3">
                            <div><MdLabelImportantOutline className=" me-2" />  <span>Billing</span></div>
                            <div><FaMoneyBillWave className="bi-chevron-right" /></div>
                        </NavLink>
                        <NavLink to={'/AdminAddNewProduct'} className="list-group-item list-group-item-action d-flex justify-content-between py-3">
                            <div><MdLabelImportantOutline className=" me-2" /><span>Manage Products</span></div>
                            <div><FaUserGear className="bi-chevron-right" /></div>
                        </NavLink>
                        <NavLink to={'/AdminNumerologyUsersList'} className="list-group-item list-group-item-action d-flex justify-content-between py-3">
                            <div><MdLabelImportantOutline className=" me-2" /><span>Numerology User</span></div>
                            <div><FaUserGear className="bi-chevron-right" /></div>
                        </NavLink>
                        <NavLink to={'/AdminManageOrderPending'} className="list-group-item list-group-item-action d-flex justify-content-between py-3">
                            <div><MdLabelImportantOutline className=" me-2" /><span>Pending Orders</span></div>
                            <div><FaUserGear className="bi-chevron-right" /></div>
                        </NavLink>
                        <NavLink to={'/AdminManageOrderCompleted'} className="list-group-item list-group-item-action d-flex justify-content-between py-3">
                            <div><MdLabelImportantOutline className=" me-2" /><span>Completed Orders</span></div>
                            <div><FaUserGear className="bi-chevron-right" /></div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}