import { useEffect } from "react";
import LoginSection1 from "./LoginSection1";
export default function Login() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <LoginSection1 />
        </>

    )
}