// json file is not uploaded on server so for testing we use localhost
import HeaderHero from "../HeaderHero";
import { SiMicrosoftexcel } from "react-icons/si";
import img from '../assets/img/about/about-2.jpg';
import Logoimage from '../assets/img/CompanyLogo-removebg-preview.png';
import { BsFillPatchCheckFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Modal, Button, Form, Image } from "react-bootstrap";
import Swal from "sweetalert2";
import PayPalButton from "../Payment/PayPalButton";
import * as XLSX from 'xlsx';

export default function B2BDataJobWiseSection2() {
    const [data, setData] = useState([]);
    const [cityName, setCityName] = useState("")
    const [jobName, setJobName] = useState("")
    const [totalCount, setTotalCount] = useState(null)
    const [actualAmount, setActualAmount] = useState(null)
    const [discountAmount, setDiscountAmount] = useState(null)
    const [jobId, setJobId] = useState(null)
    const [cityId, setCityId] = useState(null)
    const [stateId, setStateId] = useState(null)
    const [UserId, setUserId] = useState(null)
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [showPaymentPopUp, setShowPaymentPopUp] = useState(false);
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("")
    const [Email, setEmail] = useState("");
    const [Mobile, setMobile] = useState("");
    const [JsonResult, setJsonResult] = useState([]);

    const { jid } = useParams();
    const jxid = (window.atob(jid))
    useEffect(() => {
        FetchJobXrefWithId();
    }, [jxid], [UserId]);

    function FetchJobXrefWithId() {
        fetch(`https://api.domehelp.com/Master/GetJobCityWiseByID?Id=${jxid}`, {
            method: "POST",
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error in response")
                return
            }
            return resp.json()
        }).then((result) => {
            if (result.status === 1) {
                setData(result.data)
                setJobName(result.data.jobs.jobName)
                setCityName(result.data.cities.cityName)
                setTotalCount(result.data.totalCount)
                setJobId(result.data.jobs.jobId)
                setCityId(result.data.cities.cityID)
                setStateId(result.data.states.stateID)
                if (result.data.totalCount === 0) {
                    setActualAmount(0)
                    setDiscountAmount(0)
                } else {
                    setActualAmount(result.data.totalCount * 10)
                    setDiscountAmount(result.data.totalCount * 1)
                }
            } else {
                console.log(result.message)
            }
        })
    }

    function handleSampleleadButton(id, name) {
        setJobName(name);
        setJobId(id);
        setShowPurchaseModal(true);
    }

    function handlePurchaseLead() {
        if (FirstName.trim() === '') {
            Swal.fire("Warning !", "Enter First Name", "warning");
            return;
        }
        if (LastName.trim() === '') {
            Swal.fire("Warning !", "Enter Last Name", "warning");
            return;
        }
        if (Email.trim() === '') {
            Swal.fire("Warning !", "Enter Email", "warning");
            return;
        } else if (!/\S+@\S+\.\S+/.test(Email)) {
            Swal.fire("Warning !", "Please Enter Correct Email", "warning");
            return;
        }
        if (Mobile.trim() === '' || Mobile.length !== 10) {
            Swal.fire("Warning !", "Enter 10 Digit Mobile Number", "warning");
            return;
        }
        let fd = new FormData();
        fd.append("FirstName", FirstName);
        fd.append("LastName", LastName);
        fd.append("email", Email);
        fd.append("Mobile", Mobile);
        fetch(`https://api.domehelp.com/User/AddUserSampleLead`, {
            method: "POST",
            body: fd,
        })
            .then((resp) => {
                if (!resp.ok) {
                    console.log("Error in response");

                    return;
                }
                return resp.json();
            })
            .then((result) => {
                if (result && result.status === 1) {
                    setUserId(result.data.userID);
                    setShowPaymentPopUp(true)
                    FetchJsonFile(jxid)

                } else {
                    console.log(result.message);
                }
            })
            .catch((error) => {
                console.log("Error in handlePurchaseLead:", error);
            });

        setShowPurchaseModal(false);
        ClearInput();
    }
    function ClearInput() {
        setFirstName('');
        setLastName('');
        setEmail('');
        setMobile('');
    }

    function handlePaymentSuccess(details) {
        if (details.status === 'COMPLETED') {
            downloadExcel(JsonResult)
        } else {
            Swal.fire("Faild To Download!", "Payment is not completed", 'error')
        }
        handlePurchaseLead()
        let fd = new FormData();
        fd.append("UserId", UserId);
        fd.append("StateId", stateId);
        fd.append("CityId", cityId);
        fd.append("JobId", jobId);
        fd.append("Amount", discountAmount);
        fd.append("TranscationId", details.id);
        fd.append("PaymentDate", details.update_time);
        fd.append("PaymentStatus", details.status);
        fetch(`https://api.domehelp.com/User/CreatePayment`, {
            method: "POST",
            body: fd,
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error in Payment Details Save response")
                return
            }
            return resp.json()
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire("Success", result.message, "success")
                SaveInformationInPurchaseLead();
                setShowPaymentPopUp(false)
            } else {
                Swal.fire("Faild !", result.message, "error")
            }
        })
        setShowPurchaseModal(false);
        ClearInput();
    };
    function FetchJsonFile(id) {
        let fd = new FormData();
        fd.append("id", id);
        fetch(`http://localhost:5055/Master/DownloadFileForPurchaseLead`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error in json file response")
                return
            }
            return resp.json()
        }).then((result) => {
            if (result.status === 0) {
                Swal.fire("Faild !", "No Lead Available For this Job in Given City", 'info');
                setShowPaymentPopUp(false)
            }
            else {
                setJsonResult(result)
            }
        })
    }
    function downloadExcel(jsonData) {
        const worksheet = XLSX.utils.json_to_sheet(jsonData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'DataSheet.xlsx');
    };
    function SaveInformationInPurchaseLead() {
        let fd = new FormData();
        fd.append("UserId", UserId);
        fd.append("StateId", stateId);
        fd.append("CityId", cityId);
        fd.append("JobId", jobId);
        fetch(`https://api.domehelp.com/User/SavePurchaseLeadInfo`, {
            method: "POST",
            body: fd,
        })
            .then((resp) => {
                if (!resp.ok) {
                    console.log("Error In response of save purchase lead information");
                    return;
                }
                return resp.json();
            })
            .then((result) => {
                if (result && result.status === 1) {
                    //  console.log(result.message);
                } else {
                    console.log(result.message);
                }
            })
            .catch((error) => {
                console.log("Error in SaveInformationInPurchaseLead:", error);
            });
    }


    return (
        <>
            <HeaderHero Name="PurchaseList" />
            <section className="bg-black-100">
                <div className="container">
                    <div className="row justify-content-center gy-4 align-items-center">
                        <div className="col-lg-6 pe-xl-12 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.1s">
                            <h2 className="h1 mb-3">List of <strong style={{ color: "rgb(0 101 254)" }}>{jobName} </strong>in {cityName}</h2>
                            <p className="fs-lg">Number of {jobName} in {cityName} with email address, phone number, geocoded address, and other key details for download.</p>
                            <div className="row pb-2 pt-1">
                                <div className="col-12">
                                    <ul className="list-unstyled">
                                        <li className="py-1 d-flex">
                                            <i className="text-secondary me-2"><BsFillPatchCheckFill /></i><span className="mx-1" style={{ color: "rgb(0 101 254)" }}>{totalCount} </span> Number of {jobName}
                                        </li>
                                        <li className="py-1 d-flex">
                                            <i className="text-secondary me-2"><BsFillPatchCheckFill /></i><span className="mx-1" style={{ color: "rgb(0 101 254)" }}>{totalCount} </span>  Email addresses
                                        </li>
                                        <li className="py-1 d-flex">
                                            <i className="text-secondary me-2"><BsFillPatchCheckFill /></i><span className="mx-1" style={{ color: "rgb(0 101 254)" }}>{totalCount} </span>  Phone numbers
                                        </li>
                                        <li className="py-1 d-flex">
                                            <i className="text-secondary me-2"><BsFillPatchCheckFill /></i><span className="mx-1" style={{ color: "rgb(0 101 254)" }}>{totalCount} </span>  With Websites
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p className="fs-lg">Data Updated on <strong>June 10, 2024</strong></p>
                            <h4 className="fs-lg d-flex"><strong style={{ color: "rgb(0 101 254)" }}>&#8377; {discountAmount}</strong><p className=" mx-2"><del style={{ color: "red" }}>&#8377; {actualAmount}</del></p>(90% off)</h4>
                            <p className="fs-lg">Domehelp has the most up to date and comprehensive {jobName} list in {cityName}. Our lists are constantly being verified and our database is constantly being updated.
                            </p>
                            <button className="btn btn-dark my-2" onClick={() => handleSampleleadButton(jxid, jobName)}><SiMicrosoftexcel /> PURCHASE LEAD LIST</button>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-duration=".4s" data-wow-delay="0.1s">
                            <img src={img} title="" alt="" />
                        </div>
                    </div>
                </div>

                <Modal show={showPurchaseModal} onHide={() => setShowPurchaseModal(false)}>
                    <Modal.Header closeButton>
                        <Image src={Logoimage} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
                        <Modal.Title >Free sample lead list of <strong>{jobName} </strong> in India</Modal.Title><br />
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Name"
                                    value={FirstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Name"
                                    value={LastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formFirstName">
                                
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formLastName">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={Email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formLastName">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="mobile"
                                    value={Mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="my-2 me-2 btn btn-secondary" onClick={handlePurchaseLead}>Register Now</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showPaymentPopUp} onHide={() => setShowPaymentPopUp(false)}>
                    <Modal.Header closeButton>
                        <Image src={Logoimage} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
                        <Modal.Title >Choose Payment Method</Modal.Title><br />
                    </Modal.Header>
                    <Modal.Body>
                        <h6>Paybale Amount is  <strong style={{ color: "rgb(0 101 254)" }}>&#8377; {discountAmount}</strong></h6>
                        <PayPalButton amount={discountAmount} onSuccess={handlePaymentSuccess} />
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
            </section>
        </>
    );
}
