import React, { useState, useEffect } from 'react';
import { Modal, Button, Image, Table } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logoimage from '../assets/img/CompanyLogo-removebg-preview.png';
import { LuBuilding2 } from "react-icons/lu";


export default function UserBillingSection1() {
    const [lgShow, setLgShow] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [data, setData] = useState([]);
    const [selectedState, setSelectedState] = useState(null);

    useEffect(() => {
        fetchPurchaseDetail();
    }, []);

    function fetchPurchaseDetail() {
        const id = window.localStorage.getItem("id");
        let fd = new FormData();
        fd.append("userId", id);
        fetch(`https://api.domehelp.com/User/GetPurchaseInfo`, {
            method: "POST",
            body: fd,
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error In Response");
                return;
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                setData(result.data);
            } else {
                console.log(result.message);
            }
        }).catch((error) => {
            console.error("Fetch error:", error);
        });
    }

    function handleStateClick(state) {
        setSelectedState(state);
    }

    function handleCityClick(item) {
        setSelectedInvoice({
            transactionID: item.transactionID,
            amount: item.amount,
            stateName: item.jobXrefCity.states.stateName,
            cityName: item.jobXrefCity.cities.cityName,
            jobName: item.jobXrefCity.jobs.jobName,
            paymentDate: item.paymentDate,
            paymentStatus: item.paymentStatus,
        });
        setLgShow(true);
    }

    const groupedData = data.reduce((acc, item) => {
        const stateName = item.jobXrefCity.states.stateName;
        if (!acc[stateName]) {
            acc[stateName] = [];
        }
        acc[stateName].push(item);
        return acc;
    }, {});

    return (
        <>

            <div className="container-fluid">

                <h5>User Purchase Information</h5>
                <div className="row mb-4 mt-2 d-flex align-items-center">
                    {Object.keys(groupedData).map((stateName) => (
                        <button
                            key={stateName}
                            className={`btn btn-sm m-1 col-md-2 ${selectedState === stateName ? 'btn-primary' : 'btn-outline-primary'}`}
                            style={{ fontSize: "x-small" }}
                            onClick={() => handleStateClick(stateName)}
                        >
                            <span className='m-2'><LuBuilding2 /></span>
                            {stateName}
                        </button>
                    ))}
                </div>
                {selectedState && (
                    <div className="row mb-4 mt-2 d-flex align-items-center">
                        {groupedData[selectedState].map((item) => (
                            <button
                                key={item.paymentID}
                                className="btn btn-sm btn-info m-1 col-md-2"
                                style={{ fontSize: "xx-small" }}
                                onClick={() => handleCityClick(item)}
                            >
                                {item.jobXrefCity.cities.cityName}
                            </button>
                        ))}
                    </div>
                )}
            </div>
            {selectedInvoice && (
                <InvoiceModal
                    show={lgShow}
                    handleClose={() => setLgShow(false)}
                    invoiceData={selectedInvoice}
                />
            )}
        </>
    );
}

function downloadInvoiceAsPDF(invoiceData) {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text("Invoice", 14, 22);
    doc.setFontSize(12);
    doc.text("Transaction Details", 14, 32);

    const rows = [
        ["Transaction ID", invoiceData.transactionID],
        ["Amount", invoiceData.amount],
        ["State Name", invoiceData.stateName],
        ["City Name", invoiceData.cityName],
        ["Job Name", invoiceData.jobName],
        ["Payment Date", new Date(invoiceData.paymentDate).toLocaleDateString()],
        ["Payment Status", invoiceData.paymentStatus]
    ];

    doc.autoTable({
        startY: 36,
        head: [['Field', 'Value']],
        body: rows,
    });
    doc.save(`Invoice_${invoiceData.transactionID}.pdf`);
}

function InvoiceModal({ show, handleClose, invoiceData }) {
    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Image src={Logoimage} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
                <Modal.Title id="example-modal-sizes-title-lg">
                    View Invoice
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table bordered hover>
                    <tbody>
                        <tr>
                            <td><strong>Transaction ID</strong></td>
                            <td>{invoiceData.transactionID}</td>
                        </tr>
                        <tr>
                            <td><strong>Amount</strong></td>
                            <td>{invoiceData.amount}</td>
                        </tr>
                        <tr>
                            <td><strong>State Name</strong></td>
                            <td>{invoiceData.stateName}</td>
                        </tr>
                        <tr>
                            <td><strong>City Name</strong></td>
                            <td>{invoiceData.cityName}</td>
                        </tr>
                        <tr>
                            <td><strong>Job Name</strong></td>
                            <td>{invoiceData.jobName}</td>
                        </tr>
                        <tr>
                            <td><strong>Payment Date</strong></td>
                            <td>{new Date(invoiceData.paymentDate).toLocaleDateString()}</td>
                        </tr>
                        <tr>
                            <td><strong>Payment Status</strong></td>
                            <td>{invoiceData.paymentStatus}</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => downloadInvoiceAsPDF(invoiceData)}>
                    Download Invoice
                </Button>
            </Modal.Footer>
        </Modal>
    );
}