import '../assets/css/theme.css'
export default function HomeSection6() {

    return (
        <>
            <section className="section bg-gray-100">
                <div className="container">
                    <div className="row section-heading justify-content-center">
                        <div className="col-lg-6 text-center wow fadeInUp" data-aos="fade-up">
                            <h6 className="text-primary mb-4">WHY DOMEHELP?</h6>
                            <h3 className="h1 mb-3">Not Just Leads!.</h3>
                            <p className="fs-lg m-0">Unlock the complete potential of your marketing, sales, analytics, and other business operations with quality leads found nowhere else on the Internet. Decrease your time spent on finding leads and shift your focus to more productive tasks. </p>
                        </div>
                    </div>
                    <div className="row gy-4">
                        <div className=" wow fadeInUp" style={{ display: 'flex', flex: '1' }} data-aos="fade-up">
                            <div className="card smarts-slider-inner">
                                <div className="card-body d-flex">
                                    <div className="col ps-3">
                                        <h3 className='text-white'>Close Faster</h3>
                                        <h1 className='text-white'>62 %</h1>
                                        <h5 className='text-white'>Increase in overall productivity</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" wow fadeInUp" style={{ display: 'flex', flex: '1' }} data-aos="fade-up">
                            <div className="card smarts-slider-inner">
                                <div className="card-body d-flex">
                                    <div className="col ps-3" >
                                        <h3 className='text-white'>Gain Insight</h3>
                                        <h1 className='text-white'>3x</h1>
                                        <h5 className='text-white'>Increase in win rate</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" wow fadeInUp" style={{ display: 'flex', flex: '1' }} data-aos="fade-up">
                            <div className="card smarts-slider-inner">
                                <div className="card-body d-flex">
                                    <div className="col ps-3">
                                        <h3 className='text-white'>Attract Customers</h3>
                                        <h1 className='text-white'>74 %</h1>
                                        <h5 className='text-white'>Decrease in marketing spend</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}