import { useNavigate } from "react-router-dom";
import BgImage from '../assets/img/bg/bg-banner-12.jpg';
import Logo from '../assets/img/CompanyLogo1.png'
import { useState, useEffect } from "react";
const calculateTimeLeft = () => {

  const difference = +new Date('2025-01-01T15:00:00') - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export default function CommingSoon() {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <div key={interval} className={interval}>
        <span className="count">
          {timeLeft[interval]}
        </span>
        <span className="title">
          {interval}{' '}
        </span>

      </div>
    );
  });

  return (
    <>
      <main>
        <section
          className="bg-center bg-cover bg-fixed effect-section"
          style={{ backgroundImage: `url(${BgImage})` }}
        >
          <div className="container">
            <div className="row justify-content-center min-vh-100 align-items-center">
              <div className="col-lg-8 text-center">
                <div className="mb-4">
                  <img
                    style={{ height: '200px' }}
                    src={Logo}
                    title=""
                    alt=""
                  />
                </div>
                <h1 className="display-2 text-white mb-3">We're coming soon..</h1>
                <p className="h5 text-white text-opacity-65 pb-4 fw-400">
                  Our website is under construction. We'll be here soon with our new awesome site.
                </p>
                <div
                  className="count-down count-down-01 justify-content-center"

                > {timerComponents.length ? timerComponents : <span>Time's up!</span>}</div>
                <div className="pt-6">
                  <button className="btn btn-light mb-3" onClick={() => navigate(-1)}>
                    Back to Previous Page
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}