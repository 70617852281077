import img9 from '../assets/img/Services/feature-3.svg'
import img92 from '../assets/img/Services/Servicesection3img.svg'
import { NavLink } from 'react-router-dom'
export default function ServicesSection9() {
    return (
        <>
            <section >
                <div className="section effect-section">
                    <div className="effect effect-middle bg-gray-100"></div>
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-5 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                <div className="col-lg-6 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                    <img src={img92} title="" alt="" />
                                </div>
                                <div className="h3 mb-4">Work-flow automation</div>
                                <p className="m-0">Automate your business processes with workflow automation tools. Route tasks, files, data between people or systems. Increase the efficiency, accuracy, and productivity of your organization through business automation. Any workflow can be automated with workflow tools with a higher level of security and compliance needs.</p>
                                <div className="pt-4"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                            </div>
                            <div className="col-lg-5 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                <div className="col-lg-6 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                    <img src={img9} title="" alt="" />
                                </div>
                                <div className="h3 mb-4">News monitoring data</div>
                                <p className="m-0">Evaluate recent events by scraping news and web data. Extract news data about companies, products, industries, and much more through our advanced web scraping technologies. Get world data, visualizations, infographics, trends, local news, news cycle, and any other type of data you want. Monitor and evaluate every recent event, world trend, or insight.</p>
                                <div className="pt-4"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}