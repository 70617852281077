import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import Loader from "../Component/Loader";

export default function B2BDataJobWiseSection1() {
    const [data, setData] = useState([]);
    const [cityName, setCityName] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const { sid, cid } = useParams();

    useEffect(() => {
        FetchJobWithStateAndCityID();
    }, [sid, cid]);

    useEffect(() => {
        const handleSearch = () => {
            const value = searchTerm.toLowerCase();
            const rows = document.querySelectorAll("#CityDv ul li");
            rows.forEach(row => {
                const text = row.textContent || row.innerText;
                const isVisible = text.toLowerCase().includes(value);
                row.style.display = isVisible ? "" : "none";
            });
        };

        const inputElement = document.getElementById("myInput");
        inputElement.addEventListener("keyup", handleSearch);

        return () => {
            inputElement.removeEventListener("keyup", handleSearch);
        };
    }, [searchTerm]);

    function FetchJobWithStateAndCityID() {
        try {
            fetch(`https://api.domehelp.com/Master/GetJobStateAndCityWise?State=${sid}&City=${cid}`, {
                method: "POST",
            }).then((response) => {
                if (!response.ok) {
                    console.log("Error in response");
                    return;
                }
                return response.json()
            }).then((result) => {
                if (result.status === 1) {
                    setData(result.data);
                    if (result.data.length > 0) {
                        setCityName(result.data[0].cities.cityName);
                    }
                } else {
                    console.error(result.message);
                }
            })
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="d-lg-flex align-items-center justify-content-center">
                            <br /><br /><br /><br />
                            <div className="cmn-title-div">
                                <h2 className="mb-0">Find B2B Leads In <span style={{ color: 'rgb(0 102 255)' }}>{cityName}</span> Across Different Domains</h2>
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-md-12 d-flex mt-2">
                                <input
                                    type="text"
                                    id="myInput"
                                    className="form-control h-75"
                                    placeholder="Search for City  or Job names..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        <div id='CityDv' className="sts-links-div sts-links-customs mt-lg-5">
                            <ul className="row mt-3">
                                {data.length > 0 ? (
                                    data.map((item) => (
                                        <li id={item.jobXrefId} className="col-lg-4 col-md-6 mb-2" key={item.jobXrefId}>
                                            <div className="web-devlopnt-disc">
                                                <span></span>
                                                <NavLink style={{ fontSize: "small" }} to={`/B2BDataJobWiseSection2/${window.btoa(item.jobXrefId)}`}>
                                                    {item.jobs.jobName} in {item.cities.cityName} ({item.totalCount})
                                                </NavLink>
                                            </div>
                                        </li>
                                    ))
                                ) : (
                                    <li className="col-12">
                                        <div className="web-devlopnt-disc">
                                            <span></span>
                                            <div className="text-center"><Loader /></div>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

