import '../assets/css/theme.css'
import Businessimg from '../assets/img/HomeSection1img/bestinsightquality-image.webp'
export default function HomeSection2() {
    return (
        <>
            <div className="py-7 bg-primary">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-8 mx-auto">
                            <div className="logos-text-spaces pt-3 pb-0 text-center" style={{ paddingTop: '2.5rem' }}>
                                <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: '0px', margin: '0px', padding: '0px', position: 'relative', maxWidth: '100%' }}>
                                    <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: '0px', margin: '0px', padding: '0px', maxWidth: '100%' }}>
                                        <img alt="" aria-hidden="true" src={Businessimg} style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: '0px', margin: '0px', padding: '0px' }} />
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}