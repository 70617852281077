import HeaderHero from "../HeaderHero"
import { useEffect } from "react";
import AdminDash from "./AdminDash";
import HeaderHeroProfile from "../HeaderHeroProfile";
import { useNavigate } from "react-router";
export default function ManageUser() {
    const navigate = useNavigate();
    const token = `${window.localStorage.getItem("access")}`;
    const role = `${window.localStorage.getItem('role')}`;
    useEffect(() => {
        if ((token == '' || null) || role == 2) {
            navigate(`/Login`)
            return
        }
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <HeaderHeroProfile />
            <AdminDash />
        </>
    );

}