import '../assets/css/theme.css'
import HomeSection4img from '../assets/img/home/HomeSection4img.png'
import { GiAutomaticSas } from "react-icons/gi";
import { TbBrandAuth0 } from "react-icons/tb";
import { MdRealEstateAgent } from "react-icons/md";
import { GiMiningHelmet } from "react-icons/gi";
import { MdEmojiTransportation } from "react-icons/md";
import { VscLaw } from "react-icons/vsc";
import { BiBuildingHouse } from "react-icons/bi";
export default function HomeSection4() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row align-items-center gy-4 justify-content-between">
                        <div className="col-lg-5 col-xl-4 wow fadeInLeft" data-aos="fade-right" data-aos-delay="0.1s"><br />
                            <div className="h3 mb-3">Trusted by</div>
                            <p className="mb-5">Web scraping enables businesses to take unstructured data on the world wide web and turn it into structured data so that it can be consumed by their applications, providing significant business value</p>
                            <div className="d-flex mb-6">
                                <div className="icon-lg border border-primary rounded bg-gray-100 dots-icon text-primary"><GiAutomaticSas />
                                    <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i className="dot dot3"></i></span>
                                </div>
                                <div className="col ps-3" style={{ marginTop: '-10px' }}>
                                    <p className="m-0">Trusted by <b>76 out of Fortune 500 companies for workflow automation</b>  and data collection</p>
                                </div>
                            </div>
                            <div className="d-flex mb-6">
                                <div className="icon-lg border border-primary rounded bg-gray-100 dots-icon text-primary"><TbBrandAuth0 />
                                    <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i className="dot dot3"></i></span>
                                </div>
                                <div className="col ps-3" style={{ marginTop: '-10px' }}>
                                    <p className="m-0">Trusted by some of the <b>most valuable brands</b> in the world for protection <b>against counterfeits</b></p>
                                </div>
                            </div>
                            <div className="d-flex mb-6">
                                <div className="icon-lg border border-primary rounded bg-gray-100 dots-icon text-primary"><MdRealEstateAgent />
                                    <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i className="dot dot3"></i></span>
                                </div>
                                <div className="col ps-3" >
                                    <p className="m-0">Trusted by some of the <b>largest real estate investments firms</b></p>
                                </div>
                            </div>
                            <div className="d-flex mb-6">
                                <div className="icon-lg border border-primary rounded bg-gray-100 dots-icon text-primary"><GiMiningHelmet />
                                    <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i className="dot dot3"></i></span>
                                </div>
                                <div className="col ps-3" style={{ marginTop: '-10px' }}>
                                    <p className="m-0">Trusted by <b>thousands of startups</b>  and established enterprises for <b>sales data mining</b></p>
                                </div>
                            </div>
                            <div className="d-flex mb-6">
                                <div className="icon-lg border border-primary rounded bg-gray-100 dots-icon text-primary"><MdEmojiTransportation />
                                    <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i className="dot dot3"></i></span>
                                </div>
                                <div className="col ps-3" style={{ marginTop: '-10px' }}>
                                    <p className="m-0">Trusted by <b>top 10 e-commerce companies </b>to get insight into competitor’s products</p>
                                </div>
                            </div>
                            <div className="d-flex mb-6">
                                <div className="icon-lg border border-primary rounded bg-gray-100 dots-icon text-primary"><VscLaw />
                                    <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i className="dot dot3"></i></span>
                                </div>
                                <div className="col ps-3">
                                    <p className="m-0">Trusted by <b>top researchers and journalist</b>  to source data</p>
                                </div>
                            </div>
                            <div className="d-flex mb-6">
                                <div className="icon-lg border border-primary rounded bg-gray-100 dots-icon text-primary"><BiBuildingHouse />
                                    <span className="dots"><i className="dot dot1"></i><i className="dot dot2"></i><i className="dot dot3"></i></span>
                                </div>
                                <div className="col ps-3">
                                    <p className="m-0">Trusted by some of the largets<b> recruitment firms</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 text-center wow fadeInRight" data-aos="fade-left" data-aos-delay="0.1s">
                            <img src={HomeSection4img} title="" alt="" style={{ maxWidth: '125%', marginTop: '10%' }} /></div>
                    </div>

                </div>
            </section>

        </>
    )
}