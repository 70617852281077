import Logoimage from '../assets/img/CompanyLogo-removebg-preview.png';
import LogoWhite from '../assets/img/CompanyLogo1.png'
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
function Header() {
    const [navbarOpen, setNavbarOpen] = useState(false);

    const toggleNavbar = () => {
        setNavbarOpen(!navbarOpen);
    };

    const closeNavbar = () => {
        setNavbarOpen(false);
    };

    MyNavigation();
    return (
        <header className="main-header main-header-01 headroom navbar-light fixed-top navbar-transparent headroom--not-bottom headroom--pinned headroom--top">
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <NavLink className="navbar-brand header-navbar-brand" to='/' style={{ width: '40px' }}>
                        <img className="logo-dark" src={Logoimage} title="" alt="" width={'100%'} height={'100%'} />
                        <img className="logo-light" src={LogoWhite} title="" alt="" />
                    </NavLink>
                    <div className={"collapse navbar-collapse" + (navbarOpen ? " show" : "")} id="navbarSupportedContent">
                        <ul className="navbar-nav m-auto">
                            <li className="nav-item dropdown">
                                <NavLink to='/' onClick={closeNavbar} className="nav-link">Home</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink to='/Services' onClick={closeNavbar} className="nav-link">Services</NavLink>
                            </li>
                            {/* <li className="nav-item dropdown">
                                <NavLink to='/B2BDatabase' onClick={closeNavbar} className="nav-link">B2B Database</NavLink>
                            </li> */}
                            <li className="nav-item dropdown">
                                <NavLink to='/Products' onClick={closeNavbar} className="nav-link">Products</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink to='/Contact' onClick={closeNavbar} className="nav-link">Contact Us</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink to='/Numerology' onClick={closeNavbar} className="nav-link">Numerology Insights</NavLink>
                            </li>
                        </ul>
                        <ul className="navbar-nav ms-lg-2">
                            <li className="nav-item d-none d-lg-block">
                                <NavLink to='/Login' className="btn btn-sm btn-secondary mb-0 ms-2 text-nowrap">Login</NavLink>
                            </li>
                        </ul>
                        <ul className="navbar-nav d-lg-none">
                            <li className="nav-item">
                                <NavLink to='/Login' onClick={closeNavbar} className="btn btn-sm btn-secondary mb-0 ms-2 text-nowrap">Login</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="nav align-items-center">
                        <button className="navbar-toggler collapsed" onClick={toggleNavbar} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="toggler-icon">
                            </span>
                        </button>
                    </div>
                </div>
            </nav>
        </header>
    );
}
export default Header;

const MyNavigation = () => {
    const [scrollPos, setScrollPos] = useState(0);
    const [navDark, setNavDark] = useState(null);
    const [navLight, setNavLight] = useState(null);
    const [navOne, setNavOne] = useState(null);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPos(window.scrollY || document.documentElement.scrollTop);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setNavDark(document.querySelector(".headroom--top"));
        setNavLight(document.querySelector(".headroom--not-top"));
        setNavOne(document.querySelector(".main-header"));
    }, []);

    useEffect(() => {
        const navCustom = () => {
            const add_class_on_scroll = () => {
                navDark.classList.add("navbar-scrolled", "headroom--not-top");
                navDark.classList.remove("headroom--top");
            };

            const remove_class_on_scroll = () => {
                navDark.classList.remove("navbar-scrolled", "headroom--not-top");
                navDark.classList.add("headroom--top");
            };

            if (navDark !== null) {
                const navDarkHeight = navDark.offsetHeight;
                if (scrollPos >= navDarkHeight) {
                    add_class_on_scroll();
                } else {
                    remove_class_on_scroll();
                }
            }
        };

        const navCustom2 = () => {
            const add_class_on_scroll2 = () => {
                navLight.classList.add("navbar-scrolled", "headroom--top");
                navLight.classList.remove("headroom--not-top");
            };

            const remove_class_on_scroll2 = () => {
                navLight.classList.remove("navbar-scrolled", "headroom--top");
                navLight.classList.add("headroom--not-top");
            };

            if (navLight !== null) {
                const navLightHeight = navLight.offsetHeight;
                if (scrollPos >= navLightHeight) {
                    add_class_on_scroll2();
                } else {
                    remove_class_on_scroll2();
                }
            }
        };

        const navCustom3 = () => {
            const add_class_on_scroll3 = () => {
                navOne.classList.add("navbar-scrolled");
            };

            const remove_class_on_scroll3 = () => {
                navOne.classList.remove("navbar-scrolled");
            };

            if (navOne !== null) {
                const navOneHeight = navOne.offsetHeight;
                if (scrollPos >= navOneHeight) {
                    add_class_on_scroll3();
                } else {
                    remove_class_on_scroll3();
                }
            }
        };

        if (navDark !== null) {
            navCustom();
        } else if (navLight !== null) {
            navCustom2();
        } else if (navOne !== null) {
            navCustom3();
        }
    }, [scrollPos, navDark, navLight, navOne]);

    return null; // Replace null with your JSX for rendering
}