import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";

export default function B2BDataCityWiseSection1() {
    const [data, setData] = useState([]);
    const [stateName, setStateName] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [count, setCount] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            fetchCityByStateId(id);
        }
    }, [id]);

    useEffect(() => {
        const handleSearch = () => {
            const value = searchTerm.toLowerCase();
            const rows = document.querySelectorAll("#CityDv ul li");
            rows.forEach(row => {
                const text = row.textContent || row.innerText;
                const isVisible = text.toLowerCase().includes(value);
                row.style.display = isVisible ? "" : "none";
            });
        };

        const inputElement = document.getElementById("myInput");
        inputElement.addEventListener("keyup", handleSearch);

        return () => {
            inputElement.removeEventListener("keyup", handleSearch);
        };
    }, [searchTerm]);

    async function fetchCityByStateId(stateId) {
        try {
            const response = await fetch(`https://api.domehelp.com/Master/GetJobStateWise?State=${stateId}`, {
                method: "POST"
            });
            if (!response.ok) {
                console.log("Error in response");
                return;
            }
            const result = await response.json();
            if (result.status === 1) {
                setData(result.data);
                if (result.data.length > 0) {
                    setStateName(result.data[0].states.stateName);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    function handleClick(ctid, name) {
        fetch(`https://api.domehelp.com/Master/GetJobStateAndCityWise?State=${id}&City=${ctid}`, {
            method: "POST",
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error in response");
                return;
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                setCount(result.tCount);
            }
        });
    }

    const groupedCities = data.reduce((acc, item) => {
        const cityName = item.cities.cityName;
        if (!acc[cityName]) {
            acc[cityName] = {
                cityID: item.cities.cityID,
                count: 1
            };
        } else {
            acc[cityName].count += 1;
        }
        return acc;
    }, {});

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="d-lg-flex align-items-center justify-content-center">
                            <br /><br /><br /><br />
                            <div className="cmn-title-div">
                                <h2 className="mb-0">Find B2B Leads In <span style={{ color: 'rgb(0 102 255)' }}>{stateName}</span> Across Different Domains</h2>
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-md-12 d-flex mt-2">
                                <input
                                    type="text"
                                    id="myInput"
                                    className="form-control h-75"
                                    placeholder="Search for City State or Job names.."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        <div id='CityDv' className="sts-links-div sts-links-customs mt-lg-5">
                        {Object.keys(groupedCities).length === 0 ? (
                                <div className="text-center">We Are Soon Providing Bussiness Leads in This State</div>
                            ) : (
                            <ul className="row mt-3 mx-10">
                                {
                                    Object.entries(groupedCities).map(([cityName, { cityID, count }]) => (
                                        <li id={cityID} className="col-lg-4 col-md-6 mb-2" key={cityID} >
                                            <div className="web-devlopnt-disc">
                                                <span></span>
                                                <NavLink style={{ fontSize: "small" }} to={`/B2BDataJobWise/${id}/${cityID}`} onClick={() => handleClick(cityID, cityName)}>
                                                    Business Leads in {cityName} ({count})
                                                </NavLink>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
