import { useEffect, useState } from "react";
import AdminHeader from "../Component/AdminHeader";
import HeaderHeroProfile from "../HeaderHeroProfile";
import { FaPlus } from "react-icons/fa6";
import { FaEdit, FaSave, FaTimes } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from "sweetalert2";
import { Pagination, Stack } from "@mui/material";
import AdminManageStateCitySection2 from "./AdminManageStateCitySection2";
import { useNavigate } from "react-router";

export default function AdminManageStateCity() {
    const [data, setData] = useState([]);
    const [state, setState] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [editId, setEditId] = useState(null);
    const [editState, setEditState] = useState('');
    const itemsPerPage = 5;

    const navigate = useNavigate();
    const token = `${window.localStorage.getItem("access")}`;
    const role = `${window.localStorage.getItem('role')}`;
    useEffect(() => {
        if ((token === '' || null) || role === 2) {
            navigate(`/Login`)
            return
        }
    }, []);
    useEffect(() => {
        FetchState();
    }, [token]);

    function FetchState() {
        fetch(`https://api.domehelp.com/Master/GetAllState`, {
            method: "POST",
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Response Error");
                return
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                const options = result.data.map((state) => ({
                    label: state.stateName,
                    value: state.stateID,
                    stateName: state.stateName
                }));
                setData(options);
            } else {
                console.log("Error");
            }
        });
    }

    function handleAddState() {
        if (state.trim() === "") {
            Swal.fire({
                title: "Warning!",
                text: "Enter State Name!",
                icon: "warning",
            });
            return;
        }
        AddState();
    }

    function AddState() {
        let fd = new FormData();
        fd.append("stateName", state);
        fetch(`https://api.domehelp.com/Master/AddState`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error");
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire({
                    title: "Success",
                    text: "State Added Successfully",
                    icon: "success"
                });
                FetchState();
                setState('');
            }
        });
    }

    function handleEdit(id, stateName) {
        setEditId(id);
        setEditState(stateName);
    }

    function handleSave(id) {
        if (editState.trim() === "") {
            Swal.fire({
                title: "Warning!",
                text: "Please Enter State Name!",
                icon: "warning",
            });
            return;
        }
        let fd = new FormData();
        fd.append("stateName", editState);
        fetch(`https://api.domehelp.com/Master/UpdateState?id=${id}`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error");
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire({
                    title: "Success",
                    text: "State Updated Successfully",
                    icon: "success"
                });
                FetchState();
                setEditId(null);
                setEditState('');
            }
        });
    }

    function handleCancel() {
        setEditId(null);
        setEditState('');
    }

    function handleDelete(id) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`https://api.domehelp.com/Master/DeleteState?id=${id}`, {
                    method: "POST",
                }).then((resp) => {
                    if (!resp.ok) {
                        console.log("Response Not ok");
                    }
                    return resp.json();
                }).then((result) => {
                    if (result.status === 1) {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: result.message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        FetchState();
                    }
                });
            }
        });
    }

    function handlePageChange(event, value) {
        setCurrentPage(value);
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <HeaderHeroProfile Name='' />
            <section className="profile-container bg-gray-100">
                <div className="container-fluid px-7">
                    <div className="row align-items-start">
                        <div className="col-xl-2" >
                            <AdminHeader />
                        </div>
                        <div className="col-xl-10">
                            <div className="col-xl-12 mt-3">
                                <div className="profile-content-area mt-6 card card-body">
                                    <div className="border-bottom">
                                        <div className="row mb-4">
                                            <div className="col-md-3"></div>
                                            <div className="col-md-1">
                                                <h6 className="text-info mt-3">State*</h6>
                                            </div>
                                            <div className="col-md-3">
                                                <input type="text" className="form-control" name="state" placeholder="Enter State Name..... " value={state} onChange={(e) => { setState(e.target.value) }} />
                                            </div>
                                            <div className="col-md-1">
                                                <button type="button" className="btn btn-sm btn-success mt-1" onClick={handleAddState}> <FaPlus /> Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <table className="table table-striped table-bordered" style={{ textAlign: 'center', fontSize: "12px" }}>
                                                <thead style={{ background: "#0066ff", color: "white" }} className="thead-dark">
                                                    <tr>
                                                        <th>SR.No</th>
                                                        <th>State Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentItems.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{indexOfFirstItem + index + 1}</td>
                                                            <td>
                                                                {editId === item.value ? (
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        style={{ padding: '0.275rem 0.5rem', }}
                                                                        value={editState}
                                                                        onChange={(e) => setEditState(e.target.value)}
                                                                    />
                                                                ) : (
                                                                    item.stateName
                                                                )}
                                                            </td>
                                                            <td>
                                                                {editId === item.value ? (
                                                                    <>
                                                                        <button className="button-success-xs" onClick={() => handleSave(item.value)}>
                                                                            <FaSave /> Save
                                                                        </button>
                                                                        <button className="button-danger-xs" onClick={handleCancel}>
                                                                            <FaTimes /> Cancel
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button id="btnedit" className="button-primary-xs" onClick={() => handleEdit(item.value, item.stateName)}>
                                                                            <FaEdit /> Edit
                                                                        </button>
                                                                        <button className="button-danger-xs" onClick={() => handleDelete(item.value)}> <RiDeleteBin6Line /> Delete </button>
                                                                    </>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <Stack spacing={2} style={{ alignItems: 'center', marginTop: '20px', marginBottom: '10px' }}>
                                                <Pagination
                                                    count={Math.ceil(data.length / itemsPerPage)}
                                                    page={currentPage}
                                                    onChange={handlePageChange}
                                                    color="primary"
                                                />
                                            </Stack>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <AdminManageStateCitySection2 />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
