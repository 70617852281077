import HeaderHero from "../HeaderHero";
import { useEffect } from "react";
function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <HeaderHero Name={'Privacy Policy'} />
            <div id="TermsAndCondition" className="section py-6 bg-body">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 Privacy-Policy">
                            <div className="common-heading text-l image-content">
                                <h4 style={{ color: '#6b40e3' }}>1. Introduction</h4>
                                <p>This Privacy Policy outlines how <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> ("we," "us," or "our") collects, uses, and protects your personal information. By using our website (www.vicpl.in) and our products or services, you agree to the terms outlined in this Privacy Policy.</p>
                                <h4 style={{ color: '#6b40e3' }}>2. Information We Collect</h4>
                                <p><b>a.</b> Personal Information: We may collect personal information, such as your name, contact information, and payment details when you register for our Services, make a purchase, or communicate with us.</p>
                                <p><b>b.</b> Log Data: When you access our Website, we may collect information that your browser sends, such as your IP address, browser type, and pages visited.</p>
                                <p><b>c.</b> Cookies: We use cookies to enhance your experience on our Website. You can control the use of cookies through your browser settings.</p>
                                <h4 style={{ color: '#6b40e3' }}>3. Use of Information</h4>
                                <p>
                                    We use the collected information for the following purposes:<br />
                                </p><ul className="a" style={{ marginLeft: '10px' }}>
                                    <li style={{ fontSize: '0.875rem' }}>To provide and maintain our Services.</li>
                                    <li style={{ fontSize: '0.875rem' }}>To process transactions and payments.</li>
                                    <li style={{ fontSize: '0.875rem' }}>To communicate with you regarding our Services, updates, and promotional offers.</li>
                                    <li style={{ fontSize: '0.875rem' }}>To analyze and improve the functionality and performance of our Website and Services.</li>
                                </ul>
                                <p></p>
                                <h4 style={{ color: '#6b40e3' }}>4. Data Security</h4>
                                <p><b>a.</b> We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.</p>
                                <p><b>b.</b> Despite our best efforts, no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee absolute security.</p>
                                <h4 style={{ color: '#6b40e3' }}>5. Third-Party Services</h4>
                                <p>Our Website and Services may contain links to third-party websites or services. Please note that we are not responsible for the privacy practices of these third parties, and we encourage you to review their privacy policies.</p>
                                <h4 style={{ color: '#6b40e3' }}>6. Children's Privacy</h4>
                                <p>Our Services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a child, please contact us immediately.</p>
                                <h4 style={{ color: '#6b40e3' }}>7. Changes to this Privacy Policy</h4>
                                <p>We reserve the right to update or modify this Privacy Policy at any time. Changes will be effective upon posting the revised Privacy Policy on our Website. Your continued use of our Services after any changes constitute acceptance of the revised Privacy Policy.</p>
                                <h4 style={{ color: '#6b40e3' }}>8. Your Choices and Rights</h4>
                                <p>You have the right to access, correct, or delete your personal information. You may also choose to opt-out of receiving promotional communications from us.</p>
                                <h4 style={{ color: '#6b40e3' }}>9. Contact Information</h4>
                                <p><b>a.</b> If you have any questions or concerns regarding this Privacy Policy, please contact us at "vic@vicpl.in".</p>
                                <p><b>b.</b> By using our Website and Services, you acknowledge that you have read, understood, and agreed to the terms of this Privacy Policy. If you do not agree with this Privacy Policy, please refrain from using our Services.</p>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default PrivacyPolicy;