import UserHeader from "../Component/UserHeader"
import HeaderHeroProfile from "../HeaderHeroProfile"
import UserBillingSection1 from "./UserBillingSection1"
import { useNavigate } from "react-router";
import { useEffect } from "react";
export default function UserBilling() {
    const navigate = useNavigate();
    const token = `${window.localStorage.getItem("access")}`;
    const role = `${window.localStorage.getItem('role')}`;
    useEffect(() => {
        if ((token == '' || null) || role == 1) {
            navigate(`/Login`)
            return
        }
    }, []);
    return (
        <>
            <HeaderHeroProfile />
            <section className="profile-container bg-gray-100">
                <div className="container-fluid px-7">
                    <div className="row align-items-start">
                        <div className="col-xl-2">
                            <UserHeader />
                        </div>
                        <div className="col-xl-10">
                            <div className="profile-content-area my-6 card card-body">
                                <div className="border-bottom mb-6 pb-6">
                                    <UserBillingSection1 />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}