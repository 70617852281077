import { NavLink } from 'react-router-dom'
import img10 from '../assets/img/bg/bg-banner-7.jpg'

export default function ServicesSection10() {
    return (
        <>
            <section   >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pe-xl-12 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                            <h2 className="h1 mb-4">Social Networkings</h2>
                            <p className="mb-2 fs-lg">Get the latest social media data to monitor social media websites for posts, send email alerts, analyze sentiments and create the best social media strategy to stay ahead of competitors. Scrap social media data such as posts, likes, dislikes, comments, and more. Be it of your customer, retailers, or competitors, we provide accurate and structured social media data to carry out business research.</p>
                            <div className="pt-3"><NavLink className="btn btn-outline-primary" to='/Contact'>More About</NavLink></div>
                        </div>
                        <div className="col-lg-6 my-3 wow fadeInRight" data-aos="fade-right" data-aos-delay="0.1s">
                            <div className="position-relative">
                                <img className="card-img" src={img10} title="" alt="About" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
} 