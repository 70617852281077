import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Modal, Button } from 'react-bootstrap';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';

const FacebookLogins = () => {
    const navigate = useNavigate();
    const token = window.localStorage.getItem("access")

    const [showEditModal, setShowEditModal] = useState(false);
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastname] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [LocaluserId, setLocalUserId] = useState('');
    const [FbId, setFbId] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [cpasswordError, setCpasswordError] = useState('');
    const [cityError, setCityError] = useState('');
    const [stateError, setStateError] = useState('');

    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = () => setShowEditModal(true);

    const handleInputChange = (setValue, setError) => (e) => {
        setValue(e.target.value);
        setError('');
    };

    const validateInputs = () => {
        let isValid = true;

        if (!mobile) {
            setMobileError('Mobile number is required');
            isValid = false;
        }
        else if (mobile.length !== 10) {
            setMobileError("Please Enter 10 Digit !");
            isValid = false;
        }
        if (password !== cpassword) {
            setCpasswordError('Passwords do not match');
            setPasswordError('Passwords do not match');
            isValid = false;
        }
        else if (password.length < 8) {
            setPasswordError('Passwords Too Small !');
            isValid = false;
        }
        if (!city) {
            setCityError('City is required');
            isValid = false;
        }
        if (!state) {
            setStateError('State is required');
            isValid = false;
        }

        return isValid;
    };
    const handleSaveChanges = () => {
        if (validateInputs()) {
            var fd = new FormData();
            fd.append('id', LocaluserId);
            fd.append('userFirstName', firstname);
            fd.append('userLastName', lastname);
            fd.append('userMobile', mobile);
            fd.append('userEmail', email);
            fd.append('userPassword', password);
            fd.append('userCity', city);
            fd.append('userState', state);
            fd.append('userFacebookId', FbId);
            fetch(`https://api.domehelp.com/User/CompleteUserByFacebook`, {
                method: "POST",
                headers: { Authorization: `${token}` },
                body: fd
            })
                .then(response =>
                    response.json())
                .then(result => {
                    if (result.status === 1) {
                        setShowEditModal(false);
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: result.message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        navigate(`/UserProfile`);
                    } if (result.status === 0) {
                        navigate(`/UserProfile`)
                    }
                })
                .catch(error => {
                    console.error("Error:", error);
                });
        }
    };

    return (
        <div>
            <LoginSocialFacebook
                appId="ENTER_YOUR_APP_ID"
                onResolve={(response) => {
                    const fd = new FormData();
                    fd.append("userFirstName", response.data.first_name);
                    fd.append("userLastName", response.data.last_name);
                    fd.append('userEmail', response.data.email);
                    fd.append('userFacebookId', response.data.id);
                    fetch('https://api.domehelp.com/User/CreateUserByFacebook', {
                        method: 'POST',
                        body: fd,
                    }).then((response) => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    }).then((result) => {
                        if (result.status === 0) {
                            Swal.fire("Success", result.message, "Success");
                            navigate(`/Login`);
                        }
                        if (result.status === 1) {
                            Swal.fire({
                                title: 'Info',
                                text: result.message,
                                icon: 'info'
                            });

                            const userId = result.data;
                            setShowEditModal(true)

                            handleShowEditModal();

                            window.localStorage.setItem("access", result.token)
                            window.localStorage.setItem('id', userId)
                            setLocalUserId(userId);
                            setFirstName(response.data.first_name);
                            setLastname(response.data.last_name);
                            setEmail(response.data.email);
                            setFbId(response.data.userID)
                        }
                        if (result.status === 2) {
                            Swal.fire("Login Successful!", result.message, "success");
                            const userId = result.data;
                            window.localStorage.setItem("access", result.token)
                            window.localStorage.setItem('id', userId)
                            navigate(`/UserProfile`);
                        }
                    }).catch(error => {
                        console.error('Error:', error);
                    });
                }}
                onReject={(error) => {
                    console.log(error);
                }}
            >
                <FacebookLoginButton style={{ fontSize: "1rem" }} />
            </LoginSocialFacebook>
            <div className="table-container">
                <Modal show={showEditModal} onHide={handleCloseEditModal} backdrop="static">
                    <Modal.Header>
                        <Modal.Title>Edit User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">First Name</label>
                                        <input
                                            type="text"
                                            className={`form-control`}
                                            placeholder="Rachel"
                                            readOnly='readonly'
                                            value={firstname}
                                            onChange={handleInputChange(setFirstName)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">Last Name</label>
                                        <input
                                            type="text"
                                            className={`form-control `}
                                            placeholder="Roth"
                                            readOnly='readonly'
                                            value={lastname}
                                            onChange={handleInputChange(setLastname)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">Mobile No.</label>
                                        <input
                                            type="text"
                                            className={`form-control ${mobileError ? 'is-invalid' : ''}`}
                                            placeholder="+91 1234567890"
                                            value={mobile}
                                            onChange={handleInputChange(setMobile, setMobileError)}
                                        />
                                        {mobileError && <div className="invalid-feedback">{mobileError}</div>}
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">Email address</label>
                                        <input
                                            type="email"
                                            className={`form-control`}
                                            placeholder="name@example.com"
                                            readOnly='readonly'
                                            value={email}
                                            onChange={handleInputChange(setEmail)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">Password</label>
                                        <input
                                            type="password"
                                            className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                            placeholder="***********"
                                            value={password}
                                            onChange={handleInputChange(setPassword, setPasswordError)}
                                        />
                                        {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-label" style={{ width: "max-content" }}>Confirm Password</label>
                                        <input
                                            type="password"
                                            className={`form-control ${cpasswordError ? 'is-invalid' : ''}`}
                                            placeholder="***********"
                                            value={cpassword}
                                            onChange={handleInputChange(setCpassword, setCpasswordError)}
                                        />
                                        {cpasswordError && <div className="invalid-feedback">{cpasswordError}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">City</label>
                                        <input
                                            type="text"
                                            className={`form-control ${cityError ? 'is-invalid' : ''}`}
                                            placeholder="Patiala.."
                                            value={city}
                                            onChange={handleInputChange(setCity, setCityError)}
                                        />
                                        {cityError && <div className="invalid-feedback">{cityError}</div>}
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label className="form-label">State</label>
                                        <input
                                            type="text"
                                            className={`form-control ${stateError ? 'is-invalid' : ''}`}
                                            placeholder="Punjab.."
                                            value={state}
                                            onChange={handleInputChange(setState, setStateError)}
                                        />
                                        {stateError && <div className="invalid-feedback">{stateError}</div>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default FacebookLogins;
