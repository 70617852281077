import React, { useEffect } from 'react';
import Aos from 'aos';

const MyAos = () => {
  useEffect(() => {
    Aos.init({
      offset: 100,
      duration: 700,
      easing: "ease-out-quad",
      once: true
    });
    window.addEventListener('load', Aos.refresh);

    return () => {
      window.removeEventListener('load', Aos.refresh);
    };
  }, []);

  return null;
};

export default MyAos;