import { useEffect } from "react"
import PricingSection1 from "./PricingSection1";
import HeaderHero from "../HeaderHero";
export default function Pricing(){
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
    return(
        <>
        <HeaderHero Name={"Pricing"}/>
        <PricingSection1/>
        </>
    )
}