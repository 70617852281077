import { useEffect } from "react";
import { Link } from "react-router-dom";
import NumerlogySection1 from "./NumerologySection1";
import "../App.css";

function Numerology(){

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
     <>
        <section className="bg-primary effect-section section">
            <div className="mask bg-primary bg-opacity-80"></div>
            <div className="header-height-bar" style={{ minHeight: '76.7625px' }}></div>
            <div className="container position-relative">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="text-white h1 mb-4">Numerology Insights</h2>
                        <ol className="breadcrumb breadcrumb-light justify-content-center">
                            <li className="breadcrumb-item">
                                <Link to='/' >Home / </Link>
                            </li>
                            <li className="breadcrumb-item active">Numerology Insights</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
       <NumerlogySection1/>
     </>

    );
}

export default Numerology;