import { useEffect } from "react";
import HeaderHero from "../HeaderHero";
import ContactSection1 from "./ContactSection1";

function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <HeaderHero Name={'Contact Us'} />
            <ContactSection1 />
        </>
    );
}
export default Contact;