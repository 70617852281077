import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { FaUserEdit } from "react-icons/fa";
import { AiTwotoneDelete } from "react-icons/ai";
import Swal from "sweetalert2";

export default function AdminHomeSection1() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAdminPasswordModal, setShowAdminPasswordModal] = useState(false);
    const [showAdminPasswordModalDel, setShowAdminPasswordModalDel] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [formData, setFormData] = useState({
        userId: "",
        firstName: "",
        lastName: "",
        userEmail: "",
        userPassword: "",
        userMobile: "",
        userStatus: "",
        userCity: "",
        userState: ""
    });
    const [adminpassword, setAdminPassword] = useState("");
    const token = `${window.localStorage.getItem("access")}`;
    const userId = `${window.localStorage.getItem('id')}`;
    const role = `${window.localStorage.getItem('role')}`;
    useEffect(() => {
        if ((token == '' || null) || role == 2) {
            navigate(`/Login`)
            return
        }
        fetchUser();
    }, []);


    function fetchUser() {
        fetch(`https://api.domehelp.com/User/GetAllUser`, {
            method: "POST",
            headers: { Authorization: token },
        })
            .then((response) => {
                if (!response.ok) {
                    return 
                }
                return response.json();
            })
            .then((result) => {
                if (result.status === 1) {
                    setData(result.data);
                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    }

    const handleEdit = (user) => {
        setSelectedUser(user);
        setFormData({
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            userEmail: user.userEmail,
            userPassword: user.userPassword,
            userMobile: user.userMobile,
            userStatus: user.userStatus,
            userCity: user.userCity,
            userState: user.userState
        });
        setShowEditModal(true);
    };

    function handleDelete(userId) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                setShowAdminPasswordModalDel(true);
                setFormData((prevData) => ({
                    ...prevData,
                    userId: userId
                }));
            }
        });

    }

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        resetFormData();
    };

    const resetFormData = () => {
        setFormData({
            userId: "",
            firstName: "",
            lastName: "",
            userEmail: "",
            userPassword: "",
            userMobile: "",
            userStatus: "",
            userCity: "",
            userState: ""
        });
        setSelectedUser(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleCloseAdminPasswordModal = () => {
        setShowAdminPasswordModal(false);
        setShowAdminPasswordModalDel(false);
        setAdminPassword("");
    };

    const handleSaveChanges = () => {
        setShowAdminPasswordModal(true);
        setShowEditModal(false);
    };

    const handleAdminPasswordUpdate = () => {
        var fd = new FormData();
        fd.append('id', formData.userId);
        fd.append('password', adminpassword);
        fd.append('userFirstName', formData.firstName);
        fd.append('userLastName', formData.lastName);
        fd.append('userMobile', formData.userMobile);
        fd.append('userEmail', formData.userEmail);
        fd.append('userIsActive', formData.userStatus);
        fetch(`http://api.domehelp.com/User/UpdateUserByAdmin`, {
            method: "POST",
            headers: { Authorization: `${window.localStorage.getItem("access")}` },
            body: fd
        })
            .then(response =>
                response.json())
            .then(result => {
                if (result.status === 1) {
                    setShowAdminPasswordModal(false);
                    setShowEditModal(false);
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: result.message,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    resetFormData();
                    setAdminPassword("");

                    fetchUser();
                } else {
                    Swal.fire("Error:", result.message, "error");
                }
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    const handleAdminPasswordSubmit = () => {
        var fd = new FormData();
        fd.append('id', formData.userId);
        fd.append('password', adminpassword);
        fetch(`https://api.domehelp.com/User/Deleteuser`, {
            method: 'POST',
            headers: { Authorization: `${window.localStorage.getItem("access")}` },
            body: fd
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                if (result.status === 1) {
                    setShowAdminPasswordModalDel(false);

                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: result.message,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    resetFormData();
                    setAdminPassword("");
                    fetchUser();
                } else {
                    Swal.fire("Faild !", result.message, "error")
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <>
            <div className="container-fluid">
                <h1 style={{ textAlign: "center" }}>All User Records</h1>

                <div style={{ maxHeight: '450px', overflow: 'scroll' }}>
                    <table className="table table-striped table-bordered" style={{ fontSize: 'small' }}>
                        <thead style={{ background: "rgb(0, 102, 255)", color: "white" }} className="thead-dark">
                            <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th >Email</th>
                                <th>Password</th>
                                <th>Mobile</th>
                                <th>IsActive</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td >{index + 1}</td>
                                    <td >{item.firstName}</td>
                                    <td >{item.lastName}</td>
                                    <td >{item.userEmail}</td>
                                    <td >{item.userPassword}</td>
                                    <td >{item.userMobile}</td>
                                    <td >{item.userStatus === 1 ? 'Yes':'No'}</td>
                                    <td >
                                        <button className="btn btn-sm btn-primary m-1" onClick={() => handleEdit(item)}><FaUserEdit /></button>
                                        <button className="btn btn-sm btn-danger m-1" onClick={() => handleDelete(item.userId)}><AiTwotoneDelete /></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
            </div>
            <div className="table-container">
                <Modal show={showEditModal} onHide={handleCloseEditModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formIsActive">
                                <Form.Label>Is Active 1-Yes 0-No</Form.Label>
                                <Form.Select
                                    name="userStatus"
                                    value={formData.userStatus}
                                    onChange={handleChange}
                                >
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEditModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSaveChanges}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showAdminPasswordModalDel} onHide={handleCloseAdminPasswordModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation Pop-Up</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formAdminPassword">
                                <Form.Label>Admin Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={adminpassword}
                                    placeholder="Enter Your Password....."
                                    onChange={(e) => { setAdminPassword(e.target.value) }}
                                    required
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAdminPasswordModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleAdminPasswordSubmit}>
                            Delete User
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showAdminPasswordModal} onHide={handleCloseAdminPasswordModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation Pop-Up</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formAdminPassword">
                                <Form.Label>Admin Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={adminpassword}
                                    placeholder="Enter Your Passwod....."
                                    onChange={(e) => { setAdminPassword(e.target.value) }}
                                    required
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAdminPasswordModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleAdminPasswordUpdate}>
                            Update User
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}