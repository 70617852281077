import { NavLink, useNavigate } from 'react-router-dom';
import BgImg from '../assets/img/bg/bg-banner-6.jpg';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { BiSolidHide } from "react-icons/bi";
import { BiSolidShow } from "react-icons/bi";
import { useUserId } from '../Component/ContextApi';
import FacebookLogins from './FacebookLogin';

export default function LoginSection1() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setUserId, setToken } = useUserId();


  const handleInputChange = (setter, setError) => (e) => {
    setter(e.target.value);
    setError('');
  };

  const handleLogin = () => {
    let isValid = true;
    if (email.trim() === '') {
      setEmailError('Enter Email!');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Enter a valid email address!');
      isValid = false;
    }
    if (password.trim() === '') {
      setPasswordError('Enter Password!');
      isValid = false;
    }

    if (isValid) {
      login(setUserId, setToken);
    }
  }

  function login(setUserId, setToken) {
    const fd = new FormData();
    fd.append("Email", email);
    fd.append("password", password);
    Swal.fire({
      title: "Loading...",
      html: `
          <div class="custom-loading-container">
              <div class="custom-spinner"></div>
              <p>Please wait...</p>
          </div>
      `,
      showConfirmButton: false,
      background: "rgba(0, 0, 0, 0.8)",
      customClass: {
        popup: 'custom-popup',
        title: 'custom-title',
        htmlContainer: 'custom-html-container'
      }
    });

    fetch(`https://api.domehelp.com/User/Login`, {
      method: "POST",
      body: fd,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === 0) {
          Swal.fire("Login Failed!", data.message, "error");
        } else if (data.status === 1) {
          Swal.fire("Login Successful!", data.message, "success");
          const UserId = data.data;
          setUserId(UserId);
          setToken(data.token);
          window.localStorage.setItem("access", data.token)
          window.localStorage.setItem('id', UserId)
          window.localStorage.setItem('role', 1)
          navigate(`/AdminHome`);
        }
        else if (data.status === 2) {
          Swal.fire("Login Successful!", data.message, "success");
          const UserId = data.data;
          setUserId(UserId);
          setToken(data.token);
          localStorage.setItem("access", data.token)
          window.localStorage.setItem('id', UserId)
          window.localStorage.setItem('role', 2)
          navigate(`/UserProfile`);
        }
      })
      .catch((error) => {
        console.error('Error during login:', error);
        Swal.fire("Login Failed!", "An error occurred during login", "error");
      });
  }



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <main className="bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${BgImg})` }}>
        <div className="container">
          <div className="row justify-content-md-end py-12">
            <div className="col-md-5 col-xl-4">
              <div className="card">
                <div className="card-body p-4">
                  <div className="pb-4 text-center">
                    <h3 className="mb-2">Login</h3>
                    <p>Sign in to your account to continue.</p>
                  </div>
                  <form>
                    <div className="form-group mb-3">
                      <label className="form-label">Email address</label>
                      <input
                        type="email"
                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                        value={email}
                        onChange={handleInputChange(setEmail, setEmailError)}
                        placeholder="name@example.com"
                      />
                      {emailError && <div className="invalid-feedback">{emailError}</div>}
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label">Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                          value={password}
                          onChange={handleInputChange(setPassword, setPasswordError)}
                          placeholder="***********"
                        />
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <BiSolidHide style={{ fontSize: "20px" }} /> : <BiSolidShow style={{ fontSize: "20px" }} />}
                        </button>
                      </div>
                      {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                    </div>
                    <div className="mt-3"><NavLink to={'/ForgetPassword'} className="small fw-700">Forget Password</NavLink></div>
                    <div className="pt-2 text-center">
                      <button type="button" className="btn btn-primary w-100" onClick={handleLogin}>
                        Log in
                      </button>
                    </div>
                    <div className="py-2 text-center"><small>OR</small></div>
                    <div className="pt-2 text-center">
                      <LoginSocialGoogle
                        client_id={"892072627636-3bul27qbjg6ol3d4cgi2hqpn91didgrm.apps.googleusercontent.com"}
                        scope="openid profile email"
                        discoveryDocs="claims_supported"
                        access_type="offline"
                        onResolve={({ provider, data }) => {
                          console.log(provider, data);
                        }}
                        onReject={(err) => {
                          console.log(err);
                        }}
                      >
                        <GoogleLoginButton />
                      </LoginSocialGoogle>
                    </div>
                    <div className='pt-2 text-center'>
                      <FacebookLogins />
                    </div>
                    <div className="mt-3 text-center"><small>Not registered?</small> <NavLink to={'/SignUp'} className="small fw-700">Create account</NavLink></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}