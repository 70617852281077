import B2BDatabaseSection4 from './B2BDatabaseSection4';
import { useEffect } from 'react';
import HeaderHero from '../HeaderHero';
import B2BDataJobWiseSection1 from './B2BDataJobWiseSection1';

export default function B2BDataJobWise() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <HeaderHero Name={'B2B Database'} />
            <B2BDataJobWiseSection1 />
            <B2BDatabaseSection4 />

        </>
    );
}