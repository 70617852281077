
import HomeSection1 from "./HomeSection1";
import HomeSection2 from "./HomeSection2";
import HomeSection3 from "./HomeSection3";
import HomeSection4 from "./HomeSection4";
import HomeSection5 from "./HomeSection5";
import HomeSection6 from "./HomeSection6";
import HomeSection7 from "./HomeSection7";
import { useEffect } from "react";

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <main>
                <HomeSection1 />
                <HomeSection3 />
                <HomeSection2 />
                <HomeSection4 />
                <HomeSection5 />
                <HomeSection6 />
                <HomeSection7 />
            </main>
        </>
    )
}