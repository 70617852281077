import { useEffect, useState } from "react";
import HeaderHero from "../HeaderHero";
import { FaCheck } from "react-icons/fa6";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
function OrderProductDone() {
    const storedDetails = JSON.parse(localStorage.getItem('orderDetails'));
    const OrderId = storedDetails.orderId;
    const [OrderDate, setOrderDate] = useState("");
    const [Total, setTotal] = useState("");
    const [ProductName, setProductName] = useState("");
    const [PCurrentPrize, setPCurrentPrize] = useState("");
    const [OQuantity, setOQuantity] = useState("");
    const [UserFullName, setUserFullName] = useState("");
    const [UserEmail, setUserEmail] = useState("");
    const [UserPhone, setUserPhone] = useState("");
    const [status, setstatus] = useState("");
    const [PaymentStatus, setPaymentStatus] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        CheckStatus(OrderId);
    }, []);

    function CheckStatus(OrderId) {
        fetch(`https://api.domehelp.com/HandleJuspayResponse/Post?OrderId=${OrderId}`, {
            method: "Post"
        }).then((response) => {
            if (!response.ok) {
                Swal.fire("Warning!", "No Record Found This Order.", "warning");
                return false;
            }
            else {
                return response.json()
            }
        }).then((result) => {
            let status = result.status;
            switch (status) {
                case "NEW":
                    setPaymentStatus("Payment cancel by User");
                    setstatus(1);
                    break;
                case "CHARGED":
                    setPaymentStatus("Payment done successfully");
                    OrderReceivedPending(OrderId);
                    setstatus(2)
                    break;
                case "PENDING":
                case "PENDING_VBV":
                    setPaymentStatus("Payment pending");
                    OrderReceivedPending(OrderId);
                    setstatus(3);
                    break;
                case "AUTHENTICATION_FAILED":
                    setPaymentStatus("Authentication failed");
                    setstatus(4);
                    break;
                case "FAILED":
                case "AUTHORIZATION_FAILED":
                    setPaymentStatus("Payment failed");
                    setstatus(5);
                    break;
                default:
                    setPaymentStatus("");
                    setstatus(0);
                    break;
            }
            GetOrderDetailsByID(OrderId);

        })
    }
    function GetOrderDetailsByID(orderId) {
        fetch(`https://api.domehelp.com/ProductInformation/GetOrderDetails?OrderId=${orderId}`, {
            method: "POST",
        }).then((response) => {
            if (!response.ok) {
                Swal.fire("Warning!", "No Record Found This Order.", "warning");
                return false;
            }
            else {
                return response.json()
            }
        }).then((result) => {
            if (result.status === 1) {
                setOrderDate(moment(result.data[0].createdOn).format("DD/MM/YYYY"));
                setTotal(result.data[0].amount);
                setProductName(result.data[0].productName);
                setPCurrentPrize(result.data[0].pCurrentAmount);
                setOQuantity(result.data[0].oQuantity);
                setUserFullName(result.data[0].userFullName);
                setUserEmail(result.data[0].userEmail);
                setUserPhone(result.data[0].userMobile);
            }
            else {
                Swal.fire("Warning!", "No Record Found This Order.", "warning");
                return false;
            }
        })
    }
    const renderStatusMessage = () => {
        switch (status) {
            case 1:
                return (
                    <p style={{ color: "#ff0000", fontWeight: "bold", fontSize: "19px" }}>
                        This order payment was canceled by you. If you would like to purchase another product.<br/><span style={{color:"#0066ff",textDecoration:"underline",cursor:"pointer"}}><Link to='/Products'>Please click here..</Link></span>
                    </p>
                );
            case 2:
                return (
                    <p style={{ color: "#4dbd37", fontWeight: "bold", fontSize: "19px" }}>
                        <FaCheck /> Thank you for your order. We verify payments manually, and you will receive the files within 1-2 hours via email.
                        Please note that if you place your order at night, the delivery may be delayed to 6-8 hours.
                        If you do not receive your order within 12 hours after payment, please contact us immediately at contact@domehelp.com.
                    </p>
                );
            case 3:
                return (
                    <p style={{ color: "#4dbd37", fontWeight: "bold", fontSize: "19px" }}>
                        Thank you for your order.Your payment is pending, and we are verifying it manually. You will receive your order within 1-2 hours via email once the payment is confirmed.
                        If you placed your order at night, the delivery may take up to 6-8 hours. If you do not receive your order within 12 hours after payment, please contact us at contact@domehelp.com.
                    </p>
                );
            case 4:
                return (
                    <p style={{ color: "#4dbd37", fontWeight: "bold", fontSize: "19px" }}>
                        Authentication failed. Please ensure your payment details are correct and try again. If the issue persists, contact your bank or our support team at contact@domehelp.com for further assistance.
                    </p>
                );
            case 5:
                return (
                    <p style={{ color: "#ff0000", fontWeight: "bold", fontSize: "19px" }}>
                       Payment failed. Please try again and ensure you have entered the correct information.
                    </p>
                );
            default:
                return null;
        }
    };
    function OrderReceivedPending(OrderId) {
        fetch(`https://api.domehelp.com/ProductInformation/OrderReceivedPending?OrderId=${OrderId}`, {
            method: "POST",
        }).then((response) => {
            if (!response.ok) {
                Swal.fire("Warning!", "getting Error.", "warning");
                return false;
            }
            else {
                return response.json()
            }
        }).then((results) => {
            if (results.status === 1) {
                
            }
            else {
                Swal.fire("Warning!", "getting Error.", "warning");
                return false;
            }
        })
    }
    return (
        <>
            <HeaderHero Name={"Product Order Done"} />
            <div className="container">
                <div className="row justify-content-center mt-3">
                    <div className="col-10">
                        {renderStatusMessage()}
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-10">
                        <div style={{ backgroundColor: "#80808021", padding: "10px" }}>
                            <table style={{ width: "80%", margin: "auto", textAlign: "center" }}>
                                <tbody>
                                    <tr style={{ color: "#00000066" }}>
                                        <td>Order number:</td>
                                        <td>Date:</td>
                                        <td>Total amount:</td>
                                        <td>Payment method:</td>
                                        <td>Payment status</td>
                                    </tr>
                                    <tr style={{ color: "black" }}>
                                        <td>#{OrderId}</td>
                                        <td>{OrderDate}</td>
                                        <td>{Total}</td>
                                        <td>Pay with UPI</td>
                                        <td>{PaymentStatus}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-10" >
                        <div style={{ width: "100%", borderBottom: "1px solid #80808066", padding: "10px 0px" }}>
                            <h4>Order details</h4>
                        </div>
                        <div className="d-flex justify-content-between" style={{ width: "100%", borderBottom: "1px solid #80808066", padding: "10px 0px" }}>
                            <h6>{ProductName} x {OQuantity}</h6>
                            <h6>{PCurrentPrize}</h6>
                        </div>
                        <div className="d-flex justify-content-between" style={{ width: "100%", padding: "10px 0px" }}>
                            <h6 style={{ fontSize: "14px" }}>Subtotal</h6>
                            <h6 style={{ fontSize: "14px" }}>{Total}</h6>
                        </div>
                        <div className="d-flex justify-content-between" style={{ width: "100%", borderBottom: "1px solid #80808066", padding: "10px 0px" }}>
                            <h6 style={{ fontSize: "14px" }}>Payment method</h6>
                            <h6 style={{ fontSize: "14px" }}>Pay with UPI</h6>
                        </div>
                        <div className="d-flex justify-content-between" style={{ width: "100%", borderBottom: "1px solid #80808066", padding: "10px 0px" }}>
                            <h6>Total</h6>
                            <h6>{Total}</h6>
                        </div>
                        <div style={{ width: "100%", padding: "10px 0px" }}>
                            <h4 style={{ marginTop: "20px" }}>Billing details</h4>
                            <p style={{ margin: "0px", marginTop: "10px" }}>{UserFullName}</p>
                            <p>{UserPhone}</p>
                            <p>{UserEmail}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default OrderProductDone;