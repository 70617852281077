import { NavLink } from "react-router-dom";


function PricingSection1(){
  return (
    <section className="section bg-gray-100">
      <div className="container">
        <div
          className="row section-heading justify-content-center text-center wow fadeInUp"
          data-wow-duration=".4s"
          data-wow-delay="0.1s"
        >
          <div className="col-lg-8 col-xl-6">
            <h3 className="h1 title-03 pb-3 mb-3">Pricing Plans</h3>
            <div className="fs-lg">
              Mombo is a HTML5 template based on Sass and Bootstrap 5 with modern and creative multipurpose design you can use it as a startups.
            </div>
          </div>
        </div>
        <div className="row align-items-center gy-4">
          {pricingData.map((plan, index) => (
            <div
              className="col-sm-6 col-lg-3 wow fadeInUp"
              data-wow-duration=".4s"
              data-wow-delay="0.1s"
              key={index}
            >
              <div className="shadow shadow-lg-hover px-4 py-6 bg-body hover-top rounded">
                <div className="pb-3 mb-3 border-bottom">
                  {plan.popular && (
                    <label className="bg-secondary text-white px-4 py-1 mb-4 ms-n5">
                      Popular Choice
                    </label>
                  )}
                  <h5 className="m-0 h1">{plan.price}</h5>
                </div>
                <h6 className="mb-2">{plan.title}</h6>
                <ul className="list-unstyled mb-0">
                  {plan.features.map((feature, idx) => (
                    <li className="py-2" key={idx}>
                      {/* <i className="bi-check-circle-fill text-secondary me-1"></i> */}
                      {feature}
                    </li>
                  ))}
                </ul>
                <div className="pt-4 mt-3 border-top">
                  <NavLink to={plan.link} className={`btn btn-${plan.buttonClass}`}>
                    {plan.buttonText}
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const pricingData = [
  {
    price: '$45',
    title: 'Basic Package',
    features: [
      '10 GB of Space',
      'Unlimited Projects',
      'Unlimited Users',
      'Unlimited Datas',
      'Unlimited File Recovery',
    ],
    link: '/Login',
    buttonClass: 'success',
    buttonText: 'Login',
  },
  {
    price: '$45',
    title: 'Basic Package',
    features: [
      '10 GB of Space',
      'Unlimited Projects',
      'Unlimited Users',
      'Unlimited Datas',
      'Unlimited File Recovery',
    ],
    link: '/Login',
    buttonClass: 'success',
    buttonText: 'Login',
  },
  {
    price: '$45',
    title: 'Basic Package',
    popular: true,
    features: [
      '10 GB of Space',
      'Unlimited Projects',
      'Unlimited Users',
      'Unlimited Datas',
      'Unlimited File Recovery',
    ],
    link: '/Login',
    buttonClass: 'secondary btn-sm',
    buttonText: 'Login',
  },
  {
    price: '$45',
    title: 'Basic Package',
    features: [
      '10 GB of Space',
      'Unlimited Projects',
      'Unlimited Users',
      'Unlimited Datas',
      'Unlimited File Recovery',
    ],
    link: '/Login',
    buttonClass: 'success',
    buttonText: 'Login',
  },
];

export default PricingSection1;
