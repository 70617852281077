import { useEffect, useState } from "react";
import Select from 'react-select';
import Swal from "sweetalert2";
import { Pagination, Stack } from "@mui/material";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa6";
import Loader from "../Component/Loader";
import { useNavigate } from "react-router";

export default function AdminManageJobXref() {
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [jobs, setJobs] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [jobXref, setJobXref] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [TotalCount, setTotalCount] = useState('');
    const [jsonPath, setJsonPath] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [editId, setEditId] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('default')
    const [count, setCount] = useState(0);

    const navigate = useNavigate();
    const token = `${window.localStorage.getItem("access")}`;
    const role = `${window.localStorage.getItem('role')}`;
    useEffect(() => {
        if ((token == '' || null) || role == 2) {
            navigate(`/Login`)
            return
        }
    }, []);

    useEffect(() => {
        FetchJob();
        FetchState();
        FetchJobXrefCity(1, 5);
    }, []);

    useEffect(() => {
        if (selectedState) {
            FetchCity(selectedState.value);
        } else {
            setCities([]);
        }
    }, [selectedState]);

    useEffect(() => {
        const handleSearch = () => {
            const value = searchTerm.toLowerCase();
            const rows = document.querySelectorAll("#myTable tbody tr");
            rows.forEach(row => {
                const text = row.textContent || row.innerText;
                const isVisible = text.toLowerCase().includes(value);
                row.style.display = isVisible ? "" : "none";
            });
        };

        const inputElement = document.getElementById("myInput");
        inputElement.addEventListener("keyup", handleSearch);

        return () => {
            inputElement.removeEventListener("keyup", handleSearch);
        };
    }, [searchTerm]);


    function FetchJob() {
        fetch(`https://api.domehelp.com/Master/GetAllJobs`, {
            method: "POST",
        }).then((resp) => {
            if (!resp.ok) {
                console.error("Error fetching jobs");
                return;
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                const options = result.data.map((Job) => ({
                    label: Job.jobName,
                    value: Job.jobId,
                }));
                setJobs(options);
            } else {
                console.error(result.Message);
            }
        }).catch((error) => {
            console.error("Fetch error: ", error);
        });
    }

    function FetchState() {
        fetch(`https://api.domehelp.com/Master/GetAllState`, {
            method: "POST",
        }).then((resp) => {
            if (!resp.ok) {
                console.error("Error fetching states");
                return;
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                const stateOptions = result.data.map((state) => ({
                    label: state.stateName,
                    value: state.stateID,
                }));
                setStates(stateOptions);
            } else {
                console.error(result.message);
            }
        }).catch((error) => {
            console.error("Fetch error: ", error);
        });
    }

    function FetchCity(stateId) {
        fetch(`https://api.domehelp.com/Master/GetAllCityByStateId?id=${stateId}`, {
            method: "POST"
        }).then((resp) => {
            if (!resp.ok) {
                console.log("response Error");
                return;
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                const cityOptions = result.data.map((city) => ({
                    label: city.cityName,
                    value: city.cityID,
                }));
                setCities(cityOptions);
            } else {
                console.error(result.Message);
            }
        }).catch((error) => {
            console.error("Fetch error: ", error);
        });
    }

    function handleSubmit() {
        if (!selectedJob) {
            Swal.fire({
                title: "Warning!",
                text: "Please Select Job!",
                icon: "warning",
            });
            return;
        }
        if (!selectedState) {
            Swal.fire({
                title: "Warning!",
                text: "Please Select State!",
                icon: "warning",
            });
            return;
        }
        if (!selectedCity) {
            Swal.fire({
                title: "Warning!",
                text: "Please Select City!",
                icon: "warning",
            });
            return;
        }
        if (jsonPath.trim() === "") {
            Swal.fire({
                title: "Warning!",
                text: "Please Enter Json File Path!",
                icon: "warning",
            });
            return;
        }
        let fd = new FormData();
        fd.append("CityId", selectedCity.value);
        fd.append("StateId", selectedState.value);
        fd.append("JobId", selectedJob.value);
        fd.append("TotalCount", TotalCount);
        fd.append("JsonFile", jsonPath);
        fetch(`https://api.domehelp.com/Master/AddJobxrefCities`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Response Error")
                return
            }
            return resp.json()
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire({
                    title: "Success",
                    text: result.message,
                    icon: "success",
                });
                handleCancel()
                FetchJobXrefCity(currentPage, itemsPerPage)
            }
            else {
                Swal.fire("Faild !", result.message, "error")
            }
        })
        handleCancel()

    }

    function handleCancel() {
        setSelectedJob(null)
        setSelectedCity(null)
        setSelectedState(null)
        setTotalCount('')
        setJsonPath('')
    }
    async function FetchJobXrefCity(cPage, iPerPage) {
        setIsLoading(true);
        try {
            const response = await fetch(`https://api.domehelp.com/Master/GetJobCityWiseFast?pageing=${cPage}&entry=${iPerPage}`, {
                method: "POST"
            });
            if (!response.ok) {
                console.log("Error in response");
                return;
            }

            const result = await response.json();

            if (result.status === 1) {
                setItemsPerPage(iPerPage);
                setCurrentPage(cPage);
                setJobXref(result.data);
                setCount(result.count);
            } else {
                console.log("Error in Fetching");
            }
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setIsLoading(false);
        }
    }


    function handleEdit(id, status) {
        setEditId(id)
        setSelectedStatus(status)
    }
    function handleSave(id) {
        let fd = new FormData();
        fd.append("JxcStatus", selectedStatus);
        fetch(`https://api.domehelp.com/Master/UpdateJobCityWise?Id=${id}`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error in response")
                return
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire("Success", result.message, "success")
                handleCancelEdit();
                setIsLoading(true);
                FetchJobXrefCity(currentPage, itemsPerPage);


            } else {
                Swal.fire("Faild !", result.message, "error")
            }

        })

    }


    function handleDelete(id) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`https://api.domehelp.com/Master/DeleteJobCityWise?ID=${id}`, {
                    method: "POST"
                }).then((resp) => {
                    if (!resp.ok) {
                        console.log("Error in response")
                        return
                    }
                    return resp.json();
                }).then((result) => {
                    if (result.status === 1) {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: result.message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        FetchJobXrefCity(currentPage, itemsPerPage);
                    }
                })
            }
        })

    }


    function handlePageChange(event, value) {
        <Loader />
        setCurrentPage(value);
        FetchJobXrefCity(value, itemsPerPage);
    }

    function handleCancelEdit() {
        setEditId(null)
        setSelectedStatus(null)
    }


    const handleItemsPerPageChange = (e) => {
        <Loader />
        const newItemsPerPage = parseInt(e.target.value);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
        FetchJobXrefCity(currentPage, newItemsPerPage);
    }

    return (
        <>
            <div className="col-xl-12 mt-3 mb-6">
                <div className="profile-content-area card card-body">
                    <div className="row mb-4 d-flex align-items-center">
                        <div className="col-md-3 d-flex flex-column">
                            <h6 className="text-info mb-2">Job<strong style={{ color: "red" }}>*</strong></h6>
                            <Select
                                options={jobs}
                                value={selectedJob}
                                onChange={setSelectedJob}
                                placeholder="Select Job"
                                className="mb-2"
                            />
                        </div>
                        <div className="col-md-2 d-flex flex-column">
                            <h6 className="text-info mb-2">State<strong style={{ color: "red" }}>*</strong></h6>
                            <Select
                                value={selectedState}
                                onChange={setSelectedState}
                                placeholder="Select State"
                                options={states}
                                className="mb-2"
                            />
                        </div>
                        <div className="col-md-2 d-flex flex-column">
                            <h6 className="text-info mb-2">Cities<strong style={{ color: "red" }}>*</strong></h6>
                            <Select
                                value={selectedCity}
                                onChange={setSelectedCity}
                                placeholder="Select Cities"
                                options={cities}
                                className="mb-2"
                            />
                        </div>

                        <div className="col-md-2 d-flex flex-column">
                            <h6 className="text-info mb-2">Total Count</h6>
                            <input
                                type="text"
                                value={TotalCount}
                                onChange={(e) => setTotalCount(e.target.value)}
                                className="mb-2 form-control"
                                placeholder="Enter Total Count"
                                style={{ height: "calc(1.5em + 3px + 0.75rem)" }}
                            />
                        </div>
                        <div className="col-md-3 d-flex flex-column">
                            <h6 className="text-info mb-2">Json Path<strong style={{ color: "red" }}>*</strong></h6>
                            <input
                                type="text"
                                value={jsonPath}
                                onChange={(e) => setJsonPath(e.target.value)}
                                className="mb-2 form-control"
                                placeholder="Enter Total Count"
                                style={{ height: "calc(1.5em + 3px + 0.75rem)" }}
                            />
                        </div>
                        <div className="text-end mb-2">
                            <button className="btn btn-sm btn-success mx-1" onClick={handleSubmit}>Submit</button>
                            <button type="button" className="btn btn-sm btn-danger mx-1" onClick={handleCancel}>Cancel</button>
                        </div>
                        <div className="row mb-1">
                            <div className="col-md-1"></div>
                            <div className="col-md-8 d-flex">
                                <input
                                    type="text"
                                    id="myInput"
                                    className="form-control h-75"
                                    placeholder="Search for City State or Job names.."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div className="col-md-2 d-flex">
                                <p className="text-info mt-2 mx-2">Show</p>
                                <div className="form-group">
                                    <select
                                        style={{ padding: "0.575rem 3.5rem 0.575rem 0.5rem" }}
                                        className="form-select w-0 h-75"
                                        value={itemsPerPage}
                                        onChange={handleItemsPerPageChange}
                                    >
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                        <div>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <table id="myTable" className="table table-striped table-bordered" style={{ textAlign: 'center', fontSize: "12px" }}>
                                    <thead style={{ background: "#0066ff", color: "white" }} className="thead-dark">
                                        <tr>
                                            <th>SR.No</th>
                                            <th>Job Name</th>
                                            <th>State Name</th>
                                            <th>City Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobXref.map((item, index) => (
                                            <tr key={item.jobXrefId}>
                                                <td>{((index + 1) + (parseInt(currentPage) * parseInt(itemsPerPage)) - parseInt(itemsPerPage))}</td>
                                                <td>{item.jobs?.jobName}</td>
                                                <td>{item.states?.stateName}</td>
                                                <td>{item.cities?.cityName}</td>
                                                <td>{editId === item.jobXrefId ?
                                                    <select className="form-control-select" onChange={(e) => setSelectedStatus(e.target.value)}>
                                                        <option value="default">Select..</option>
                                                        <option value={1}>Active</option>
                                                        <option value={0}>Inactive</option>
                                                    </select>
                                                    : item.jxcStatus === 1 ? 'Active' : 'Inactive'}
                                                </td>
                                                <td>
                                                    {editId === item.jobXrefId ? (
                                                        <>
                                                            <button className="button-success-xs" onClick={() => handleSave(item.jobXrefId)}  >
                                                                Save
                                                            </button>
                                                            <button className="button-danger-xs" onClick={handleCancelEdit}>
                                                                Cancel
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button className="button-primary-xs" onClick={() => handleEdit(item.jobXrefId, item.jxcStatus)}>
                                                                {item.jxcStatus === 1 ? <><RxCross2 /> Inactive</> : <><FaCheck />Active</>}
                                                            </button>
                                                            <button className="button-danger-xs" onClick={() => handleDelete(item.jobXrefId)}><RiDeleteBin6Line />
                                                                Delete
                                                            </button>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            <Stack spacing={2} style={{ alignItems: 'center', marginTop: '20px', marginBottom: '10px' }}>
                                <Pagination
                                    count={Math.ceil(count / itemsPerPage)}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            </Stack>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}
