import '../assets/css/theme.css'
import { NavLink } from 'react-router-dom';
import React from 'react';
import Hs7img from '../assets/img/home/hs71img-removebg-preview.png'
export default function HomeSection7() {
    return (
        <>
            <section className='section'>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="crr-btm-box row align-items-end">
                                <div className="crr-btm-box-text col-lg-7" data-aos="fade-left" data-aos-delay="0.4s" >
                                    <h2>Know what <em>Domehelp</em> can do for you</h2>
                                    <p>We gather data and then convert it into various standardized file formats like CSV, Excel, and JSON. To meet the large data needs of companies, we provide accurate and speedy web scraping services.</p><p>These are just sample data of some chosen businesses through web scraping services. We can extract data for any business type.</p>
                                    <div className="pt-4"><NavLink className="popup-youtube btn btn-primary" to={'/Contact'}>Contact Our Expert Now</NavLink></div>
                                </div>
                                <div className="col-lg-5 text-lg-right text-center" data-aos="fade-right" data-aos-delay="0.4s">
                                    <img loading="lazy" src={Hs7img} className="img-fluid" alt="site" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
