import React from "react";
import { NavLink } from "react-router-dom";
import aboutImage from "../assets/img/Services/ServicSec2img.jpg"; // Import the image


export default function ServicesSection2() {
    return (
        <>
            <section className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pe-xl-12 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                            <h2 className="h1 mb-4">Real Estate and Housing Data</h2>
                            <p className="mb-2 fs-lg">Keep a track of the Real Estate market using data scraping. Scrap Real Estate Data such as Agents,
                                Listings, Brokers, Estate Agents, Houses, Mortgages, Building Permits data & much more.
                                Gather housing data for detailed information of current and historical owners,
                                deeds and tax assessments, and precise valuations data to feed your system or receive e-mail alerts.
                            </p>
                            <div className="pt-3"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                        </div>
                        <div className="col-lg-6 my-3 wow fadeInRight" data-aos="fade-right" data-aos-delay="0.1s">
                            <div className="position-relative">
                                <img className="card-img" src={aboutImage} title="" alt="About" />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
