import React, { useState, useEffect } from "react";
import { FaEdit, FaPlus, FaSave, FaTimes } from "react-icons/fa";
import Swal from "sweetalert2";
import { Pagination, Stack } from "@mui/material";
import { RiDeleteBin6Line } from "react-icons/ri";
import HeaderHeroProfile from "../HeaderHeroProfile";
import AdminHeader from "../Component/AdminHeader";
import AdminManageJobXref from "./AdminManageJobXref";
import { useNavigate } from "react-router";
export default function AdminManageJob() {
    const [data, setData] = useState([]);
    const [job, setJob] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [editId, setEditId] = useState(null);
    const [editJob, setEditJob] = useState('');
    const itemsPerPage = 5;
    const navigate = useNavigate();
    const token = `${window.localStorage.getItem("access")}`;
    const role = `${window.localStorage.getItem('role')}`;
    useEffect(() => {
        if ((token == '' || null) || role == 2) {
            navigate(`/Login`)
            return
        }
    }, []);
    useEffect(() => {
        fetchJobs();
    }, []);

    function fetchJobs() {
        fetch(`https://api.domehelp.com/Master/GetAllJobs`, {
            method: "POST"
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error");
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                const options = result.data.map((job) => ({
                    label: job.jobName,
                    value: job.jobId,
                }));
                setData(options);
            }
        });
    }

    function handleAddJob() {
        if (job.trim() === "") {
            Swal.fire({
                title: "Warning!",
                text: "Enter Job Name!",
                icon: "warning",
            });
            return;
        }
        addJob();
    }

    function addJob() {
        let fd = new FormData();
        fd.append("JobName", job);
        fetch(`https://api.domehelp.com/Master/AddJob`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error");
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire({
                    title: "Success",
                    text: "Job Added Successfully",
                    icon: "success"
                });
                fetchJobs();
                setJob('');
            }
        });
    }

    function handleEdit(id, jobName) {
        setEditId(id);
        setEditJob(jobName);
    }

    function handleSave(id) {
        if (editJob.trim() === "") {
            Swal.fire({
                title: "Warning!",
                text: "Please Enter Job Name!",
                icon: "warning",
            });
            return;
        }
        let fd = new FormData();
        fd.append("Jname", editJob);
        fetch(`https://api.domehelp.com/Master/UpdateJob?ID=${id}`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error");
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire({
                    title: "Success",
                    text: "Job Updated Successfully",
                    icon: "success"
                });
                fetchJobs();
                setEditId(null);
                setEditJob('');
            }
        });
    }

    function handleCancel() {
        setEditId(null);
        setEditJob('');
    }

    function handleDelete(id) {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`https://api.domehelp.com/Master/DeleteJob?ID=${id}`, {
                    method: "POST",
                }).then((resp) => {
                    if (!resp.ok) {
                        console.log("Response Not ok");
                    }
                    return resp.json();
                }).then((result) => {
                    if (result.status === 1) {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: result.message,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        fetchJobs();
                    }
                });
            }
        });
    }

    function handlePageChange(event, value) {
        setCurrentPage(value);
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <HeaderHeroProfile Name='' />
            <section className="profile-container bg-gray-100">
                <div className="container-fluid px-7">
                    <div className="row align-items-start">
                        <div className="col-xl-2" >
                            <AdminHeader />
                        </div>
                        <div className="col-xl-10">
                            <div className="col-xl-12 mt-3">
                                <div className="profile-content-area card card-body">
                                    <div className="border-bottom">
                                        <div className="row mb-4">
                                            <div className="col-md-3"></div>
                                            <div className="col-md-1">
                                                <h6 className="text-info mt-3">Job<strong style={{ color: "red" }}>*</strong></h6>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="Job"
                                                    placeholder="Enter Job Here..... "
                                                    value={job}
                                                    onChange={(e) => setJob(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-1">
                                                <button type="button" className="btn btn-sm btn-success mt-1" onClick={handleAddJob}>
                                                    <FaPlus /> Add
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <table className="table table-striped table-bordered" style={{ textAlign: 'center', fontSize: "12px" }}>
                                            <thead style={{ background: "#0066ff", color: "white" }} className="thead-dark">
                                                <tr>
                                                    <th>SR.No</th>
                                                    <th>Job Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((item, index) => (
                                                    <tr key={item.value}>
                                                        <td>{indexOfFirstItem + index + 1}</td>
                                                        <td>
                                                            {editId === item.value ? (
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    style={{ padding: '0.275rem 0.5rem' }}
                                                                    value={editJob}
                                                                    onChange={(e) => setEditJob(e.target.value)}
                                                                />
                                                            ) : (
                                                                item.label
                                                            )}
                                                        </td>
                                                        <td>
                                                            {editId === item.value ? (
                                                                <>
                                                                    <button className="button-success-xs" onClick={() => handleSave(item.value)}>
                                                                        <FaSave /> Save
                                                                    </button>
                                                                    <button className="button-danger-xs" onClick={handleCancel}>
                                                                        <FaTimes /> Cancel
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button id="btnedit" className="button-primary-xs" onClick={() => handleEdit(item.value, item.label)}>
                                                                        <FaEdit /> Edit
                                                                    </button>
                                                                    <button className="button-danger-xs" onClick={() => handleDelete(item.value)}>
                                                                        <RiDeleteBin6Line /> Delete
                                                                    </button>
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <Stack spacing={2} style={{ alignItems: 'center', marginTop: '20px', marginBottom: '10px' }}>
                                            <Pagination
                                                count={Math.ceil(data.length / itemsPerPage)}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                color="primary"
                                            />
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                            <AdminManageJobXref />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
