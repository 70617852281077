import HeaderHero from "../HeaderHero";
import React, { useEffect } from "react";

function RefundPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeaderHero Name={'Refund Policy'} />
            <div id="RefundPolicy" className="section py-6 bg-body">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 refund-policy">
                            <div className="common-heading text-l image-content" >
                                <h4 style={{ color: '#6b40e3' }}>1. Overview</h4>
                                <p>
                                    This Refund Policy applies to all products and services provided by <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> through its website (www.vicpl.in) and governs the refund process for customers.
                                </p>
                                <h4 style={{ color: '#6b40e3' }}>2. Eligibility for Refund</h4>
                                <p>
                                    <b>a.</b> <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> offers refunds under the following circumstances:<br />
                                </p><ul className="a" style={{ marginLeft: '10px' }}>
                                    <li style={{ fontSize: '0.875rem' }}>Defective or non-functional products or services.</li>
                                    <li style={{ fontSize: '0.875rem' }}>Unintentional duplicate purchases.</li>
                                    <li style={{ fontSize: '0.875rem' }}>Failure to deliver the promised product or service within the agreed-upon timeframe.</li>
                                </ul>
                                <br />
                                <b>b.</b> Refunds are not available for:<br />
                                <ul className="a" style={{ marginLeft: '10px' }}>
                                    <li style={{ fontSize: '0.875rem' }}>Change of mind.</li>
                                    <li style={{ fontSize: '0.875rem' }}>Incompatibility of products or services with non-supported environments.</li>
                                    <li style={{ fontSize: '0.875rem' }}>Failure to meet customer expectations that fall within the originally agreed-upon specifications.</li>
                                </ul>
                                <p></p>
                                <h4 style={{ color: '#6b40e3' }}>3. Requesting a Refund</h4>
                                <p><b>a.</b> To request a refund, customers must submit a written request to <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> within 7 days of the date of purchase or delivery, providing detailed reasons for the refund request.</p>
                                <p><b>b.</b> <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> reserves the right to investigate the validity of the refund request and may request additional information from the customer.</p>
                                <h4 style={{ color: '#6b40e3' }}>4. Refund Process</h4>
                                <p><b>a.</b> Upon approval of a refund request, <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> will process the refund within 10 business days through the original payment method used for the purchase.</p>
                                <p><b>b.</b> Refunds may be subject to processing fees or deductions for any services already provided or costs incurred by <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> in processing the refund.</p>
                                <h4 style={{ color: '#6b40e3' }}>5. Cancelled Services</h4>
                                <p><b>a.</b> In the event that <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> cancels a service, customers will be entitled to a full refund of any fees paid for the cancelled service.</p>
                                <p><b>b.</b> <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> reserves the right to cancel services at its discretion, providing customers with a prorated refund for any prepaid, unused services.</p>
                                <h4 style={{ color: '#6b40e3' }}>6. Dispute Resolution</h4>
                                <p>If a customer disputes a charge with their credit card issuer that <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> deems to be a valid charge, <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> may terminate the customer's account and pursue legal remedies to recover the disputed amount.</p>
                                <h4 style={{ color: '#6b40e3' }}>7. Amendments to the Refund Policy</h4>
                                <p><strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> reserves the right to amend or update this Refund Policy at any time. Customers will be notified of any changes, and continued use of the Website and services constitutes acceptance of the revised Refund Policy.</p>
                                <h4 style={{ color: '#6b40e3' }}>8. Contact Information</h4>
                                <p><b>a.</b> For any questions regarding this Refund Policy, customers may contact <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> at "vic@vicpl.in".</p>
                                <p><b>b.</b> By using the products and services offered by <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong>, customers acknowledge and agree to abide by the terms and conditions outlined in this Refund Policy. If customers do not agree with these terms, they should refrain from making purchases on the Website.</p>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default RefundPolicy;