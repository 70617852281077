import img7 from '../assets/img/Services/home-banner-10.svg'
import img72 from '../assets/img/home-banner/home-banner-16.png'
import { NavLink } from 'react-router-dom'
export default function ServicesSection5() {
    return (
        <>
            <section >
                <div className="section effect-section">
                    <div className="effect effect-middle bg-gray-100"></div>
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-5 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s"><br />
                                <div className="col-lg-6 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s"><br />
                                    <img src={img72} title="" alt="" />
                                </div>
                                <br />
                                <div className="h3 mb-4">Recruitment Data Scraping</div>
                                <p className="m-0">Our customized data scraping services can scrap job data from all kinds of Jobs related data sources over the web. We can scrap data such as the latest job listings, open vacancies, candidates looking for a job, candidates profiles, companies profile, skills in demand, and hiring locations from career pages of the company websites.</p>
                                <div className="pt-4"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                            </div>
                            <div className="col-lg-5 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                <div className="col-lg-6 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                    <img src={img7} title="" alt="" />
                                </div>
                                <div className="h3 mb-4">Travel and leisure Data</div>
                                <p className="m-0">Scrap travel data such as hotel reviews, pricing, room availability, airline ticket prices, and more from vast sources available across the web. Analyze travelers’ patterns, likes, dislikes of customers, and many other details, which can be utilized for successful dealings in the travel & hospitality industry.</p>
                                <div className="pt-4"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}