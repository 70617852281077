import HeaderHero from "../HeaderHero";
import { useEffect } from "react";
function TermsAndCondition() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <HeaderHero Name={'Terms and Conditions'} />
            <div id="TermsAndCondition" className="section py-6 bg-body">
                <div className="container">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 Terms-condition">
                            <div className="common-heading text-l image-content">

                                <h4 style={{ color: '#6b40e3' }}>1. Acceptance of Terms</h4>
                                <p>
                                    By accessing or using the <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> website (www.vicpl.in) or any products, services, or content provided by <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong>, you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, please refrain from using the Website and Services.
                                </p>
                                <h4 style={{ color: '#6b40e3' }}>2. Use of Services</h4>
                                <p><b>a.</b> Eligibility: You must be at least 18 years old to use the Services. By utilizing the Services, you affirm that you are of legal age to enter into this agreement.</p>
                                <p><b>b.</b> Lawful Use: You agree to use the Services only for lawful purposes and in compliance with all applicable laws and regulations.</p>
                                <p><b>c.</b> Prohibited Conduct: You shall not engage in any conduct that is harmful, offensive, or otherwise objectionable while using the Services.</p>
                                <h4 style={{ color: '#6b40e3' }}>3. User Accounts</h4>
                                <p><b>a.</b> Account Creation: To access certain features of the Services, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information.</p>
                                <p><b>b.</b> Accuracy of Information: You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
                                <p><b>c.</b> Account Termination: <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> reserves the right to suspend or terminate your account if any information provided is found to be inaccurate, incomplete, or in violation of these Terms.</p>
                                <h4 style={{ color: '#6b40e3' }}>4. Intellectual Property</h4>
                                <p><b>a.</b> Ownership: The Website and Services, including all content, logos, and trademarks, are the exclusive property of <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> and are protected by intellectual property laws.</p>
                                <p><b>b.</b> Restrictions: You may not modify, reproduce, distribute, or create derivative works based on any part of the Website or Services without the express written consent of <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong>.</p>
                                <h4 style={{ color: '#6b40e3' }}>5. Privacy</h4>
                                <p>Privacy Policy: Your use of the Website and Services is subject to <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong>'s Privacy Policy, which is an integral part of these Terms and can be found on the Website.</p>
                                <h4 style={{ color: '#6b40e3' }}>6. Disclaimer of Warranties</h4>
                                <p><b>a.</b> No Warranty: The Website and Services are provided "as is" and without warranties of any kind, either express or implied.</p>
                                <p><b>b.</b> No Guarantee: <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> does not warrant that the Website or Services will be uninterrupted, error-free, or free of viruses or other harmful components.</p>
                                <h4 style={{ color: '#6b40e3' }}>7. Limitation of Liability</h4>
                                <p>Exclusion of Damages: <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website or Services.</p>
                                <h4 style={{ color: '#6b40e3' }}>8. Changes to Terms</h4>
                                <p><strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> reserves the right to update or modify these Terms at any time without prior notice. Your continued use of the Website or Services after any changes constitute acceptance of the revised Terms.</p>
                                <h4 style={{ color: '#6b40e3' }}>9. Governing Law and Jurisdiction</h4>
                                <p><b>a.</b> These Terms are governed by and construed in accordance with the laws of Jurisdiction, Session Court (Patiala, Punjab). Any dispute arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the Session Court (Patiala, Punjab).</p>
                                <p><b>b.</b> By accessing and using the <strong>VIABLE INFORMATICS CLUES PVT. LTD.</strong> Website and Services, you acknowledge that you have read, understood, and agreed to these Terms. If you do not agree to these Terms, please refrain from using the Website and Services.</p>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default TermsAndCondition;