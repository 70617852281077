import React, { useState, useEffect } from 'react';
import AdminHeader from "../Component/AdminHeader";
import HeaderHeroProfile from '../HeaderHeroProfile';
import { ImBin } from "react-icons/im";
import moment from "moment/moment";
import Swal from 'sweetalert2';

export default function AdminManageOrderReceived() {
    const [Orders, setOrders] = useState([]);
    const [error, setError] = useState(null);
    useEffect(() => {
        GetAllOrders();
    }, []);
    const GetAllOrders = () => {
        fetch('https://api.domehelp.com/ProductInformation/GetAllOrders', {
            method: "POST",
        }).then((response) => {
            if (!response.ok) {
                setOrders([]);
                return false;
            }
            else {
                return response.json();
            }
        }).then(data => {
            if (data.status === 1) {
                setOrders(data.data);
            } else {
                setOrders([]);
                setError(data.message);
            }
        }).catch(err => setError('Error fetching data: ' + err));

    };
    const DeleteProduct = (Orderid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to remove this order?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(` https://api.domehelp.com/ProductInformation/DeleteOrders?OrderId=${Orderid}`, {
                    method: "POST"
                }).then(() => {
                    GetAllOrders();
                    Swal.fire(
                        'Deleted!',
                        'The product has been deleted.',
                        'success'
                    );
                }).catch(err => {
                    setError('Error fetching data: ' + err);
                    Swal.fire(
                        'Error!',
                        'There was a problem deleting the product.',
                        'error'
                    );
                });
            }
        });
    };
    const VerifiedOrder = (orderId, currentStatus, userid) => {
        if (currentStatus === 0) {
            Swal.fire('Info', 'Order status is already verified.', 'info');
            return;
        }

        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to update the order status?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`https://api.domehelp.com/ProductInformation/VerifiedPaymentReceived?OrderId=${orderId}&Userid=${userid}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json' // Add this if your server expects JSON
                    }
                })
                    .then((response) => {
                        // Check if the response is OK
                        if (!response.ok) {
                            throw new Error("Error updating order status. Status: " + response.status);
                        }
                        return response.text(); // Get the response as text
                    })
                    .then((data) => {
                        try {
                            const result = JSON.parse(data); // Attempt to parse as JSON
                            if (result.status === 1) {
                                GetAllOrders();
                                Swal.fire("Success", result.message || "Order status updated successfully", "success");
                            } else {
                                Swal.fire("Warning!", result.message || "An error occurred while updating the status.", "error");
                            }
                        } catch (error) {
                            // If JSON parsing fails, show a warning
                            Swal.fire("Warning!", "Received invalid response from server: " + data, "warning");
                        }
                    })
                    .catch((error) => {
                        Swal.fire("Warning!", error.toString(), "warning");
                    });
            } else {
                Swal.fire('Cancelled', 'Order status was not updated', 'info');
            }
        });
    };
    return (
        <>
            <HeaderHeroProfile />
            <section className="profile-container bg-gray-100">
                <div className="container-fluid px-7">
                    <div className="row align-items-start">
                        <div className='col-xl-2'>
                            <AdminHeader />
                        </div>
                        <div className="col-xl-10">
                            <div className="profile-content-area my-6 card card-body">
                                <div className="border-bottom mb-6 pb-6">
                                    <h5 className="text-center text-primary"><b>Manage Pending Order</b></h5>
                                    <div style={{ maxHeight: '450px', overflow: 'auto' }}>
                                        <table className="table table-striped table-bordered text-center" style={{ fontSize: 'small' }}>
                                            <thead style={{ background: "rgb(0, 102, 255)", color: "white" }} className="thead-dark">
                                                <tr>
                                                    <th>OrderID</th>
                                                    <th>Product Name</th>
                                                    <th>Quantity</th>
                                                    <th>Amount</th>
                                                    <th>Transactionid</th>
                                                    <th>PayementDate</th>
                                                    <th>PayementMethod</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Orders.length > 0 ? (
                                                    Orders.map((Order, index) => (
                                                        <tr key={Order.orderId}>
                                                            <td>{Order.orderId}</td>
                                                            <td>{Order.productName}</td>
                                                            <td>{Order.oQuantity}</td>
                                                            <td>{Order.amount}</td>
                                                            <td>{Order.transactionId}</td>
                                                            <td>{moment(Order.createdOn).format("DD/MM/YYYY")}</td>
                                                            <td>{Order.paymentMethod}</td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <div className="checkbox-wrapper-7">
                                                                    <input className="tgl tgl-ios"
                                                                        id={`Verified${index + 1}`}
                                                                        type="checkbox"
                                                                        checked={Order.status == 0}
                                                                        onChange={() => VerifiedOrder(Order.orderId, Order.status, Order.userId)} />
                                                                    <label className="tgl-btn" htmlFor={`Verified${index + 1}`}></label>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <button className="btn btn-danger" style={{ padding: '0px 5px' }} onClick={() => { DeleteProduct(Order.orderId) }}>
                                                                    <ImBin />
                                                                </button>

                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="9">No products available.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

