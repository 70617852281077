import { useEffect } from 'react';
import HeaderHero from '../HeaderHero';
import B2BDatabaseSection1 from './B2BDatabaseSection1';
import B2BDatabaseSection2 from './B2BDatabaseSection2';
import B2BDatabaseSection3 from './B2BDatabaseSection3';
import B2BDatabaseSection4 from './B2BDatabaseSection4';
import B2BDatabaseSection5 from './B2BDatabaseSection5';

function B2BDatabase() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <HeaderHero Name={'B2B Database'} />
            <B2BDatabaseSection1 />
            <B2BDatabaseSection2 />
            <B2BDatabaseSection3 />
            <B2BDatabaseSection4 />
            <B2BDatabaseSection5 />
        </>
    );
}
export default B2BDatabase;