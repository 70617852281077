import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function UserSettingSection1() {
    const [Firstname, setFirstName] = useState('');
    const [Lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');
    const [mobile, setMobile] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [FirstnameError, setFirstNameError] = useState('');
    const [LastnameError, setLastnameError] = useState('');
    const [cityError, setCityError] = useState('');
    const [stateError, setStateError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [cpasswordError, setCpasswordError] = useState('');
    const [data, setData] = useState({});
    const [oldPassword, setOldPassword] = useState('');

    const id = window.localStorage.getItem("id");
    const token = window.localStorage.getItem("access");

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            fetchUser();
        } else {
            navigate(`/Login`);
        }
    }, [id, navigate]);

    const fetchUser = async () => {
        try {
            const response = await fetch(`https://api.domehelp.com/User/GetUserById?id=${id}`, {
                method: "POST",
                headers: { Authorization: `${token}` },
            });
            const result = await response.json();
            if (result.data) {
                setData(result.data);
                setFirstName(result.data.firstName || '');
                setLastname(result.data.lastName || '');
                setEmail(result.data.email || '');
                setMobile(result.data.mobile || '');
                setCity(result.data.city || '');
                setState(result.data.state || '');
                setOldPassword(result.data.password || ''); // Assuming the API sends the hashed password
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const handleInputChange = (setter, setError) => (e) => {
        setter(e.target.value);
        setError('');
    };

    const handleSaveChanges = async () => {
        let isValid = true;

        // Validation logic
        if (Firstname.trim() === '') {
            setFirstNameError('Enter First Name!');
            isValid = false;
        }
        if (Lastname.trim() === '') {
            setLastnameError('Enter Last Name!');
            isValid = false;
        }
        if (city.trim() === '') {
            setCityError('Enter City!');
            isValid = false;
        }
        if (state.trim() === '') {
            setStateError('Enter State!');
            isValid = false;
        }
        if (password !== '' || cpassword !== '') {
            if (password !== cpassword) {
                setCpasswordError('Password and Confirm Password Not Matched!');
                isValid = false;
            } else if (password.length < 8 || cpassword.length < 8) {
                setPasswordError('Enter minimum 8 characters!');
                setCpasswordError('Enter minimum 8 characters!');
                isValid = false;
            }
        }

        if (isValid) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Update it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    updateUser();
                }
            });
        }
    };

    const updateUser = async () => {
        const userId = parseInt(id);
        const fd = new FormData();
        fd.append('id', userId);
        fd.append('userFirstName', Firstname);
        fd.append('userLastName', Lastname);
        fd.append('userMobile', mobile);
        fd.append('userEmail', data.email);
        fd.append('userPassword', password !== '' ? password : oldPassword);
        fd.append('userCity', city);
        fd.append('userState', state);

        try {
            const response = await fetch(`https://api.domehelp.com/User/UpdateUser`, {
                method: 'POST',
                headers: { Authorization: `${token}` },
                body: fd,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            if (result.status === 1) {
                Swal.fire("Success", result.message, "success");
                setFirstName('');
                setLastname('');
                setCity('');
                setState('');
                navigate(`/Login`);
            } else {
                Swal.fire("Failed", result.message, "error");
            }
        } catch (error) {
            console.error("Error updating user data:", error);
        }
    };

    return (

        <div className="container-fluid">
            <h5>Update User information</h5>
            <div className="row mb-4">
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label className="form-label">First name</label>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First name"
                            className="form-control"
                            value={Firstname}
                            onChange={handleInputChange(setFirstName, setFirstNameError)}
                        />
                        {FirstnameError && <div className="text-danger">{FirstnameError}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label className="form-label">Last name</label>
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last name"
                            className="form-control"
                            value={Lastname}
                            onChange={handleInputChange(setLastname, setLastnameError)}
                        />
                        {LastnameError && <div className="text-danger">{LastnameError}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label className="form-label">City</label>
                        <input
                            type="text"
                            name="city"
                            placeholder="City"
                            className="form-control"
                            value={city}
                            onChange={handleInputChange(setCity, setCityError)}
                        />
                        {cityError && <div className="text-danger">{cityError}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label className="form-label">State</label>
                        <input
                            type="text"
                            name="state"
                            placeholder="State"
                            className="form-control"
                            value={state}
                            onChange={handleInputChange(setState, setStateError)}
                        />
                        {stateError && <div className="text-danger">{stateError}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label className="form-label">Password</label>
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            className="form-control"
                            value={password}
                            onChange={handleInputChange(setPassword, setPasswordError)}
                        />
                        {passwordError && <div className="text-danger">{passwordError}</div>}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label className="form-label">Confirm Password</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            className="form-control"
                            value={cpassword}
                            onChange={handleInputChange(setCpassword, setCpasswordError)}
                        />
                        {cpasswordError && <div className="text-danger">{cpasswordError}</div>}
                    </div>
                </div>
            </div>
            <div className="col-md-12 text-end">
                <button className="btn btn-sm btn-primary m-2" onClick={handleSaveChanges}>Save changes</button>
                <button className="btn btn-sm btn-secondary m-2" onClick={() => navigate(-1)}>Cancel</button>
            </div>
        </div>

    );
}
