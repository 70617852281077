import { useEffect } from 'react';
import ServicesSection2 from './ServicesSection2';
import ServicesSection3 from './ServicesSection3';
import ServicesSection4 from './ServicesSection4';
import ServicesSection5 from './ServicesSection5';
import ServicesSection6 from './ServicesSection6';
import ServicesSection7 from './ServicesSection7';
import ServicesSection8 from './ServicesSection8';
import ServicesSection9 from './ServicesSection9';
import ServicesSection10 from './ServicesSection10';
import HeaderHero from '../HeaderHero';


export default function Services() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <main>
                <HeaderHero Name={'Services'} />
                <ServicesSection2 />
                <ServicesSection3 />
                <ServicesSection4 />
                <ServicesSection5 />
                <ServicesSection6 />
                <ServicesSection7 />
                <ServicesSection8 />
                <ServicesSection9 />
                <ServicesSection10 />
            </main>
        </>
    )
}