import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPalButton = ({ amount, onSuccess }) => {
    return (
        <PayPalScriptProvider options={{ "client-id": "AWEEVYHKzk7jUb1QlEiy0P9GB13PJ5L5tXDaVARps1gdg9-WqEb34RgQuSEghtilwSI5F5NbTFsT1w2y" }}>
            <PayPalButtons
                style={{ layout: 'vertical' }}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: amount,
                            },
                        }],
                    });
                }}
                onApprove={async (data, actions) => {
                    const details = await actions.order.capture();
                    onSuccess(details);
                }}
            />
        </PayPalScriptProvider>
    );
};
export default PayPalButton;