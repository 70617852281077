import React, { useState, useEffect } from 'react';
import AdminHeader from "../Component/AdminHeader";
import HeaderHeroProfile from '../HeaderHeroProfile';
import { ImBin } from "react-icons/im";
import moment from "moment/moment";
import Swal from 'sweetalert2';

export default function AdminManageOrderCompleted() {
    const [Orders, setOrders] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        GetAllReceivedOrders();
    }, []);

    const GetAllReceivedOrders = (() => {
        fetch('https://api.domehelp.com/ProductInformation/GetAllReceivedOrders', {
            method: "POST",
        })
            .then(response => response.json())
            .then(data => {
                if (data.status === 1) {
                    setOrders(data.data);
                } else {
                    setError(data.message);
                    setOrders([]);
                }
            })
            .catch(err => setError('Error fetching data: ' + err));

    });

    const DeleteProduct = (Orderid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to remove this Order?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(` https://api.domehelp.com/ProductInformation/DeleteOrders?OrderId=${Orderid}`, {
                    method: "POST"
                }).then(() => {
                    GetAllReceivedOrders();
                    Swal.fire(
                        'Deleted!',
                        'The product has been deleted.',
                        'success'
                    );
                }).catch(err => {
                    setError('Error fetching data: ' + err);
                    Swal.fire(
                        'Error!',
                        'There was a problem deleting the product.',
                        'error'
                    );
                });
            }
        });
    };

    return (
        <>
            <HeaderHeroProfile />
            <section className="profile-container bg-gray-100">
                <div className="container-fluid px-7">
                    <div className="row align-items-start">
                        <div className='col-xl-2'>
                            <AdminHeader />
                        </div>
                        <div className="col-xl-10">
                            <div className="profile-content-area my-6 card card-body">
                                <div className="border-bottom mb-6 pb-6">
                                    <h5 className="text-center text-primary"><b>Manage Received Order</b></h5>
                                    <div style={{ maxHeight: '450px', overflow: 'auto' }}>
                                        <table className="table table-striped table-bordered text-center" style={{ fontSize: 'small' }}>
                                            <thead style={{ background: "rgb(0, 102, 255)", color: "white" }} className="thead-dark">
                                                <tr>
                                                    <th>OrderID</th>
                                                    <th>Product Name</th>
                                                    <th>Quantity</th>
                                                    <th>Amount</th>
                                                    <th>Transactionid</th>
                                                    <th>PayementDate</th>
                                                    <th>PayementMethod</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Orders.length > 0 ? (
                                                    Orders.map((Order, index) => (
                                                        <tr key={Order.orderId}>
                                                            <td>{Order.orderId}</td>
                                                            <td>{Order.productName}</td>
                                                            <td>{Order.oQuantity}</td>
                                                            <td>{Order.amount}</td>
                                                            <td>{Order.transactionId}</td>
                                                            <td>{moment(Order.createdOn).format("DD/MM/YYYY")}</td>
                                                            <td>{Order.paymentMethod}</td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <div className="checkbox-wrapper-7">
                                                                    <input
                                                                        className="tgl tgl-ios"
                                                                        id={`Verified${index + 1}`}
                                                                        type="checkbox"
                                                                        checked={true}
                                                                        readOnly
                                                                    />
                                                                    <label className="tgl-btn" htmlFor={`Verified${index + 1}`}></label>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <button className="btn btn-danger" style={{ padding: '0px 5px' }} onClick={() => { DeleteProduct(Order.orderId) }}>
                                                                    <ImBin/>
                                                                </button>

                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="9">No products available.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

