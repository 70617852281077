import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminHeader from "../Component/AdminHeader";
import Swal from 'sweetalert2';
import HeaderHeroProfile from '../HeaderHeroProfile';
export default function AdminSetting() {
    const [Firstname, setFirstName] = useState('');
    const [Lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [FirstnameError, setFirstNameError] = useState('');
    const [LastnameError, setLastnameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [cpasswordError, setCpasswordError] = useState('');
    const [cityError, setCityError] = useState('');
    const [stateError, setStateError] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [data, setData] = useState(null);

    const navigate = useNavigate();
    const id = window.localStorage.getItem("id")
    const token = window.localStorage.getItem("access")
    const role = `${window.localStorage.getItem('role')}`;

    useEffect(() => {
        if ((token == '' || null) || role == 2) {
            navigate(`/Login`)
            return
        }
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            fetchUser();
        } else {
            navigate(`/Login`);
        }
    }, [id, navigate]);

    const fetchUser = async () => {
        try {
            const response = await fetch(`https://api.domehelp.com/User/GetUserById?id=${id}`, {
                method: "POST",
                headers: { Authorization: `${window.localStorage.getItem("access")}` },
            });
            const result = await response.json();
            setData(result.data);
            if (result.data) {
                setFirstName(result.data.firstName||"");
                setLastname(result.data.lastName||"");
                setEmail(result.data.userEmail||"");
                setMobile(result.data.userMobile||"");
                setCity(result.data.userCity||"");
                setState(result.data.userState||"");
                setOldPassword(result.data.userPassword|"");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const handleInputChange = (setter, setError) => (e) => {
        setter(e.target.value);
        setError('');
    };

    const handleSaveChanges = async () => {
        let isValid = true;
        if (Firstname.trim() === '') {
            setFirstNameError('Enter First Name!');
            isValid = false;
        }
        if (Lastname.trim() === '') {
            setLastnameError('Enter Last Name!');
            isValid = false;
        }
        if (email.trim() === '') {
            setEmailError('Enter Email!');
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError('Enter a valid email address!');
            isValid = false;
        }
        if (mobile.trim() === '' || mobile.length !== 10) {
            setMobileError('Enter 10 Digit Mobile Number!');
            isValid = false;
        }
        if (password !== '' || cpassword !== '') {
            if (password !== cpassword) {
                setCpasswordError('Password and Confirm Password Not Matched!');
                isValid = false;
            } else if (password.length < 8 || cpassword.length < 8) {
                setPasswordError('Enter minimum 8 characters!');
                setCpasswordError('Enter minimum 8 characters!');
                isValid = false;
            }
        }
        if (city.trim() === '') {
            setCityError('Enter City!');
            isValid = false;
        }
        if (state.trim() === '') {
            setStateError('Enter State!');
            isValid = false;
        }

        if (isValid) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Update it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    updateAdmin()

                }
            });

        }
        function updateAdmin() {
            const userId = parseInt(id)
            const fd = new FormData();
            fd.append('id', userId)
            fd.append('password', data.userPassword)
            fd.append('userFirstName', Firstname)
            fd.append('userLastName', Lastname)
            fd.append('userMobile', mobile)
            fd.append('userEmail', email)
            fd.append('userPassword', password !== '' ? password : oldPassword)
            fd.append('userCity', city)
            fd.append('userState', state)
            fd.append('userIsActive', 1)
            fetch(`https://api.domehelp.com/User/UpdateAdminProfile`, {
                method: 'POST',
                headers: { Authorization: `${window.localStorage.getItem("access")}` },
                body: fd,
            }).then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
                .then((result) => {
                    if (result.status === 1) {
                        Swal.fire("Success", result.message, "success");
                        setFirstName('');
                        setLastname('');
                        setEmail('');
                        setMobile('');
                        setPassword('');
                        setCpassword('');
                        setCity('');
                        setState('');
                        fetchUser();
                    }
                    else {
                        Swal.fire("Faild", result.message, "error")
                    }
                })
        }
    };

    return (
        <>
            <HeaderHeroProfile />
            <section className="profile-container bg-gray-100">
                <div className="container-fluid px-7">
                    <div className="row align-items-start">
                        <div className='col-xl-2'>
                            <AdminHeader />
                        </div>
                        <div className="col-xl-10">
                            <div className="profile-content-area my-6 card card-body">

                                <div className="border-bottom mb-6 pb-6">
                                    <h5>Update Admin information</h5>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label">First name</label>
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    placeholder="First name"
                                                    className="form-control"
                                                    value={Firstname}
                                                    onChange={handleInputChange(setFirstName, setFirstNameError)}
                                                />
                                                {FirstnameError && <div className="text-danger">{FirstnameError}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label">Last name</label>
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    placeholder="Last name"
                                                    className="form-control"
                                                    value={Lastname}
                                                    onChange={handleInputChange(setLastname, setLastnameError)}
                                                />
                                                {LastnameError && <div className="text-danger">{LastnameError}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label">City</label>
                                                <input
                                                    type="text"
                                                    name="city"
                                                    placeholder="City"
                                                    className="form-control"
                                                    value={city}
                                                    onChange={handleInputChange(setCity, setCityError)}
                                                />
                                                {cityError && <div className="text-danger">{cityError}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label">State</label>
                                                <input
                                                    type="text"
                                                    name="state"
                                                    placeholder="State"
                                                    className="form-control"
                                                    value={state}
                                                    onChange={handleInputChange(setState, setStateError)}
                                                />
                                                {stateError && <div className="text-danger">{stateError}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label">Email</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    className="form-control"
                                                    value={email}
                                                    onChange={handleInputChange(setEmail, setEmailError)}
                                                />
                                                {emailError && <div className="text-danger">{emailError}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label">Mobile</label>
                                                <input
                                                    type="text"
                                                    name="mobile"
                                                    placeholder="Mobile"
                                                    className="form-control"
                                                    value={mobile}
                                                    onChange={handleInputChange(setMobile, setMobileError)}
                                                />
                                                {mobileError && <div className="text-danger">{mobileError}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label">Password</label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    className="form-control"
                                                    value={password}
                                                    onChange={handleInputChange(setPassword, setPasswordError)}
                                                />
                                                {passwordError && <div className="text-danger">{passwordError}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label">Confirm Password</label>
                                                <input
                                                    type="password"
                                                    name="confirmPassword"
                                                    placeholder="Confirm Password"
                                                    className="form-control"
                                                    value={cpassword}
                                                    onChange={handleInputChange(setCpassword, setCpasswordError)}
                                                />
                                                {cpasswordError && <div className="text-danger">{cpasswordError}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-end">
                                    <button className="btn btn-sm btn-primary m-2" onClick={handleSaveChanges}>Save changes</button>
                                    <button className="btn btn-sm btn-secondary m-2" onClick={() => navigate(-1)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
