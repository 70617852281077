import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
export default function ProductsList() {
    const [data, setData] = useState([]);
    useEffect(() => {      
        GetProductList();
    }, []);

    function GetProductList() {
        Swal.fire({
            title: "Loading...",
            html: `
                <div class="custom-loading-container">
                    <div class="custom-spinner"></div>
                    <p>Please wait...</p>
                </div>
            `,
            showConfirmButton: false,
            background: "rgba(0, 0, 0, 0.8)",
            customClass: {
              popup: 'custom-popup',
              title: 'custom-title',
              htmlContainer: 'custom-html-container'
            }
          });
        fetch(`https://api.domehelp.com/ProductInformation/GetAllProducts`, {
            method: "POST",
        })
            .then((response) => {
                if (!response.ok) {
                    Swal.fire("Warning!", "getting Error.", "warning");
                    return false;
                }
                return response.json();
            })
            .then((result) => {
                if (result.status === 1) {
                    setData(result.data);
                    Swal.close();
                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                Swal.fire("Warning!", error.toString(), "warning");
            });
    }

    return (
        <>
            <div className="container">
                <div className="row mt-4 mb-4">
                    {data.map((item, index) => (
                        <div className="col-4" key={index + 1}>
                            <div className="card" style={{ width: "100%", borderRadius: 0, border: "none", boxShadow: "none" }}>
                                <Link to={`/ProductDeatils/${item.productId}`}><img src={`https://api.domehelp.com/ProductDoc/${item.pBannerImage}`}  className="card-img-top" alt="..." style={{ borderRadius: 0, position: "relative"}} /></Link>
                                <div className="discount-icons">{item.discount}%</div>
                                <div className="card-body" style={{ padding: 10 }}>
                                    <Link to={`/ProductDeatils/${item.productId}`} style={{ fontSize: 16 }}>{item.productName}</Link>
                                    <h6 style={{ fontSize: 18 }}><span><del aria-hidden="true">₹{item.pPreviousAmount}</del> <span>₹{item.pCurrentAmount}</span></span></h6>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

