import { Routes, Route, useLocation } from 'react-router-dom';
import React from 'react';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Home from './Home/Home';
import Services from './Services/Services';
import Contact from './Contact/Contact';
import B2BDatabase from './B2BDatabase/B2BDatabase';
import Login from './Login/Login';
import SignUp from './SignUp/SignUp';
import ForgetPassword from './ForgetPassword/ForgetPassword';
import ChangePassword from './ChangePassword/ChangePassword';
import PrivacyPolicy from './Policy/PrivacyPolicy';
import RefundPolicy from './Policy/RefundPolicy';
import TermsAndCondition from './Policy/TermsAndCondition';
import MyAos from './MyAos';
import 'aos/dist/aos.css';
import Page404 from './Component/Page404';
import AdminHome from './Admin/AdminHome';
import AdminDash from './Admin/AdminDash';
import AdminHomeSection2 from './Admin/AdminHomeSection2';
import AdminProfile from './Admin/AdminProfile';
import UserProfile from './User/UserProfile';
import AdminSetting from './Admin/AdminSetting';
import UserSetting from './User/UserSetting';
import UserBilling from './User/UserBilling';
import Pricing from './Pricing/Pricing';
import CommingSoon from './Component/CommingSoon';
import AdminManageStateCity from './Admin/AdminManageStateCity';
import AdminManageJob from './Admin/AdminManageJob';
import B2BDataStateWise from './B2BDatabase/B2BDataStateWise';
import B2BDataJobWise from './B2BDatabase/B2BDataJobWise';
import B2BDataJobWiseSection2 from './B2BDatabase/B2BDataJobWiseSection2';
import UserDashboard from './User/UserDashboard';
import Numerology from'./Numerology/Numerology';
import AdminNumerologyUsersList from './Admin/AdminNumerologyUsersList';
import Product from './Products/Product';
import ProductDeatils from './Products/ProductDeatils';
import ProductBuy from './Products/ProductBuy';
import AdminAddNewProduct from './Admin/AdminAddNewProduct';
import OrderProductDone from './Products/OrderProductDone';
import AdminManageOrderReceived from './Admin/AdminManageOrderReceived';
import AdminManageOrderCompleted from './Admin/AdminManageOrderCompleted';
const RoutingComponent = () => {
  const location = useLocation();
  const adminPaths = ['/AdminHome', '/AdminHomeSection2', '/AdminDash', '/AdminProfile', '/AdminSetting', '/AdminManageStateCity', '/AdminManageJob','/AdminNumerologyUsersList','/AdminAddNewProduct','/AdminManageOrderReceived','/AdminManageOrderCompleted'];
  const userPaths = ['/UserProfile', '/UserSetting', '/UserDashboard', '/UserBilling'];
  const showHeaderFooter = ![...adminPaths, ...userPaths].some(path => location.pathname.startsWith(path));

  return (
    <>
      {showHeaderFooter && <Header />}
      <MyAos />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/B2BDatabase' element={<B2BDatabase />} />
        <Route path='/B2BDataStateWise/:id' element={<B2BDataStateWise />} />
        <Route path='/B2BDataJobWise/:sid/:cid' element={<B2BDataJobWise />} />
        <Route path='/B2BDataJobWiseSection2/:jid' element={<B2BDataJobWiseSection2 />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Login' element={<Login />} />
        <Route path='/ChangePassword' element={<ChangePassword />} />
        <Route path='/SignUp' element={<SignUp />} />
        <Route path='/ForgetPassword' element={<ForgetPassword />} />
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/RefundPolicy' element={<RefundPolicy />} />
        <Route path='/TermsAndCondition' element={<TermsAndCondition />} />
        <Route path='/CommingSoon' element={<CommingSoon />} />
        <Route path='/Pricing' element={<Pricing />} />
        <Route path="/Products" element={<Product />} />
        <Route path="/ProductDeatils/:productId" element={<ProductDeatils />} />
        <Route path="ProductBuy/:productId" element={<ProductBuy/>}/>
        <Route path="/ProductOrderDone" element={<OrderProductDone/>}/>
        <Route path='/AdminHome' element={<AdminHome />} />
        <Route path='/AdminHomeSection2' element={<AdminHomeSection2 />} />
        <Route path='/AdminDash' element={<AdminDash />} />
        <Route path='/AdminProfile' element={<AdminProfile />} />
        <Route path='/AdminSetting' element={<AdminSetting />} />
        <Route path='/AdminManageStateCity' element={<AdminManageStateCity />} />
        <Route path='/AdminManagejob' element={<AdminManageJob />} />
        <Route path='/AdminAddNewProduct' element={<AdminAddNewProduct/>}/>
        <Route path='/AdminManageOrderPending' element={<AdminManageOrderReceived/>}/>
        <Route path='/AdminManageOrderCompleted' element={<AdminManageOrderCompleted/>}/>
        <Route path='/UserProfile' element={<UserProfile />} />
        <Route path='/UserSetting' element={<UserSetting />} />
        <Route path='/UserBilling' element={<UserBilling />} />
        <Route path='/UserDashboard' element={<UserDashboard />} />
        <Route path='/Numerology' element={<Numerology />} />
        <Route path='/AdminNumerologyUsersList' element={<AdminNumerologyUsersList />} />
        <Route path='/*' element={<Page404 />} />
      </Routes>
      {showHeaderFooter && <Footer />}
    </>
  );
};

export default RoutingComponent;