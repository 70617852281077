import { BrowserRouter, Routes, Route, HashRouter, MemoryRouter } from 'react-router-dom';
import React from 'react';
import './assets/css/theme.css'
import 'aos/dist/aos.css';
import RoutingComponent from './RoutingComponent';

function App() {
  return (
    <>
      {/*If we don't want to show our page in url then we can use MemoryRouter at the place of HashRouter but we never access the page using url changes*/}
      <HashRouter>
        <RoutingComponent />
      </HashRouter>
    </>
  );
}

export default App;
