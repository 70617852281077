import img71 from '../assets/img/home-banner/home-banner.png'
import img72 from '../assets/img/home-banner/home-banner-1.png'
import { NavLink } from 'react-router-dom'
export default function ServicesSection7() {
    return (
        <>
            <section >
                <div className="section effect-section">
                    <div className="effect effect-middle bg-gray-100"></div>
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-5 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                <div className="col-lg-6 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                    <img src={img71} title="" alt="" />
                                </div>
                                <div className="h3 mb-4">Retail Store Data</div>
                                <p className="m-0">Push your growth curve higher by getting accurate, verified, updated, affordable, and ready to use location data in an instant. Gain visibility over the retail market with location intelligence and scrap data such as retail store openings, store closures, parking convenience, in-store pickup option, services, subsidiaries, nearest competitor’s store, and much more.</p>
                                <div className="pt-4"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                            </div>
                            <div className="col-lg-5 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                <div className="col-lg-6 my-3 wow fadeInLeft" data-aos="fade-left" data-aos-delay="0.1s">
                                    <img src={img72} title="" alt="" />
                                </div>
                                <div className="h3 mb-4">Brand monitoring data</div>
                                <p className="m-0">Build your brand with the good graces of your customer through brand monitoring data. Know how people perceive your brand and the impact of your services on your customers and much more. Get every precise visibility of your brand and stay ahead of your competitors by monitoring your brand philosophy.</p>
                                <div className="pt-4"><NavLink className="btn btn-outline-primary" to={'/Contact'}>More About</NavLink></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}