// json file is not uploaded on server so for testing we use localhost
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { MdMarkEmailUnread } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';
import { LuFileX } from "react-icons/lu";
import { Modal, Button, Form, Image } from "react-bootstrap";
import Logoimage from '../assets/img/CompanyLogo-removebg-preview.png';
import { SiMicrosoftexcel } from "react-icons/si";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { Scrollbar } from 'react-scrollbars-custom';
import Loader from '../Component/Loader';

function B2BDatabaseSection1() {
    const [job, setJob] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [topData, setTopData] = useState([]);
    const [message, setMessage] = useState("");
    const [selectedSamplelead, setSelectedSamplelead] = useState(null);
    const [jobName, setJobName] = useState("");
    const [jobXrefId, setJobXrefId] = useState(null);
    const [jobId, setJobId] = useState(null)
    const [UserId, setUserId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [stateId, setStateId] = useState(null);
    const [showSampleModal, setShowSampleModal] = useState(false);
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("")
    const [Email, setEmail] = useState("");
    const [Mobile, setMobile] = useState("");

    const noDataRef = useRef(null);

    useEffect(() => {
        FetchJob();
        FetchState();
        FetchTopData();
    }, [UserId]);

    useEffect(() => {
        if (selectedState) {
            FetchCity(selectedState.value);
        } else {
            setCities([]);
        }
    }, [selectedState]);

    useEffect(() => {
        if (!selectedState && !selectedCities && !selectedJob) {
            FetchTopData()
            return
        }
        if (selectedState && selectedCities && selectedJob) {
            FetchFilteredData();
        }
    }, [selectedState, selectedCities, selectedJob]);

    function FetchJob() {
        fetch(`https://api.domehelp.com/Master/GetAllJobs`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((resp) => {
            if (!resp.ok) {
                console.error("Error fetching jobs");
                return;
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                const Joboptions = result.data.map((Job) => ({
                    label: Job.jobName,
                    value: Job.jobId,
                }));
                setJob(Joboptions);
            } else {
                console.error(result.Message);
            }
        }).catch((error) => {
            console.error("Fetch error: ", error);
        });
    }

    function FetchState() {
        fetch(`https://api.domehelp.com/Master/GetAllState`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((resp) => {
            if (!resp.ok) {
                console.error("Error fetching states");
                return;
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                const stateOptions = result.data.map((state) => ({
                    label: state.stateName,
                    value: state.stateID,
                }));
                setStates(stateOptions);
            } else {
                console.error(result.message);
            }
        }).catch((error) => {
            console.error("Fetch error: ", error);
        });
    }

    function FetchCity(stateId) {
        fetch(`https://api.domehelp.com/Master/GetAllCityByStateId?id=${stateId}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((resp) => {
            if (!resp.ok) {
                console.log("response Error");
                return;
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                const cityOptions = result.data.map((city) => ({
                    label: city.cityName,
                    value: city.cityID,
                }));
                setCities(cityOptions);
            } else {
                console.error(result.Message);
            }
        }).catch((error) => {
            console.error("Fetch error: ", error);
        });
    }

    function FetchFilteredData() {
        let jbId = selectedJob?.value;
        let ctID = selectedCities?.value;
        let stid = selectedState?.value;
        fetch(`https://api.domehelp.com/Master/GetJobStateAndCityAndJobWise?State=${stid}&City=${ctID}&job=${jbId}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error in response");
                return;
            }
            return resp.json();
        }).then((result) => {
            if (!result) {
                return;
            }
            if (result.status === 1) {
                setFilteredData(result.data);
            } else if (result.status === 0) {
                if (noDataRef.current) {
                    noDataRef.current.style.display = "block";
                }
                setMessage(result.message);
                setFilteredData([]);
                setTopData([]);
            }
        }).catch((error) => {
            console.error("Fetch error: ", error);
        });
    }

    function FetchTopData() {
        fetch(`https://api.domehelp.com/Master/GetTopJobxRef`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((resp) => {
            if (!resp.ok) {
                console.log("response not ok");
                return;
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                setMessage("")
                setTopData(result.data);
                if (noDataRef.current) {
                    noDataRef.current.style.display = "none";
                }
            } else {
                console.log(result.message);
            }
        }).catch((error) => {
            console.error("Fetch error: ", error);
        });
    }

    function handleClear() {
        setSelectedCities(null)
        setSelectedJob(null)
        setSelectedState(null)
        setTopData(topData)
        setFilteredData(topData)
    }

    function handleSampleleadButton(id, name, cityID, stateID, jobId) {
        setJobName(name)
        setJobXrefId(id)
        setCityId(cityID)
        setStateId(stateID)
        setJobId(jobId)
        setShowSampleModal(true)
    }
    function handleDownLoadSample() {
        if (FirstName.trim() === '') {
            Swal.fire("Warning !", "Enter First Name", "warning")
            return
        }
        if (LastName.trim() === '') {
            Swal.fire("Warning !", "Enter Last Name", "warning")
            return
        }
        if (Email.trim() === '') {
            Swal.fire("Warning !", "Enter Email", "warning")
            return
        } else if (!/\S+@\S+\.\S+/.test(Email)) {
            Swal.fire("Warning !", "Please Enter Correct Email", "warning")
            return
        }
        if (Mobile.trim() === '' || Mobile.length !== 10) {
            Swal.fire("Warning !", "Enter 10 Digit Mobile Number", "warning")
            return
        }
        let fd = new FormData();
        fd.append("FirstName", FirstName);
        fd.append("LastName", LastName);
        fd.append("email", Email);
        fd.append("Mobile", Mobile)
        fetch(`https://api.domehelp.com/User/AddUserSampleLead`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error in response")
                return
            }
            return resp.json()
        }).then((result) => {
            if (result.status === 1) {
                FetchJsonFile(jobXrefId)
                SaveInformationInPurchaseLead(result.data.userID)
            }
            else {
                Swal.fire("Faild !", result.message, "error");
                setShowSampleModal(false);
                console.log(result.message)
            }
        })
        setShowSampleModal(false)
        ClearInput()
    }
    function SaveInformationInPurchaseLead(id) {
        let fd = new FormData();
        fd.append("UserId", id);
        fd.append("StateId", stateId);
        fd.append("CityId", cityId);
        fd.append("JobId", jobId);
        fetch(`https://api.domehelp.com/User/SavePurchaseLeadInfo`, {
            method: "POST",
            body: fd,
        })
            .then((resp) => {
                if (!resp.ok) {
                    console.log("Error In response of save purchase lead information");
                    return;
                }
                return resp.json();
            })
            .then((result) => {
                if (result && result.status === 1) {
                    console.log(result.message);
                } else {
                    Swal.fire("Faild !", result.message, "error");
                }
            })
            .catch((error) => {
                console.log("Error in SaveInformationInPurchaseLead:", error);
            });
    }
    function ClearInput() {
        setFirstName('')
        setLastName('')
        setEmail('')
        setMobile('')
    }
    function FetchJsonFile(id) {
        let fd = new FormData();
        fd.append("id", id);
        fetch(`http://localhost:5055/Master/DownloadFileForSampleLead`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error in json file response")
                return
            }
            return resp.json()
        }).then((result) => {
            console.log(result)
            if (result.status === 0) {
                Swal.fire("Faild !", "No Sample Lead Available For this Job in Given City", 'info')
            }
            else {
                downloadExcel(result)
            }
        })
    }
    function downloadExcel(jsonData) {
        const worksheet = XLSX.utils.json_to_sheet(jsonData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'DataSheet.xlsx');
    };

    return (
        <section className="py-7">
            <div className="container-fluid px-7">
                <div className="row justify-content-center">
                    <div className="col-xl-3 col-lg-5 col-md-6 mx-auto mb-xl-0 mb-3">
                        <form className="row justify-content-center" data-hs-cf-bound="true">
                            <div className="card mt-5">
                                <div className="col-md-12 mt-4 px-4">
                                    <label className="labl-comn-className">Select Country</label>
                                    <div className="form-group mb-0">
                                        <Select placeholder="India" isDisabled />
                                    </div>
                                </div>

                                <div className="col-md-12 mt-2 px-4">
                                    <label className="labl-comn-className">Select State</label>
                                    <div className="form-group mb-0">
                                        <Select
                                            options={states}
                                            value={selectedState}
                                            onChange={setSelectedState}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2  px-4">
                                    <label className="labl-comn-className">Select City</label>
                                    <div className="form-group mb-0">
                                        <Select
                                            options={cities}
                                            value={selectedCities}
                                            onChange={setSelectedCities}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2 mb-4 px-4">
                                    <label className="labl-comn-className">Select Category</label>
                                    <div className="form-group mb-0">
                                        <Select
                                            options={job}
                                            value={selectedJob}
                                            onChange={setSelectedJob}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="row justify-content-center">
                            <div className="col-10 mt-2">
                                <button type="button" onClick={handleClear} className="my-2 me-2 btn btn-shadow btn-danger" style={{ width: '-webkit-fill-available' }}>Reset Selection</button>
                            </div>
                            <div className="col-10 mt-2">
                                <NavLink type="button" to={'/Contact'} className="my-2 me-2 btn btn-shadow btn-secondary" style={{ width: '-webkit-fill-available' }}>Request for custom leads</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 mb-2" style={{ height: "475px" }}>
                        <div className='table-responsive'>
                            <table id='myTable' className="text-start w-100 table table-striped" style={{ margin: 0 }}>
                                <thead style={{ color: "#1c6ce3" }}>
                                    <tr>
                                        <th width="17%">Category</th>
                                        <th width="17%">No. Of Records</th>
                                        <th width="17%"><MdMarkEmailUnread />&nbsp;Email</th>
                                        <th width="15%"><FaPhone />&nbsp;Phone</th>
                                        <th width="32%" style={{ textAlign: 'center' }}></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <Scrollbar >
                            <div className="table-responsive">
                                <table className="text-start w-100 table table-striped">
                                    <tbody style={{ fontSize: "small" }}>
                                        {
                                            filteredData.length === 0 ? (
                                                <tr id='noDatatr' ref={noDataRef} width="100%">
                                                    <td colSpan={5} style={{ width: "1045px" }}>
                                                        <div className="text-center w-100 h-100">
                                                            <div className='mt-5' style={{ height: "300px" }}>
                                                                <div style={{ fontSize: "105px" }}><LuFileX color='#c3ced4' /><br /></div>
                                                                {message === '' ? <Loader /> : message}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (
                                                filteredData.map(item => (
                                                    <tr key={item.jobXrefId}>
                                                        <td width="20%">{item.jobs.jobName} in {item.cities.cityName}</td>
                                                        <td width="15%">{item.totalCount}</td>
                                                        <td width="20%">{item.totalCount}</td>
                                                        <td width="15%">{item.totalCount}</td>
                                                        <td width="30%">
                                                            <div className='d-flex'>
                                                                <button className="btn btn-sm btn-outline-primary mx-1" value={selectedSamplelead} onClick={() => handleSampleleadButton(item.jobXrefId, item.jobs.jobName, item.cities.cityID, item.states.stateID, item.jobs.jobId)}>Sample Leads</button>
                                                                <NavLink className="btn btn-sm btn-info mx-1" to={`/B2BDataJobWiseSection2/${window.btoa(item.jobXrefId)}`}>Purchase Leads</NavLink>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className="table-responsive" >
                                <table className="text-start w-100 table table-striped">
                                    <tbody style={{ fontSize: "small" }}>
                                        {
                                            filteredData.length === 0 && topData.length > 0 && (
                                                topData.map(item => (

                                                    <tr key={item.jobXrefId}>
                                                        <td width="20%">{item.jobs.jobName} in {item.states.stateName}</td>
                                                        <td width="15%">{item.totalCount}</td>
                                                        <td width="20%">{item.totalCount}</td>
                                                        <td width="15%">{item.totalCount}</td>
                                                        <td width="30%">
                                                            <div className='d-flex'>
                                                                <button className="btn btn-sm btn-outline-primary mx-1" value={selectedSamplelead} onClick={() => handleSampleleadButton(item.jobXrefId, item.jobs.jobName, item.cities.cityID, item.states.stateID, item.jobs.jobId)}>Sample Leads</button>
                                                                <NavLink className="btn btn-sm btn-info mx-1" to={`/B2BDataJobWiseSection2/${window.btoa(item.jobXrefId)}`}>Purchase Leads</NavLink>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                ))
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Scrollbar>
                    </div>
                </div>
            </div>

            <Modal show={showSampleModal} onHide={() => setShowSampleModal(false)}>
                <Modal.Header closeButton>
                    <Image src={Logoimage} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
                    <Modal.Title >Free sample lead list of <strong>{jobName} </strong> in India</Modal.Title><br />
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="Name"
                                value={FirstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formFirstName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="Name"
                                value={LastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLastName">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={Email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLastName">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                                type="number"
                                name="mobile"
                                value={Mobile}
                                onChange={(e) => setMobile(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={() => handleDownLoadSample()}>
                        <SiMicrosoftexcel /> &nbsp;&nbsp; Download Sample
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default B2BDatabaseSection1;
