import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"

export default function B2BDatabaseSection3() {
    const [data, setData] = useState([])

    useEffect(() => {
        FetchJobWithStateCityCount()
    }, [])
    function FetchJobWithStateCityCount() {
        fetch(`https://api.domehelp.com/Master/GetStateAndCityCount`, {
            method: "POST"
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error in response")
                return
            }
            return resp.json()
        }).then((result) => {
            if (result.status === 1) {
                setData(result.data)
            }
            else {
                console.log(result.message)
            }
        })
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="d-lg-flex align-items-center justify-content-center"><br /><br /><br /><br />
                            <div className="cmn-title-div"><h2 className="mb-0">Business Leads by Industry</h2></div>
                        </div>
                        <div className="sts-links-div sts-links-customs mt-lg-5">
                            <ul className="row mt-3">
                                <li className="col-lg-4 col-md-6 mb-2">
                                    <div className="web-devlopnt-disc"><span></span><a href="#">Business Leads in Dental (5416)</a></div>
                                </li>
                                <li className="col-lg-4 col-md-6 mb-2">
                                    <div className="web-devlopnt-disc"><span></span><a href="#">Business Leads in Entertainment Media (5300)</a></div>
                                </li>
                                <li className="col-lg-4 col-md-6 mb-2">
                                    <div className="web-devlopnt-disc"><span></span><a href="#">Business Leads in North Educational Institutes (5276)</a></div>
                                </li>
                                <li className="col-lg-4 col-md-6 mb-2">
                                    <div className="web-devlopnt-disc"><span></span><a href="#">Business Leads in South IT Companies (5196)</a></div>
                                </li>
                                <li className="col-lg-4 col-md-6 mb-2">
                                    <div className="web-devlopnt-disc"><span></span><a href="#">Business Leads in Manufacturing Companies (5190)</a></div>
                                </li>
                                <li className="col-lg-4 col-md-6 mb-2">
                                    <div className="web-devlopnt-disc"><span></span><a href="#">Business Leads in Electricals & Electronics Stores (5062)</a></div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="d-lg-flex align-items-center justify-content-center"><br /><br /><br /><br />
                            <div className="cmn-title-div"><h2 className="mb-0">Find <span style={{ color: 'rgb(0 102 255)' }}>B2B Leads</span>  Across Different Domains</h2></div>
                        </div>
                        <div className="sts-links-div sts-links-customs mt-lg-5">
                            <ul className="row mt-3">
                                {
                                    data.map((item) => (
                                        <li id={item.stateID} className="col-lg-4 col-md-6 mb-2" key={item.stateID}>
                                            <div className="web-devlopnt-disc">
                                                <span></span>
                                                <NavLink style={{ fontSize: "small" }} to={`/B2BDataStateWise/${item.stateID}`}>Business Leads in {item.stateName} ({item.cityCount})</NavLink>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

