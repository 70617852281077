import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminHeader from "../Component/AdminHeader";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationCity } from "react-icons/md";
import { FaGlobe } from "react-icons/fa";
import HeaderHeroProfile from '../HeaderHeroProfile';
export default function AdminProfile() {
    const [Firstname, setFirstName] = useState('');
    const [Lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [data, setData] = useState(null);

    const id = window.localStorage.getItem("id")
    const token = window.localStorage.getItem("access")
    const role = `${window.localStorage.getItem('role')}`;
    const navigate = useNavigate();
    useEffect(() => {
        if ((token === '' || null) || role === 2) {
            navigate(`/Login`)
            return
        }
    }, [token]);
    useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            fetchUser();
        } else {
            navigate(`/Login`);
        }
    }, [id, navigate]);

    const fetchUser = async () => {
        try {
            const response = await fetch(`https://api.domehelp.com/User/GetUserById?id=${id}`, {
                method: "POST",
                headers: { Authorization: `${token}` },
            });
            const result = await response.json();
            setData(result.data);
            if (result.data) {
                setFirstName(result.data.firstName);
                setLastname(result.data.lastName);
                setEmail(result.data.userEmail);
                setMobile(result.data.userMobile);
                setCity(result.data.userCity);
                setState(result.data.userState);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };
    return (
        <>
            <HeaderHeroProfile />
            <section className="profile-container bg-gray-100">
                <div className="container-fluid px-7">
                    <div className="row align-items-start">
                        <div className='col-xl-2'>
                            <AdminHeader />
                        </div>
                        <div className="col-xl-10">
                            <div className="profile-content-area my-6 card card-body">
                                <div className="border-bottom mb-6 pb-6">
                                    <h5>Admin information</h5>
                                    <div className="row">
                                        <div className="col-md-6 my-2">
                                            <div className="d-flex">
                                                <div className="icon-md bg-primary bg-opacity-10 rounded-circle text-primary mt-1">
                                                    <MdEmail />
                                                </div>
                                                <div className="col ps-3 lh-normal">
                                                    <div className="text-mode m-0 fw-600">Email</div>
                                                    <span>{data ? data.userEmail : 'Loading...'}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <div className="d-flex">
                                                <div className="icon-md bg-primary bg-opacity-10 rounded-circle text-primary mt-1">
                                                    <FaPhoneAlt />
                                                </div>
                                                <div className="col ps-3 lh-normal">
                                                    <div className="text-mode m-0 fw-600">Mobile</div>
                                                    <span>{data ? data.userMobile : 'Loading...'}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <div className="d-flex">
                                                <div className="icon-md bg-primary bg-opacity-10 rounded-circle text-primary mt-1">
                                                    <MdLocationCity />
                                                </div>
                                                <div className="col ps-3 lh-normal">
                                                    <div className="text-mode m-0 fw-600">City</div>
                                                    <span>{data ? data.userCity : 'Loading...'}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <div className="d-flex">
                                                <div className="icon-md bg-primary bg-opacity-10 rounded-circle text-primary mt-1">
                                                    <FaGlobe />
                                                </div>
                                                <div className="col ps-3 lh-normal">
                                                    <div className="text-mode m-0 fw-600">State</div>
                                                    <span>{data ? data.userState : 'Loading...'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
