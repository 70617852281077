//json file is not uploaded on server so we use localhost url

import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { LuBuilding2 } from "react-icons/lu";
import { GrSend } from "react-icons/gr";
import { FaCommentSms } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import Dropdown from 'react-bootstrap/Dropdown';
import { Editor } from 'primereact/editor';

export default function UserDashboardSection1() {
    const [data, setData] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [currentPage, setCurrentPage] = useState(true);
    const [newPage, setNewPage] = useState(false);
    const [jobData, setJobData] = useState([]);
    const [jobXrefId, setJobXrefId] = useState(null);
    const [JsonResult, setJsonResult] = useState([]);
    const [emailPopUp, setEmailPopUp] = useState(false);
    const [smsPopUp, setSmsPopUp] = useState(false);
    const [subject, setSubject] = useState('');
    const [Message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const id = window.localStorage.getItem("id");
    useEffect(() => {
        fetchPurchaseDetail();
    }, []);

    const toggleDropdown = () => setIsOpen(!isOpen);
    function fetchPurchaseDetail() {
        let fd = new FormData();
        fd.append("userId", id);
        fetch(`https://api.domehelp.com/User/GetPurchaseInfo`, {
            method: "POST",
            body: fd,
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error In Response");
                return;
            }
            return resp.json();
        }).then((result) => {
            if (result.status === 1) {
                setData(result.data);
            } else {
                console.log(result.message);
            }
        }).catch((error) => {
            console.error("Fetch error:", error);
        });
    }

    function handleStateClick(state) {
        setSelectedState(state);
    }

    function handleCityClick(cityid, stid) {
        setCurrentPage(false)
        setNewPage(true)

        fetch(`https://api.domehelp.com/User/GetPurchaseInfoCity?userId=${id}&stateid=${stid}&cityid=${cityid}`, {
            method: "POST",
        }).then((resp) => {
            if (!resp.ok) {
                console.log("ERROR IN RESPONSE")
                return
            }
            return resp.json()
        }).then((result) => {
            if (result.status === 1) {
                setJobData(result.data)
            }
            else {
                console.log(result.message)
            }
        })
    }

    function handleBack() {
        setNewPage(false);
        setCurrentPage(true);
    }

    function handleDownload(jobXrefId) {
        setJobXrefId(jobXrefId);
        FetchJsonFile(jobXrefId);

    }
    function DownloadInJson() {
        const dataStr = JSON.stringify(JsonResult, null, 2);
        const dataBlob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'DomehelpDataSheet.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    function downloadExcel() {
        const worksheet = XLSX.utils.json_to_sheet(JsonResult);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'DomehelpDataSheet.xlsx');
    };
    async function downloadPDF() {
        const doc = new jsPDF();

        const tableColumn = Object.keys(JsonResult[0]);
        const tableRows = [];

        JsonResult.forEach(record => {
            const row = [];
            for (const key in record) {
                row.push(record[key]);
            }
            tableRows.push(row);
        });

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 20,
            margin: { top: 10, right: 10, bottom: 10, left: 10 },
            styles: { fontSize: 8, cellPadding: 2 },
            theme: 'grid',

            didDrawPage: function (data) {
                // Header
                doc.setFontSize(12);
                doc.setTextColor(40);
                doc.text("Data Report", data.settings.margin.left, 10);

            },
            addPageContent: function (data) {
                // Footer
                let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
                doc.text('Page ' + data.pageNumber, data.settings.margin.left, pageHeight - 5);
            }
        });

        doc.save('DomehelpDataSheet.pdf');
    }

    function FetchJsonFile(jobXrefId) {
        let fd = new FormData();
        fd.append("id", jobXrefId);
        fetch(`http://localhost:5055/Master/DownloadFileForPurchaseLead`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error in json file response")
                return
            }
            return resp.json()
        }).then((result) => {
            if (result.status === 0) {
                Swal.fire("Faild !", "No Lead Available For this Job in Given City", 'info');
            }
            else {
                setJsonResult(result)
            }
        })

    }

    function handleEmailPopUp(jxId) {
        setJobXrefId(jxId)
        setEmailPopUp(true)
    }

    function handleSmsPopUp(jxId) {
        setJobXrefId(jxId)
        console.log("JobXrefId is :", jxId)
        setSmsPopUp(true)
    }

    function handleSendEmail() {
        if (subject.trim() === "") {
            Swal.fire({
                title: "Warning!",
                text: "Please Enter Email Subject !",
                icon: "warning",
            });
            return
        }
        if (Message === "" || null) {
            Swal.fire("Warning !", "Please Enter Message", "warning")
            return
        }
        let fd = new FormData()
        fd.append("Id", jobXrefId)
        fd.append("Subject", subject);
        fd.append("Body", Message)
        fetch(`http://localhost:5055/Master/FindAndSendEmailFromJsonFile`, {
            method: "POST",
            body: fd
        }).then((resp) => {
            if (!resp.ok) {
                console.log("Error In Email Sending Response ")
                return
            }
            return resp.json()
        }).then((result) => {
            if (result.status === 1) {
                Swal.fire("Success", result.message, "success")

            } else {
                Swal.fire("Faild", result.message, "error")
            }
        })
        setMessage('')
        setSubject('')
        setEmailPopUp(false)


    }
    const groupedData = data.reduce((acc, item) => {
        const stateName = item.jobXrefCity.states.stateName;
        const cityName = item.jobXrefCity.cities.cityName;

        if (!acc[stateName]) {
            acc[stateName] = {};
        }

        if (!acc[stateName][cityName]) {
            acc[stateName][cityName] = item; // Store the first occurrence of the city
        }

        return acc;
    }, {});


    return (
        <>

            <div className="container-fluid">

                {currentPage && <div>
                    <h5>User Purchase Information</h5>
                    <div className="row mb-4 mt-2 d-flex align-items-center">
                        {Object.keys(groupedData).map((stateName) => (
                            <button
                                key={stateName}
                                className={`btn btn-sm m-1 col-md-2 ${selectedState === stateName ? 'btn-primary' : 'btn-outline-primary'}`}
                                style={{ fontSize: "x-small" }}
                                onClick={() => handleStateClick(stateName)}
                            >
                                <span className='m-2'><LuBuilding2 /></span>
                                {stateName}
                            </button>
                        ))}
                    </div>
                    {selectedState && (
                        <div className="row mb-4 mt-2 d-flex align-items-center">
                            {Object.values(groupedData[selectedState]).map((item) => (
                                <button
                                    key={item.paymentID}
                                    className="btn btn-sm btn-info m-1 col-md-2"
                                    style={{ fontSize: "xx-small" }}
                                    onClick={() => handleCityClick(item.cityID, item.stateID)}
                                >
                                    {item.jobXrefCity.cities.cityName}
                                </button>
                            ))}
                        </div>
                    )}
                </div>}
                {newPage && <div>
                    <h5>List Of Job Given</h5>
                    <div className="row mb-4 mt-2 d-flex align-items-center">
                        <table className="table table-striped table-bordered" style={{ textAlign: 'center', fontSize: "12px" }}>
                            <thead style={{ background: "#0066ff", color: "white" }} className="thead-dark">
                                <tr>
                                    <td>SR.</td>
                                    <td>Job Name</td>
                                    <td>Total Count</td>
                                    <td colSpan={3}>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {jobData.length > 0 ? jobData.map((item, index) => (
                                    <tr key={item.jobXrefId}>
                                        <td>{index + 1}</td>
                                        <td>{item.jobXrefCity.jobs.jobName}</td>
                                        <td>{item.jobXrefCity.totalCount}</td>
                                        <td>
                                            <button className="button-primary-xs" onClick={() => handleEmailPopUp(item.jobXrefId)}>
                                                <span style={{ marginRight: ".5rem" }}><GrSend /></span>
                                                Email
                                            </button>
                                        </td>
                                        <td>
                                            <button className="button-warning-xs" onClick={() => handleSmsPopUp(item.jobXrefId)}>
                                                <span style={{ marginRight: ".5rem" }}><FaCommentSms /></span>
                                                SMS
                                            </button>
                                        </td>
                                        <td>
                                            <Dropdown show={isOpen} onToggle={toggleDropdown}>
                                                <Dropdown.Toggle className="button-success-xs" onClick={() => {
                                                    handleDownload(item.jobXrefId);
                                                    toggleDropdown();
                                                }}>
                                                    <span style={{ marginRight: ".5rem" }}><IoMdDownload /></span>
                                                    Download
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={downloadPDF}>PDF</Dropdown.Item>
                                                    <Dropdown.Item onClick={downloadExcel}>Excel</Dropdown.Item>
                                                    <Dropdown.Item onClick={DownloadInJson}>JSON</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan="6">No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='text-end'>
                        <button className='btn btn-success' onClick={() => handleBack()}>Back</button>
                    </div>
                </div>
                }
            </div>

            {/* PopUp for Email */}
            <Modal size='lg' show={emailPopUp} onHide={() => setEmailPopUp(false)} style={{ marginTop: "1rem" }}>
                <Modal.Header closeButton>
                    <Modal.Title id='example-modal-sizes-title-lg' style={{ fontSize: "medium" }}>Send Message to all Email address</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form className="rd-mailform" data-form-output="form-output-global"
                        data-form-type="contact" method="post">
                        <div className="row g-3">

                            <div className="col-md-12 d-flex flex-row">
                                <div className="form-group w-100 d-flex flex-column">
                                    <label className='form-label'>Subject<strong style={{ color: "red" }}>*</strong></label>
                                    <input type='text' className='form-control' placeholder='Subject...' value={subject} onChange={(e) => setSubject(e.target.value)} />
                                    <label className="form-label">Message<strong style={{ color: "red" }}>*</strong></label>
                                    <Editor value={Message} onTextChange={(e) => setMessage(e.htmlValue)} style={{ height: '320px' }} />
                                </div>
                            </div>
                            <div className="col-12 pt-2">
                                <button className="btn rounded-pill btn-primary w-100" type="button" onClick={handleSendEmail}><span style={{ marginRight: ".5rem" }}><GrSend /></span>Send</button>
                                <div className="snackbars" id="form-output-global"></div>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>

            {/* Popup for sms */}

            <Modal size='sm' show={smsPopUp} onHide={() => setSmsPopUp(false)} style={{ marginTop: "3rem" }}>
                <Modal.Header>Send SMS To all Mobile Number</Modal.Header>
                <Modal.Body>
                    <label className="form-label">Message<strong style={{ color: "red" }}>*</strong></label>
                    <textarea className='form-control min-h-150px h-100 w-100' placeholder="Hi there, I would like to ..."></textarea>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn rounded-pill btn-primary w-100" type="button" ><span style={{ marginRight: ".5rem" }}><GrSend /></span>Send</button>
                </Modal.Footer>
            </Modal>

        </>
    );
}