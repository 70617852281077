import { useEffect } from "react";
import ChangePasswordSection1 from "./ChangePasswordSection1";
export default function ChangePassword() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <ChangePasswordSection1 />
        </>
    )
}